import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service';

@Component({
  selector: 'discontinued-popup',
  templateUrl: './discontinued-popup.html',
})
export class DiscontinuedPopupComponent {
  @Input('oldProductData') oldProduct: any;
  @Input('newProductData') newProduct: any;
  @Output() changeProduct: EventEmitter<any> = new EventEmitter();
  @Output() articleOpen: EventEmitter<any> = new EventEmitter();

  constructor(
    private broadcast: BroadcastService,
  ) {}

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
}

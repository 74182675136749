<div class="tw-w-full tw-px-4 tw-pb-8 tw-pt-12 tw-relative tw-bg-gray-75 tw-rounded-t-6">
  <div class="tw-mb-6 tw-font-body tw-text-center">
    <h1 class="tw-text-700 tw-font-bold tw-text-teal-600 tw-mb-3">Your call is scheduled</h1>
    <p class="tw-font-body tw-leading-5 tw-text-300 tw-text-gray-500 tw-mb-4">
      <span class="tw-capitalize">{{ userName }}</span>, we have arranged an online consultation with our skin doctor for you.
    </p>
  </div>
  <img src="https://cdn.cureskin.com/app/img/call_doctors.png" />
  <div class="tw-w-full tw-flex tw-flex-col tw-font-body tw-mb-6">
    <div class="tw-w-full tw-flex tw-gap-2 tw-border tw-border-gray-100 tw-rounded-lg tw-px-2 tw-py-2">
      <img class="tw-w-11" src="https://cdn.cureskin.com/app/img/call_white.svg" />
      <div class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-gap-1">
        <p class="tw-text-200 tw-font-semibold tw-text-gray-400 tw-leading-none">You will get a call on</p>
        <h2 class="tw-text-300 tw-font-bold tw-leading-none">+91 {{ userNumber }}</h2>
      </div>
    </div>
    <div class="tw-w-full tw-flex tw-gap-2 tw-border tw-border-gray-100 tw-rounded-lg tw-px-2 tw-py-2">
      <img class="tw-w-12" src="https://cdn.cureskin.com/app/img/medecine.svg" />
      <div class="tw-w-full tw-flex tw-flex-col tw-justify-center tw-gap-1">
        <p class="tw-text-200 tw-font-semibold tw-text-gray-400 tw-leading-none">Ask any query and doubts</p>
        <h2 class="tw-text-300 tw-font-bold tw-leading-none">related to your treatment</h2>
      </div>
    </div>
  </div>
  <div class="tw-mb-3 tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-3">
    <span
      class="tw-w-full tw-bg-teal-600 tw-text-white tw-py-3 tw-px-8 tw-rounded-xl tw-text-300
      tw-flex tw-justify-center tw-items-center tw-font-bold shine-button tw-relative tw-overflow-hidden"
      (click)="closePopup()">
      Okay
    </span>
  </div>
  <div
    class="tw-absolute tw-flex tw-justify-center tw-items-center tw-z-[100] tw-top-3 tw-right-3 close-sheet"
    style="width: 35px; height: 35px"
  >
    <div class="tw-text-gray-500 tw-text-500 tw-font-bold" (click)="closePopup()">&#10005;</div>
  </div>
</div>

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions, Pagination, Swiper } from 'swiper';
import { ConnectionService } from '@services/connection-service';

@Component({
  selector: 'app-week3-part-one',
  templateUrl: './week3-part-one.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class Week3PartOneComponent implements OnChanges {
  carouselConfigWeeklyJourney: SwiperOptions = {
    slidesPerView: 'auto',
    navigation: { prevEl: 'nonext', nextEl: 'nonext' },
  };
    @Input('user')user:any;
    @Input('followUp')followUp:any;
    @Output('openInstructions')openInstructions:EventEmitter<string> = new EventEmitter();
    constructor(private conn: ConnectionService) {
    }
    ngOnChanges(): void {
    }
    handleInstructions(card:string):void {
      this.openInstructions.emit(card);
    }
    handleCTA():void {
      this.conn.navigateToURL('https://app.cureskin.com/user/checkup');
    }
    handleCardCTA(articleId?:string):void {
      if (articleId) {
        this.conn.navigateToURL(`https://app.cureskin.com/article/${articleId}`);
      } else {
        this.conn.navigateToURL('https://app.cureskin.com/user/checkup');
      }
    }
}

import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Params, Router, UrlTree } from '@angular/router';
import { ConnectionService } from '@services/connection-service';

export const regimenProductsDetailGuard: CanActivateFn = async (route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> => {
  const router = inject(Router);
  const connectionService = inject(ConnectionService);
  const userLanguage = (await connectionService.getActingUser())?.get('languagePreference');
  const experiments = (await connectionService.findUserActiveExperiments());
  const experiment = experiments?.find((exp: any): any => exp.key === 'regimen_detail_page_revamp');
  const variant = experiment?.variant || {};
  const { queryParams }: { queryParams: Params } = route;
  const regimens = await connectionService.fetchRegimens(queryParams?.regimenId, false);
  const regimen = regimens.find((each: any): boolean => each.class === queryParams?.class);

  if (regimen.active) return true;

  if (!experiment) return true;

  if (variant?.languages?.includes(userLanguage) && variant?.classes?.includes(queryParams.class)) {
    return router.createUrlTree(['user', 'regimen', 'revamp-products'], { queryParams });
  }

  return true;
};

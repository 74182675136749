<div class="tw-border tw-border-[#DCE8E5] tw-p-4 tw-rounded-3 tw-bg-gradient-light-teal tw-font-body tw-shadow-z1" (click)="bannerClick()">
  <h1 class="tw-text-600 tw-font-bold">Got questions?</h1>
  <h1 class="tw-text-600 tw-font-body  tw-font-normal tw-mb-3 tw-leading-6 tw-tracking-normal">Talk to our skin doctor</h1>
  <p class="tw-font-body tw-leading-5 tw-font-normal tw-text-300 tw-text-gray-500 tw-mb-4">
    We will arrange an online consultation with a skin doctor {{ appointmentTimeMessage }}.
  </p>
  <div class="tw-w-full tw-h-[1px] tw-bg-[#CECECE] tw-mb-3"></div>
  <div class="tw-w-full tw-flex tw-justify-between tw-items-center tw-mb-1">
    <span class="tw-text-teal-600 tw-text-400 tw-font-bold tw-font-body tw-flex tw-items-center tw-gap-2">
      <h1 class="">Request a call</h1>
      <span class="">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_33185_963" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
          <rect x="0.0146484" width="16" height="16" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_33185_963)">
          <path d="M5.36465 14.6668L4.18131 13.4835L9.66465 8.00016L4.18131 2.51683L5.36465 1.3335L12.0313
          8.00016L5.36465 14.6668Z" fill="#0E796C"/>
        </g>
        </svg>
      </span>
    </span>
    <span class="tw-flex tw-items-center">
      <img src="https://cdn.cureskin.com/app/img/doctor_ai.svg" class="tw-w-[35px] tw-h-[35px] -tw-mr-3" alt="doctor icon" />
      <img src="https://cdn.cureskin.com/app/img/phone_teal.svg" class="tw-w-[39px] tw-h-[39px]" alt="phone icon" />
    </span>
  </div>
</div>

<ng-container *ngIf="isRebrandingBanner; then rebrandedTemplateV1 else originalTemplate"></ng-container>

<ng-template #originalTemplate>
<div class="card tw-flex tw-flex-row tw-items-center tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-teal-100" (click)="handleClick()">
  <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full bg-black tw-bg-yellow-200" [src]="userDoctor?.get('doctorDisplayImage')" />
  <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
    <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@yourDoctorHasRepliedOnYourQuery">
      Your doctor has replied on your query
    </p>
    <cs-button [class]="'primary btn-xxxs btn-xxxs-padding tw-px-2 btn-float'"><cs-button-text i18n="@@knowMore">Know More</cs-button-text></cs-button>
  </div>
</div>
</ng-template>

<ng-template #rebrandedTemplateV1>
  <section class="tw-rounded-3 tw-mx-4 tw-mb-4 tw-px-2 tw-py-3 tw-border-[2px] tw-border-solid tw-border-teal-200 tw-max-h-18 tw-box-border" 
  style="background: linear-gradient(95deg, #E4F4F0 -6.23%, #FFF 88%);" (click)="handleClick()">
    <div class="header tw-flex tw-items-center">
      <div >
      <cs-icon class="tw-h-12 tw-w-12" [iconName]="'doctor_reply'"></cs-icon>
      </div>
      <div class="tw-ml-2 tw-flex tw-flex-col tw-min-h-12 tw-gap-1">
        <div class="tw-flex tw-items-center">
          <div class="tw-leading-4 tw-font-body tw-font-bold tw-text-[14px] tw-items-center tw-flex tw-pt-1" i18n="@@msgFromDoctor">
          Message from doctor 
      </div>
      <cs-icon class="tw-pt-1 tw-h-4 tw-w-4 tw-pl-2" [iconName]="'chevron_right_teal'"></cs-icon>
    </div>
      <div class="tw-leading-[18px] tw-font-body tw-text-gray-500 tw-font-medium tw-text-[12px] tw-items-center tw-flex" i18n="@@yourDoctorHasRepliedOnYourQuery"> Your doctor has replied on your query </div>
    </div>
    </div>
  </section>
</ng-template>
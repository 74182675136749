import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { RegimenInstructionModule } from '@shared/regimen-instruction/regimen-instruction.module';
import { CallUsModule } from '@components/call-us';
import { OrderUpdateModule } from '@shared/order-update/order-update.module';
import { ShopByCategoryModule } from '@shared/shop-by-category/shop-by-category.module';
import { GetSupportQueryModule } from '@shared/get-support-query/get-support-query.module';
import { DoctorProfileModule } from '@shared/doctor-profile/doctor-profile.module';
import { SelectSubQueryModule } from '@shared/select-sub-query/select-sub-query.module';
import { DirectivesModule } from '@directives/directives.module';
import { ButtonModule } from '@components/button';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { SingleSelectorModule } from '@shared/single-selector/single-selector.module';
import { FilePickerModule } from '@components/file-picker';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetModule } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission.module';
import { LanguageChangeModule } from '@shared/bottom-sheet-layouts/language-change/language-change.module';
import { GetFeedbackSheetModule } from '@shared/bottom-sheet-layouts/get-feedback-sheet/get-feedback-sheet.module';
import { V2ChatComponent } from './chatV2-view.component';
import { ImageCardModule } from '../user/user-view/user-assistant/user-chat/components/image-card/image-card.module';
import { AudioModule } from '../user/user-view/user-assistant/user-chat/components/audio/audio.module';
import { MultiImagesModule } from '../user/user-view/user-assistant/user-chat/components/multi-images/multiImages.module';
import { ArticleModule } from '../user/user-view/user-assistant/user-chat/components/article/article.module';
import { AttachmentsModule } from '../user/user-view/user-assistant/user-chat/components/attachments/attachments.module';
import { CrossRegimenBannerModule } from '../user/user-view/user-home/cross-regimen-banner/cross-regimen-banner.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CsIconModule,
    DirectivesModule,
    ToolbarModule,
    LoadingModule,
    ProductListModule,
    PopUpModule,
    CallUsModule,
    OrderUpdateModule,
    RegimenInstructionModule,
    ShopByCategoryModule,
    GetSupportQueryModule,
    DoctorProfileModule,
    SelectSubQueryModule,
    ImageCardModule,
    ButtonModule,
    AudioModule,
    MultiImagesModule,
    SingleSelectorModule,
    ArticleModule,
    FilePickerModule,
    AttachmentsModule,
    CrossRegimenBannerModule,
    MatBottomSheetModule,
    NotificationPermissionSheetModule,
    LanguageChangeModule,
    GetFeedbackSheetModule,
  ],
  declarations: [V2ChatComponent],
  exports: [V2ChatComponent],
})
export class ChatV2ViewModule {}

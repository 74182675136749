import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-diet-plan',
  templateUrl: './diet-plan.component.html',
  styleUrls: ['../../../user/user-view/user-home/user-home.scss'],
})
export class DietPlanComponent {
  @Input('userStatus')userStatus:any;
  @Output('openDiet')openDiet:EventEmitter<void> = new EventEmitter();

  handleClick():void {
    this.openDiet.emit();
  }
}

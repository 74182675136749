import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'mouse-tour',
  templateUrl: './mouse-tour.html',
})
export class MouseTourComponent {
  @Input('element') element: HTMLElement;
  @ViewChild('mouse', { static: true }) mouse: ElementRef;
  @Output('onClose') onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(private windowRef: WindowRefService, public appConfig: AppConfig) {}

  ngOnInit(): void {
    const WIDTH_BUFFER = 15;
    const HEIGHT_BUFFER = 15;
    const TOOLTIP_POINTER_BUFFER = 8;
    const targetElementCords: DOMRect = this.element.getClientRects().item(0);
    this.mouse.nativeElement.style.width = `${targetElementCords.width + WIDTH_BUFFER}px`;
    this.mouse.nativeElement.style.height = `${targetElementCords.height + HEIGHT_BUFFER}px`;
    this.mouse.nativeElement.style.left = `${targetElementCords.left - WIDTH_BUFFER / 2}px`;
    this.mouse.nativeElement.style.top = `${targetElementCords.top - HEIGHT_BUFFER / 2}px`;
    this.mouse.nativeElement.style.borderRadius = this.windowRef.nativeWindow.getComputedStyle(this.element).borderRadius;
    this.mouse.nativeElement.style.setProperty('--pos-right',
      `${((targetElementCords.width + WIDTH_BUFFER) / 2) - TOOLTIP_POINTER_BUFFER}px`);
  }

  closeTour(): void {
    this.element.click();
    this.onClose.emit();
  }
}

<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-flex tw-flex-col tw-h-full tw-overflow-hidden tw-bg-gray-100">
  <div class="tw-flex-none tw-bg-grid-layout tw-bg-yellow-100 tw-pt-20 tw-pb-[60px]">
    <div class="tw-font-body tw-font-bold tw-text-600 tw-tracking-normal tw-text-center" i18n="@@myOrders">My orders</div>
  </div>
  <div class="tw-flex-none tw-flex tw-px-6 tw-gap-2 tw-relative tw-z-10 tw-mt-[-20px]">
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.active, 'tw-bg-black tw-text-white': activeOrderType === orderType.active }" (click)="changeOrderType(orderType.active)">
      <span i18n="@@active">active</span>
    </div>
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.completed, 'tw-bg-black tw-text-white': activeOrderType === orderType.completed }" (click)="changeOrderType(orderType.completed)">
      <span i18n="@@completed">completed</span>
    </div>
    <div class="tw-h-10 tw-flex tw-items-center tw-justify-center tw-px-4 tw-rounded-full" [ngClass]="{'tw-bg-white tw-shadow-z1': activeOrderType !== orderType.cancelled, 'tw-bg-black tw-text-white': activeOrderType === orderType.cancelled }" (click)="changeOrderType(orderType.cancelled)">
      <span i18n="@@cancelled">cancelled</span>
    </div>
  </div>
  <div class="tw-flex-auto tw-relative tw-overflow-y-auto">
    <loading class="pos-abs vh-center" *ngIf="isLoading"></loading>
    <div class="tw-flex tw-flex-col tw-gap-3 tw-pt-5 tw-px-5 tw-mb-6" *ngIf="!isLoading">
      <div class="empty-hint centerA empty-icon" *ngIf="!orders?.length">
        <span class="tw-font-body tw-text-300" i18n="@@noOrdersAvailable">No orders available</span>
      </div>
      <div *ngFor="let order of orders" routerLink="/user/order/{{order?.id}}">
        <ng-container *ngIf="orderPageRegimenProposition">
          <ng-container *ngIf="order?.get('type') === appConfig.Shared.Order.Type.REGIMEN && (order?.get('regimenStateAtLastCheckpoint')?.get('regimenId') || order?.get('regimen')?.get('regimenId'))">
            <div class="tw-rounded-3 tw-p-4 tw-border tw-border-gray-200 tw-shadow-z4 tw-bg-white">
              <div class="tw-flex tw-justify-between tw-mb-5">
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                  <ng-container [ngSwitch]="true">
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchCase="order.get('stage') === 'INITIAL'" i18n="@@orderNotPlacedYet">
                      Order not placed yet
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchDefault>
                      <span i18n="@@orderNumber">Order number</span><span>: {{'#'+order.get('orderNumber')}}</span>
                    </p>
                  </ng-container>
                  <ng-container [ngSwitch]="true">
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" i18n="@@paymentPending">
                      Payment pending
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!!ordersMap.get(order.id)?.expectedDeliveryDate?.to">
                      <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ordersMap.get(order.id)?.expectedDeliveryDate?.to | date: 'd MMM'}}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && !orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliverOnDate')">
                      <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ order.get('deliverOnDate') | date:'dd MM' }}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliveredOn')">
                      <span i18n="@@deliveredOn">Delivered on</span><span>: {{ order.get('deliveredOn') | date:'dd MMM yyyy' }}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !order.get('canceledByUser')" i18n="@@placeANewOrder">
                      Place a new order
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!isCurrentOrderInRefundStages(order?.id) && orderCanceledStages.includes(order.get('stage')) && !!order.get('canceledByUser')" i18n="@@userCancelled">
                      User cancelled
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchDefault>
                      -
                    </p>
                  </ng-container>
                </div>
                <ng-container [ngSwitch]="true">
                  <div class="tw-rounded tw-border tw-border-yellow-400 tw-bg-yellow-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@paymentPending">
                      Payment pending
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPlacedStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@orderPlaced">
                      Order placed
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@packed">
                      Packed
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@dispatched">
                      Dispatched
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-red-400 tw-bg-red-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@outForDelivery">
                      Out For Delivery
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@deliveryAttemptFailed">
                      Delivery attempt failed
                    </p>
                  </div>
                  <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@delivered">Delivered</p>
                    <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                      <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                    </div>
                  </div>
                  <div *ngSwitchCase="isCurrentOrderInRefundStages(order?.id) && (orderCanceledStages.includes(order.get('stage')) || order.get('stage') === appConfig.Shared.Order.Stage.REFUND_INITIATED)">
                    <p *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_REQUESTED" class="tw-font-body tw-text-200 tw-text-gray-500">
                      Refund Requested
                    </p>
                    <p *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_INITIATED" class="tw-font-body tw-text-200 tw-text-gray-500">
                      Refund Initiated
                    </p>
                    <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_PROCESSED">
                      <p class="tw-font-body tw-text-200 tw-text-black">
                        Refunded
                      </p>
                      <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                        <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                      </div>
                    </div>
                  </div>
                  <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="!isCurrentOrderInRefundStages(order?.id) && orderCanceledStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto" i18n="@@canceled">
                      Cancelled
                    </p>
                    <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
                      <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
                    </div>
                  </div>
                  <div class="tw-rounded tw-flex tw-items-center tw-gap-1 tw-bg-gray-100 tw-border tw-border-gray-300 tw-px-1" *ngSwitchDefault>
                    <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto">
                      {{order.get('stage').replace('_', ' ') | titlecase}}
                    </p>
                  </div>
                </ng-container>
              </div>
              <div class="tw-flex tw-items-center tw-gap-4">
                <div>
                  <div class="tw-bg-teal-100 tw-w-32 tw-h-[120px] tw-rounded-3">
                    <img [src]="order.get('regimenStateAtLastCheckpoint')?.get('regimenCoverImage') || order.get('regimen')?.get('regimenCoverImage')" class="tw-w-32 tw-h-[120px] tw-object-cover tw-rounded-3" alt="regimen_img"/>
                  </div>
                </div>
                <div>
                  <div class="tw-flex tw-flex-col tw-gap-2">
                    <p *ngIf="order.get('regimenStateAtLastCheckpoint')?.get('displayConcern')?.name || order?.get('regimen')?.get('displayConcern')?.name" class="tw-text-400 tw-font-body tw-font-bold tw-text-black">
                      <span>
                        Treatment <br/> Kit for <br/> {{order.get('regimenStateAtLastCheckpoint')?.get('displayConcern')?.name || order?.get('regimen')?.get('displayConcern')?.name}}
                      </span>
                    </p>
                    <p *ngIf="!(order.get('regimenStateAtLastCheckpoint')?.get('displayConcern')?.name || order?.get('regimen')?.get('displayConcern')?.name)" class="tw-text-400 tw-font-body tw-font-bold tw-text-black">
                      <span *ngIf="order.get('regimenStateAtLastCheckpoint')?.get('class') === appConfig.Shared.InstantCheckup.Type.FACE || order.get('regimen').get('class') === appConfig.Shared.InstantCheckup.Type.FACE">
                        Treatment <br/> Kit for <span class="tw-capitalize">{{appConfig.Shared.RegimenFor.SKIN.toLowerCase()}} </span>
                      </span>
                      <span *ngIf="(order.get('regimenStateAtLastCheckpoint')?.get('class') && order.get('regimenStateAtLastCheckpoint')?.get('class') !== appConfig.Shared.InstantCheckup.Type.FACE) || order.get('regimen').get('class') !== appConfig.Shared.InstantCheckup.Type.FACE">
                        Treatment <br/> Kit for <span class="tw-capitalize">{{order.get('regimenStateAtLastCheckpoint')?.get('class')?.toLowerCase() || order.get('regimen').get('class').toLowerCase()}} </span>
                      </span>
                    </p>
                  </div>
                  <div class="tw-mt-2" *ngIf="getFreeProductDetails(order)">
                    <p class="tw-text-200 tw-font-body tw-text-gray-800"> + Free Pouch </p>
                  </div>
                </div>
              </div>
              <ng-container [ngSwitch]="true">
                <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                  <span class="tw-w-max tw-max-w-[90%] tw-bg-teal-600 tw-text-white tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold tw-relative tw-overflow-hidden tw-capitalize tw-py-2 tw-px-4" i18n="@@makePayment"> Make payment </span>
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') === 'COD'">
                  <div class="tw-flex tw-flex-col">
                    <p class="tw-font-body tw-text-300">
                      <span class="tw-text-black" i18n="@@amountToBePaid">Amount to be paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                    </p>
                    <p class="tw-font-body tw-text-300" *ngIf="order.get('paymentType') === 'COD'">
                      (Cash on delivery)
                    </p>
                  </div>
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') !== 'COD'">
                  <p class="tw-font-body tw-text-300">
                    <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                  </p>
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                  <p class="tw-font-body tw-text-300">
                    <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                  </p>
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
                <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchDefault>
                  <p class="tw-font-body tw-text-300">
                    <span class="tw-text-black" i18n="@@orderAmount">Order amount</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order?.get('regimenStateAtLastCheckpoint')?.get('fixedPrice') || order?.get('regimen').get('fixedPrice')}}</span>
                  </p>
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="order?.get('type') !== appConfig.Shared.Order.Type.REGIMEN && !!!order?.get('regimen')">
            <div class="tw-rounded-2xl tw-p-4 tw-border tw-border-gray-200 tw-shadow-z1 tw-bg-white">
              <div class="tw-flex tw-justify-between tw-mb-4">
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
                  <ng-container [ngSwitch]="true">
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchCase="order.get('stage') === 'INITIAL'" i18n="@@orderNotPlacedYet">
                      Order not placed yet
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchDefault>
                      <span i18n="@@orderNumber">Order number</span><span>: {{'#'+order.get('orderNumber')}}</span>
                    </p>
                  </ng-container>
                  <ng-container [ngSwitch]="true">
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" i18n="@@paymentPending">
                      Payment pending
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!!ordersMap.get(order.id)?.expectedDeliveryDate?.to">
                      <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ordersMap.get(order.id)?.expectedDeliveryDate?.to | date: 'd MMM'}}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && !orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliverOnDate')">
                      <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ order.get('deliverOnDate') | date:'dd MM' }}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliveredOn')">
                      <span i18n="@@deliveredOn">Delivered on</span><span>: {{ order.get('deliveredOn') | date:'dd MMM yyyy' }}</span>
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !order.get('canceledByUser')" i18n="@@placeANewOrder">
                      Place a new order
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !!order.get('canceledByUser')" i18n="@@userCancelled">
                      User cancelled
                    </p>
                    <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchDefault>
                      -
                    </p>
                  </ng-container>
                </div>
                <ng-container [ngSwitch]="true">
                  <div class="tw-rounded tw-border tw-border-yellow-400 tw-bg-yellow-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@paymentPending">
                      Payment pending
                    </p>
                  </div>
                  <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPlacedStages.includes(order.get('stage'))">
                    <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@orderPlaced">
                      Order placed
                    </p>
                        </div>
                              <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
                                  <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@packed">
                                      Packed
                                  </p>
                              </div>
                              <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
                                  <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@dispatched">
                                      Dispatched
                                  </p>
                              </div>
                              <div class="tw-rounded tw-border tw-border-red-400 tw-bg-red-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
                                  <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@outForDelivery">
                                      Out For Delivery
                                  </p>
                              </div>
                              <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
                                  <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@deliveryAttemptFailed">
                                      Delivery attempt failed
                                  </p>
                              </div>
                              <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                                  <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@delivered">Delivered</p>
                                  <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                                      <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                                  </div>
                              </div>
                              <div *ngSwitchCase="isCurrentOrderInRefundStages(order?.id) && (orderCanceledStages.includes(order.get('stage')) || order.get('stage') === appConfig.Shared.Order.Stage.REFUND_INITIATED)">
                                <p *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_REQUESTED" class="tw-font-body tw-text-200 tw-text-gray-500">
                                  Refund Requested
                                </p>
                                <p *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_INITIATED" class="tw-font-body tw-text-200 tw-text-gray-500">
                                  Refund Initiated
                                </p>
                                <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_PROCESSED">
                                  <p class="tw-font-body tw-text-200 tw-text-black">
                                    Refunded
                                  </p>
                                  <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                                    <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                                  </div>
                                </div>
                              </div>
                              <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="!isCurrentOrderInRefundStages(order?.id) && orderCanceledStages.includes(order.get('stage'))">
                                <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto" i18n="@@canceled">
                                  Cancelled
                                </p>
                                <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
                                  <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
                                </div>
                              </div>
                              <div class="tw-rounded tw-flex tw-items-center tw-gap-1 tw-bg-gray-100 tw-border tw-border-gray-300 tw-px-1" *ngSwitchDefault>
                                  <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto">
                                      {{order.get('stage').replace('_', ' ') | titlecase}}
                                  </p>
                              </div>
                </ng-container>
                      </div>
                      <div class="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
                          <div class="tw-flex tw-items-center tw-gap-2" *ngFor="let product of order.get('productInfo')">
                              <img class="tw-h-auto tw-max-h-10 tw-max-w-auto" [src]="product?.image" />
                              <p class="tw-font-body tw-text-300">{{product?.quantity}}</p>
                              <cs-icon class="tw-h-4 tw-w-4 tw-text-gray-800" [iconName]='"close"'></cs-icon>
                              <p class="tw-font-body tw-text-300">{{product?.name}}</p>
                          </div>
                      </div>
                      <ng-container [ngSwitch]="true">
                          <div class="tw-flex tw-justify-between tw-items-center tw-mt-4" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                            <span class="tw-w-max tw-max-w-[90%] tw-bg-teal-600 tw-text-white tw-rounded-xl tw-text-300 tw-flex tw-justify-center tw-items-center tw-font-bold tw-relative tw-overflow-hidden tw-capitalize tw-py-2 tw-px-4" i18n="@@makePayment"> Make payment </span>
                            <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                          </div>
                          <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') === 'COD'">
                              <div class="tw-flex tw-flex-col">
                                  <p class="tw-font-body tw-text-300">
                                      <span class="tw-text-black" i18n="@@amountToBePaid">Amount to be paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                                  </p>
                                  <p class="tw-font-body tw-text-300" *ngIf="order.get('paymentType') === 'COD'">
                                      (Cash on delivery)
                                  </p>
                              </div>
                              <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                          </div>
                          <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') !== 'COD'">
                              <p class="tw-font-body tw-text-300">
                                  <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                              </p>
                              <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                          </div>
                          <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-300">
                                  <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                              </p>
                              <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                          </div>
                          <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchDefault>
                              <p class="tw-font-body tw-text-300">
                                  <span class="tw-text-black" i18n="@@orderAmount">Order amount</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                              </p>
                              <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                          </div>
                      </ng-container>
                  </div>
          </ng-container>
        </ng-container>
        <div class="tw-rounded-2xl tw-p-4 tw-border tw-border-gray-200 tw-shadow-z1 tw-bg-white" *ngIf="!orderPageRegimenProposition">
          <div class="tw-flex tw-justify-between tw-mb-4">
            <div class="tw-flex tw-flex-col tw-items-start tw-gap-1">
              <ng-container [ngSwitch]="true">
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchCase="order.get('stage') === 'INITIAL'" i18n="@@orderNotPlacedYet">
                  Order not placed yet
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none" *ngSwitchDefault>
                  <span i18n="@@orderNumber">Order number</span><span>: {{'#'+order.get('orderNumber')}}</span>
                </p>
              </ng-container>
              <ng-container [ngSwitch]="true">
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))" i18n="@@paymentPending">
                  Payment pending
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!!ordersMap.get(order.id)?.expectedDeliveryDate?.to">
                  <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ordersMap.get(order.id)?.expectedDeliveryDate?.to | date: 'd MMM'}}</span>
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && !orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliverOnDate')">
                  <span i18n="@@expectedDelivery">Expected delivery</span><span>: {{ order.get('deliverOnDate') | date:'dd MM' }}</span>
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!ordersMap.get(order.id)?.expectedDeliveryDate?.to && orderDeliveredStages.includes(order.get('stage')) && !!order.get('deliveredOn')">
                  <span i18n="@@deliveredOn">Delivered on</span><span>: {{ order.get('deliveredOn') | date:'dd MMM yyyy' }}</span>
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="orderCanceledStages.includes(order.get('stage')) && !order.get('canceledByUser')" i18n="@@placeANewOrder">
                  Place a new order
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchCase="!isCurrentOrderInRefundStages(order?.id) && orderCanceledStages.includes(order.get('stage')) && !!order.get('canceledByUser')" i18n="@@userCancelled">
                  User cancelled
                </p>
                <p class="tw-font-body tw-text-200 tw-text-black tw-leading-none tw-text-gray-400" *ngSwitchDefault>
                  -
                </p>
              </ng-container>
            </div>
            <ng-container [ngSwitch]="true">
              <div class="tw-rounded tw-border tw-border-yellow-400 tw-bg-yellow-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@paymentPending">
                  Payment pending
                </p>
              </div>
              <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPlacedStages.includes(order.get('stage'))">
                <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@orderPlaced">
                  Order placed
                </p>
                    </div>
                          <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderPackedStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@packed">
                                  Packed
                              </p>
                          </div>
                          <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDispatchedStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@dispatched">
                                  Dispatched
                              </p>
                          </div>
                          <div class="tw-rounded tw-border tw-border-red-400 tw-bg-red-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderOutForDeliveryStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@outForDelivery">
                                  Out For Delivery
                              </p>
                          </div>
                          <div class="tw-rounded tw-border tw-border-green-400 tw-bg-green-100 tw-h-6 tw-flex tw-items-center tw-shrink-0 tw-px-3" *ngSwitchCase="orderDeliveryFailedStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@deliveryAttemptFailed">
                                  Delivery attempt failed
                              </p>
                          </div>
                          <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-black" i18n="@@delivered">Delivered</p>
                              <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                                  <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                              </div>
                          </div>
                          <div *ngSwitchCase="isCurrentOrderInRefundStages(order?.id) && (orderCanceledStages.includes(order.get('stage')) || order.get('stage') === appConfig.Shared.Order.Stage.REFUND_INITIATED)">
                            <p *ngIf="refundRelatedStageOrdersMap.get(order?.id).stage === appConfig.Shared.Order.Stage.REFUND_REQUESTED" class="tw-font-body tw-text-200 tw-text-gray-500">
                              Refund Requested
                            </p>
                            <p *ngIf="refundRelatedStageOrdersMap.get(order?.id).stage === appConfig.Shared.Order.Stage.REFUND_INITIATED" class="tw-font-body tw-text-200 tw-text-gray-500">
                              Refund Initiated
                            </p>
                            <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngIf="refundRelatedStageOrdersMap.get(order?.id)?.stage === appConfig.Shared.Order.Stage.REFUND_PROCESSED">
                              <p class="tw-font-body tw-text-200 tw-text-black">
                                Refunded
                              </p>
                              <div class="tw-rounded-full tw-bg-green-400 tw-h-4">
                                <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'done'"></cs-icon>
                              </div>
                            </div>
                          </div>
                          <div class="tw-rounded tw-flex tw-items-center tw-gap-1" *ngSwitchCase="!isCurrentOrderInRefundStages(order?.id) && orderCanceledStages.includes(order.get('stage'))">
                              <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto" i18n="@@canceled">
                                  Cancelled
                              </p>
                              <div class="tw-rounded-full tw-bg-red-400 tw-h-4">
                                  <cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]="'close'"></cs-icon>
                              </div>
                          </div>
                          <div class="tw-rounded tw-flex tw-items-center tw-gap-1 tw-bg-gray-100 tw-border tw-border-gray-300 tw-px-1" *ngSwitchDefault>
                              <p class="tw-font-body tw-text-200 tw-text-gray-500 tw-w-min xs:tw-w-auto">
                                  {{order.get('stage').replace('_', ' ') | titlecase}}
                              </p>
                          </div>
            </ng-container>
                  </div>
                  <div class="tw-mb-4 tw-flex tw-flex-col tw-gap-2">
                      <div class="tw-flex tw-items-center tw-gap-2" *ngFor="let product of order.get('productInfo')">
                          <img class="tw-h-auto tw-max-h-10 tw-max-w-auto" [src]="product?.image" />
                          <p class="tw-font-body tw-text-300">{{product?.quantity}}</p>
                          <cs-icon class="tw-h-4 tw-w-4 tw-text-gray-800" [iconName]='"close"'></cs-icon>
                          <p class="tw-font-body tw-text-300">{{product?.name}}</p>
                      </div>
                  </div>
                  <ng-container [ngSwitch]="true">
                      <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="orderPaymentPendingStages.includes(order.get('stage'))">
                          <button class="tw-flex-none tw-h-8 tw-flex tw-item-center tw-justify-center tw-bg-green-400 tw-rounded-full tw-m-0">
                          <span class="tw-font-body tw-text-200 tw-text-white tw-font-bold tw-tracking-normal" i18n="@@makePayment"> Make payment </span>
                        </button>
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                      </div>
                      <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') === 'COD'">
                          <div class="tw-flex tw-flex-col">
                              <p class="tw-font-body tw-text-300">
                                  <span class="tw-text-black" i18n="@@amountToBePaid">Amount to be paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                              </p>
                              <p class="tw-font-body tw-text-300" *ngIf="order.get('paymentType') === 'COD'">
                                  (Cash on delivery)
                              </p>
                          </div>
                          <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                      </div>
                      <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="expectedDeliveryDateStages.includes(order.get('stage')) && order.get('paymentType') !== 'COD'">
                          <p class="tw-font-body tw-text-300">
                              <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                          </p>
                          <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                      </div>
                      <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchCase="orderDeliveredStages.includes(order.get('stage'))">
                          <p class="tw-font-body tw-text-300">
                              <span class="tw-text-black" i18n="@@amountPaid">Amount Paid</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                          </p>
                          <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                      </div>
                      <div class="tw-flex tw-justify-between tw-items-center" *ngSwitchDefault>
                          <p class="tw-font-body tw-text-300">
                              <span class="tw-text-black" i18n="@@orderAmount">Order amount</span>:<span class="tw-font-body tw-font-bold tw-text-green-400"> ₹{{order.get('amount')}}</span>
                          </p>
                          <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]='"chevron_right"'></cs-icon>
                      </div>
                  </ng-container>
              </div>
        </div>
      </div>
    </div>
</div>
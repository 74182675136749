import { Component, Input, OnDestroy, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { CurrentComponentService } from '@services/current-component';

@Component({ selector: 'multiImages', templateUrl: './multiImages.html' })
export class MultiImagesComponent implements OnDestroy {
  chat: any;
  user: any;
  loading: boolean = true;
  @Input()
  set data(data: any) {
    this.chat = data;
  }

  constructor(private window: WindowRefService,
    private broadcast: BroadcastService,
    private router: Router,
    private conn: ConnectionService,
    private currentComponent: CurrentComponentService,
    private viewContainer: ViewContainerRef) {
    this.currentComponent.set(this);
  }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    const dialog = this.viewContainer.element.nativeElement;
    if (dialog) {
      setTimeout((): void => this.window.nativeWindow.document.body.appendChild(dialog), 0);
    }
    setTimeout((): boolean => this.loading = false, 0);
  }

  handleBackPress(): Promise<boolean> {
    this.currentComponent.remove(this);
    this.back();
    return Promise.resolve(true);
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  closePopUp(event: any): void {
    this.chat = [];
    if (event) {
      this.router.navigate([`/user/instantCheckup/${event}`],
        { queryParams: { username: this.user.get('username'), fromList: true }, replaceUrl: true });
    }
  }

  ngOnDestroy(): void {
    this.currentComponent.remove(this);
    this.viewContainer.element.nativeElement.remove();
  }
}

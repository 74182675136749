import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { LoadingModule } from '@components/loading';
import { CommonModule, Location } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { AppConfig } from 'client/app/app.config';
import { extractRegimenProducts, setRegimenPricing } from 'client/helper/regimen-helper';
import { extractAssetsForSlider, extractInstructionVideo } from 'client/helper/product-helper';
import { ApiClientConstant } from '@cureskin/api-client';
import { ButtonModule } from '@components/button';

import { CsIconModule } from '../../../components/icon/icon.module';
import { ProductSlideShowComponent } from '../components/product-slide-show/product-slide-show.component';
import { VideoDialogComponent } from '../components/video-dialog/video-dialog.component';
import { UserRegimenModule } from '../user-regimen.module';

const regimenFieldIncldue: any = [
  'morning.product' as 'morning',
  'night.product' as 'night',
  'morning.product.productHighlights',
  'night.product.productHighlights',
];

@Component({
  selector: 'app-regimen-products-detail',
  standalone: true,
  imports: [CommonModule, ButtonModule, CsIconModule, LoadingModule, SwiperModule, DialogModule, UserRegimenModule],
  templateUrl: './regimen-products-detail.component.html',
  styles: `:host {
    @apply tw-block tw-font-body tw-pt-20 tw-font-normal tw-bg-gray-80 tw-h-full tw-overflow-y-auto tw-relative;
    background: linear-gradient(180deg, rgba(244,255,252,1) 0%, rgba(244,255,252,1) 46%, rgba(0,212,255,0) 100%), #F3F3F3;
    background-size: 100% 60vh;
    background-repeat: no-repeat;
  }`,
})
export class RegimenProductsDetailComponent implements OnInit {
  eventLoggerService: EventLoggerService = inject(EventLoggerService);
  connectionService: ConnectionService = inject(ConnectionService);
  route: ActivatedRoute = inject(ActivatedRoute);
  appConfig: AppConfig = inject(AppConfig);
  location: Location = inject(Location);
  router: Router = inject(Router);
  dialog: Dialog = inject(Dialog);
  isLoading: boolean = false;
  isShowHighlight: boolean;
  isInternalUser: boolean;
  allocatedDoctor: any;
  products: any = [];
  regimen: any;
  user: any;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1.75,
    autoHeight: true,
    pagination: false,
    navigation: false,
    autoplay: false,
  };

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const regimenClass = this.route.snapshot.queryParams.class;
    const { regimenId }: any = this.route.snapshot.queryParams;
    this.user = await this.connectionService.getActingUser();
    this.isInternalUser = this.connectionService.isInternalUser();

    const experiments = await this.connectionService.findUserActiveExperiments();
    experiments?.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'show_regimen_product_highlight'
        && experiment?.variant?.languages?.includes(this.user.get('languagePreference'))) {
        this.isShowHighlight = true;
      }
    });

    const regimens: any[] = (await this.connectionService.fetchRegimens(regimenId, true, true, false, regimenFieldIncldue));
    this.regimen = regimens?.find((each: any): boolean => each.class === regimenClass);
    this.regimen = setRegimenPricing(this.regimen);
    this.products = extractRegimenProducts(this.regimen)
      .map((product: any): any => {
        const images = extractAssetsForSlider(product.product);
        const video = extractInstructionVideo(product, this.user);
        return { ...product, images, video };
      });
    if (this.user?.get('allocatedDoctor')) {
      this.allocatedDoctor = await this.connectionService.findUserByObjectId(this.user.get('allocatedDoctor').id);
    }

    // Log page open event
    this.eventLoggerService.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-view-unpaid' }));
    this.isLoading = false;
  }

  doctorCardClick(): void {
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_dr_card_clicked' }));
  }

  openSlideShow(product: any): void {
    this.dialog.open(ProductSlideShowComponent, { data: { product }, backdropClass: ['tw-opacity-90', 'tw-bg-black'] });
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_product_image_clicked' }));
  }

  openVideoPlayer(url: string): void {
    this.dialog.open(VideoDialogComponent, { data: { url }, backdropClass: ['tw-opacity-90', 'tw-bg-black'] });
    this.eventLoggerService.cleverTapEvent('click', JSON.stringify({ name: 'regimen_detail_play_video_clicked' }));
  }

  buyRegimen(): void {
    this.eventLoggerService.trackInParse('BUY_NOW', ApiClientConstant?.Event?.Type?.ORDER);
    this.eventLoggerService.trackEvent('BUY_NOW_CLICKED');
    this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED');
    if (this.regimen.class === this.appConfig.Shared.Regimen.Class.HAIR) {
      this.eventLoggerService.trackEvent('BUY_NOW_CLICKED_HAIR');
      this.eventLoggerService.trackInFirebaseAndBranch('BUY_NOW_CLICKED_HAIR');
    }
    this.router.navigate(['/user/checkout'],
      { queryParams: { type: 'REGIMEN', regimenId: this.regimen.regimenId }, replaceUrl: true });
  }

  async readInstructions(): Promise<void> {
    this.eventLoggerService.trackEvent('REGIMEN_VIEW_READ_INSTRUCTION', { username: this.user?.get('username') });
    await this.router.navigate(['/user'],
      {
        queryParams: {
          tab: 'regimen',
          class: this.regimen?.class,
          instructions: true,
        },
      });
  }

  back(): void {
    this.location.back();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { InputModule } from '@components/inputs';
import { CsIconModule } from '@components/icon';
import { PopUpModule } from '@components/pop-up';
import { DiscontinuedPopupModule } from '@shared/discontinued-popup/discontinued-popup.module';
import { ProductCartComponent } from './cart.component';
import { AovBannerComponent } from './aov-banner/app-aov-banner.component';

@NgModule({
  imports: [
    CommonModule,
    ToolbarModule,
    LoadingModule,
    ButtonModule,
    InputModule,
    CsIconModule,
    PopUpModule,
    DiscontinuedPopupModule,
    AovBannerComponent,
  ],
  declarations: [ProductCartComponent],
  exports: [],
})
export class ProductCartModule {}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UnpaidPendingCallService {
  /**
   * Main method that checks all criteria using helper functions.
   */
  checkEligibility(
    variantData: any,
    user: any,
    osInfo: string,
    phoneDetails: any,
    regimenClass: string,
  ): boolean {
    if (!variantData) return false;
    const osLower = osInfo?.toLowerCase() || '';
    const fallbackCriteria = {
      web: {
        Class: ['Face'],
        languagePreference: ['en'],
        order_state: ['NEW_USER'],
      },
      ios: {
        Class: ['Face'],
        languagePreference: ['en'],
        order_state: ['NEW_USER'],
      },
      android: {
        Class: ['Face'],
        Gender: ['Female'],
        languagePreference: ['en'],
        minAge: 24,
        order_state: ['NEW_USER'],
        phoneModel: ['abc', 'test-phone'],
      },
    };

    let osConfig: { [key: string]: any; };
    if (variantData.criteria && variantData.criteria[osLower] !== undefined) {
      osConfig = variantData.criteria[osLower];
    } else if (osLower === 'web') {
      osConfig = undefined;
    } else {
      osConfig = fallbackCriteria[osLower];
    }

    if (!osConfig) {
      return osLower === 'web';
    }

    let userPhoneModel = '';
    if (phoneDetails) {
      const userPhoneDetails = phoneDetails
        .split(',')
        .find((part: string): any => part.trim().toLowerCase().startsWith('model:'));
      if (userPhoneDetails) {
        userPhoneModel = userPhoneDetails.split(':')[1].trim();
      }
    }

    const validators: { [key: string]: (user: any, expected: any) => boolean } = {
      languagePreference: (user: any, expected: string[]): boolean => {
        const userLang = (user?.get('languagePreference') || '').toLowerCase();
        return expected.map((val: string): string => val.toLowerCase()).includes(userLang);
      },
      order_state: (user: any, expected: string[]): boolean => {
        const userOrderState = (user?.get('orderState') || '').toUpperCase();
        return expected.map((val: string): string => val.toUpperCase()).includes(userOrderState);
      },
      Class: (_user: any, expected: string[]): boolean => expected
        .map((val: string): string => val.toLowerCase()).includes(regimenClass.toLowerCase()),
      Gender: (user: any, expected: string[]): boolean => expected.includes(user?.get('Gender')),
      minAge: (user: any, expected: number): boolean => {
        const userAge = user?.get('Age');
        return userAge >= expected;
      },
      phoneModel: (_user: any, expected: string[]): boolean => {
        if (!userPhoneModel) return false;
        const allowedModels = expected.map((m: string): string => m.toLowerCase());
        return allowedModels.includes(userPhoneModel.toLowerCase());
      },
    };

    return Object.keys(osConfig).every((key: string): boolean => {
      const expectedValue = osConfig[key];
      const validator = validators[key];
      return validator
        ? validator(user, expectedValue)
        : user?.get(key) === expectedValue;
    });
  }
}

import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-call-scheduled-bottomsheet',
  standalone: true,
  imports: [],
  templateUrl: './call-scheduled-bottomsheet.component.html',
})
export class CallScheduledBottomsheetComponent {
  user: any;
  userNumber: number;
  userName: string;
  currentDisplayText: string;
  constructor(
    // eslint-disable-next-line new-cap
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public appConfig: AppConfig,
    private readonly bottomSheetRef: MatBottomSheetRef,
    public readonly conn: ConnectionService,
  ) {}

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.userName = this.user?.get('PatientName');
    this.userNumber = this.user?.get('MobileNumber');
    this.currentDisplayText = this.data.currentDisplayText;
  }

  closePopup(): void {
    this.bottomSheetRef.dismiss();
  }
}

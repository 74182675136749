import { Injectable } from '@angular/core';
import { TimeService } from '@services/time-service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDateService {
  differenceInDays: number = 0;
  constructor(private readonly timeService: TimeService) {}

  calculateExpectedDeliveryDate(
    responseDateUTC: string | null,
    deliveryIntervalInDays: number = 3,
  ): { from: Date; to: Date } {
    const today: Date = new Date();

    if (responseDateUTC) {
      const deliveryDate: Date = new Date(responseDateUTC);
      const dayDifference: number = this.getDeliveryDateDifference(responseDateUTC, today);

      if (dayDifference >= 5) {
        // Case 1: Delivery date is 5 or more days from today
        return {
          from: this.timeService.addDays(today, 3),
          to: this.timeService.addDays(today, 5),
        };
      }
      if (dayDifference > 0 && dayDifference < 5) {
        // Case 2: Delivery date is within the next 5 days
        return {
          from: this.timeService.addDays(deliveryDate, -1),
          to: this.timeService.addDays(deliveryDate, 1),
        };
      }
    }
    // Default or Case 3: Delivery date is today or in the past
    return {
      from: this.timeService.addDays(today, 2),
      to: this.timeService.addDays(today, 2 + deliveryIntervalInDays),
    };
  }

  getDeliveryDateDifference(deliveryDateInUTC: string | null, todayDate: Date): number {
    const deliveryDate: Date = new Date(deliveryDateInUTC);
    return this.timeService.differenceInDays(deliveryDate, todayDate);
  }
}

<div class="tw-h-8 tw-p-2  tw-rounded-full tw-relative tw-shadow-z4"
     [ngClass]="{'tw-bg-gray-80': isDoctorImageVariant, 'tw-bg-gray-75 tw-pr-8': !isDoctorImageVariant}"
>
    <!-- Background background overlay -->
    <div class="tw-absolute tw-inset-0 tw-rounded-full tw-overflow-hidden">
      <div class="tw-h-full tw-bg-teal-600 tw-transition-all tw-duration-300 tw-rounded-full"
           [ngClass]="{'tw-opacity-100': isPlaying, 'tw-opacity-0': !isPlaying}"
           [style.width.%]="backgroundProgress">
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-gap-3 tw-w-full tw-relative tw-h-full">
      <!-- Play/Pause Button -->
      <div
        (click)="handlePlayPause()"
        [ngClass]="{'tw-bg-gray-75': isPlaying, 'tw-bg-teal-600': !isPlaying}"
        class="tw-w-8 tw-h-8 tw-rounded-full tw-bg-teal-600 tw-flex tw-justify-center tw-items-center active:tw-scale-95 tw-transition-all">
        <span *ngIf="!isPlaying" class="tw-text-white">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.01053 2.82974C5.01058 2.24153 3.75 2.96251 3.75 4.12264V13.8774C3.75 15.0375 5.01058 15.7585 6.01053 15.1703L14.3021 10.2929C15.288 9.71294 15.288 8.28709 14.3021 7.70711L6.01053 2.82974Z"
              fill="currentColor"/>
          </svg>
        </span>
        <span *ngIf="isPlaying" class="tw-text-teal-600">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="3" width="4" height="12" fill="currentColor"/>
            <rect x="10" y="3" width="4" height="12" fill="currentColor"/>
          </svg>
        </span>
      </div>

      <!-- Progress Bar and Time -->
      <div class="tw-flex-1 tw-flex tw-items-center tw-justify-between tw-gap-3">
        <div id="audioWaveformContainer" class="tw-relative tw-flex-1 tw-h-8">
          <!-- Waveform container -->
          <div class="tw-absolute tw-inset-0 tw-flex tw-items-center">
            <div class="tw-flex tw-items-center tw-gap-[4px] tw-w-full tw-h-full">
              <div *ngFor="let bar of waveformBars; let i = index"
                   class="tw-flex-1 tw-h-full tw-flex tw-items-center">
                <div [class]="getBarClass(i)"
                     [ngClass]="{'tw-opacity-[30%]': isPlaying && !(i <= getCurrentBar())}"
                     [style.height.%]="bar"
                     [style.background-color]="isPlaying ? 'var(--cureskin-gray-50)' : 'var(--cureskin-gray-600)'">
                </div>
              </div>
            </div>
          </div>
          <!-- Invisible click area for seeking -->
          <div class="tw-absolute tw-inset-0"
               (click)="seekToPosition($event)">
          </div>
        </div>
        <div *ngIf="isDoctorImageVariant" class="tw-w-10 tw-relative">
          <img class="tw-rounded-full tw-w-[42px] tw-aspect-square tw-border-white tw-border-[1px]" [src]="docImg" />
          <img alt="green mic" src="/assets/icons/green-mic.svg" class="tw-absolute -tw-left-1 tw-bottom-0"/>
        </div>
        <span *ngIf="!isDoctorImageVariant" class="tw-text-300"
              [ngClass]="{'tw-text-gray-75': isPlaying, 'tw-opacity-[66%]': !isPlaying}"
        >
          {{isPlaying ? getRemainingTime() : formatTime(audioDuration)}}
        </span>
      </div>
    </div>
  </div>

/**
 * Extract product images/video for slideshow.
 *
 * First image/video should be main product image then add extraImages
 *
 * @param product
 * @returns {type: 'video' | 'image', url: string}[]
 */
export function extractAssetsForSlider(product: any): { type: 'video' | 'image', url: string }[] {
  if (!product) return [];
  const assets: any[] = [];
  const { rebrandedImageWithBackground, extraImages }: any = product;

  if (rebrandedImageWithBackground?.length) {
    if (rebrandedImageWithBackground[0].split('.').pop() === 'mp4') {
      assets.push({ type: 'video', url: rebrandedImageWithBackground[0] });
    } else {
      assets.push({ type: 'image', url: rebrandedImageWithBackground[0] });
    }
  }

  const extraImagesFlat = extraImages?.flat();
  if (extraImagesFlat?.length) {
    extraImagesFlat.forEach((image: string): void => {
      if (image?.split('.')?.pop() === 'mp4') {
        assets.push({ type: 'video', url: image });
      } else {
        assets.push({ type: 'image', url: image });
      }
    });
  }
  return assets;
}

/**
 * Extract instruction video url in user's language based on user's status(paid/unpaid)
 *
 * @param product
 * @param user
 * @returns
 */
export function extractInstructionVideo(product: any, user: any): string | null {
  const userLanguage = user?.get('languagePreference') || 'en';
  const instructionSetIncoming = product?.instructionSet;
  const instructionSet = product?.product?.instructions?.find((element: any): any => element.title === instructionSetIncoming.title);
  if (!instructionSet) return null;

  if (user?.isPaid() && instructionSet?.paidInstructionVideo) {
    return instructionSet?.paidInstructionVideo[userLanguage] || instructionSet?.paidInstructionVideo?.en;
  }
  if (instructionSet?.unpaidInstructionVideo) {
    return instructionSet?.unpaidInstructionVideo[userLanguage] || instructionSet?.unpaidInstructionVideo?.en;
  }
  return null;
}

import { Routes } from '@angular/router';
import { ChoosePlanInfoComponent } from '@shared/choose-plan-info/choose-plan-info.component';
import { EmptyComponent } from './empty/empty.component';
import { LoginDoctorComponent } from './login/doctor/login-doctor.component';
import { LogoutComponent } from './logout/logout.component';
import { MainConcernComponent } from './main-concern/main-concern.component';
import { HelpComponent } from './help/help.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { ArticleComponent } from './article/article.component';
import { OnboardingLanguageComponent } from './onboarding-language/onboarding-language.component';
import { IframeComponent } from './iframe/iframe.component';
import { NoticeComponent } from './notice/notice.component';
import { VideoComponent } from './video/video.component';
import { ReminderFullPageComponent } from './reminder';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { ProductCartComponent } from './cart/cart.component';
import { QuizGameComponent } from './quiz-game/quiz-game.component';
import { ReportComponent } from './report/report.component';
import { authenticationGuard } from './guards/authentication.guard';
import { onboardingGuard } from './guards/onboarding.guard';
import { TestimonialsNewComponent } from './testimonial/testimonials-new/testimonials-new.component';
import { TestimonialsDescComponent } from './testimonial/testimonials-description/testimonials-description.component';
import { UserCheckoutComponent } from './user-checkout/user-checkout.component';
import { TestimonialCaseStudiesInfoComponent } from './testimonial/testimonial-case-studies-info/testimonial-case-studies-info.component';
import { UserNotificationComponent } from './user-notification/user-notification.component';
import { UserRegimenDoctorComponent } from './user-regimen/user-regimen-doctor/user-regimen-doctor.component';
import { UserRegimenServicesComponent } from './user-regimen/user-regimen-services/user-regimen-services.component';
import {
  CheckupCaptureComponent, CheckupListComponent,
  CheckupPhotoInstructionComponent, CheckupViewContainerComponent,
  SelectCheckupTypeComponent,

} from './instant-checkup/components';
import { UserAddressListComponent } from './user-address/user-address-list/user-address-list.component';
import { UserAddressEditComponent } from './user-address/user-address-edit/user-address-edit.component';
import { UserFollowupReportComponent } from './user-followup-report/user-followup-report.component';
import { UserOrderListComponent } from './user-order/user-order-list/user-order-list.component';
import { UserOrderReorderComponent } from './user-order/user-order-reorder/user-order-reorder.component';
import { UserOrderSuccessComponent } from './user-order/user-order-success/user-order-success.component';
import { UserOrderFailureComponent } from './user-order/user-order-failure/user-order-failure.component';
import { UserOrderAddonComponent } from './user-order/user-order-addon/user-order-addon.component';
import { UserOrderPaymentComponent } from './user-order/user-order-payment/user-order-payment.component';
import { UserOrderViewComponent } from './user-order/user-order-view/user-order-view.component';
import { UserPrescriptionViewComponent } from './user-prescription/user-prescription-view.component';
import { UserReferComponent } from './user-refer/user-refer.component';
import { UserWalletViewComponent } from './user-wallet/user-wallet-view/user-wallet-view.component';
import { UserWalletTransactionsComponent } from './user-wallet/user-wallet-transactions/user-wallet-transactions.component';
import { UserProfileComponent } from './user-profile/user-profile/user-profile.component';
import { UserProfileEditComponent } from './user-profile/user-profile-edit/user-profile-edit.component';
import { UserCheckupComponent } from './user-checkup/user-checkup.component';
import { RegimenProductsDetailComponent } from './user-regimen/regimen-products-detail/regimen-products-detail.component';
import { regimenProductsDetailGuard } from './user-regimen/regimen-products-detail.guard';
import { UserRegimenProductsViewComponent } from './user-regimen/user-regimen-products-view/user-regimen-products-view.component';

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [authenticationGuard],
    redirectTo: '/user?tab=home&init=true',
  },
  {
    path: 'language',
    component: OnboardingLanguageComponent,
    data: { pageOpenEvent: { name: 'LanguagePage' } },
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'cart',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductCartComponent,
    data: { pageOpenEvent: { name: 'CartPage' } },
  },
  {
    path: 'notice',
    canActivate: [authenticationGuard, onboardingGuard],
    component: NoticeComponent,
    data: { pageOpenEvent: { name: 'NoticePage' } },
  },
  {
    path: 'video',
    canActivate: [authenticationGuard, onboardingGuard],
    component: VideoComponent,
  },
  {
    path: 'mainConcern',
    canActivate: [authenticationGuard],
    component: MainConcernComponent,
    data: { pageOpenEvent: { name: 'MainConcernPage' } },
  },
  {
    path: 'progress/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/user/report/:id',
  },
  {
    path: 'report',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReportComponent,
    data: { pageOpenEvent: { name: 'ReportPage' } },
  },
  {
    path: 'help',
    data: {
      pageOpenEvent: {
        name: 'HelpPage',
      },
    },
    canActivate: [authenticationGuard, onboardingGuard],
    component: HelpComponent,
  },
  {
    path: 'quiz-game',
    canActivate: [authenticationGuard, onboardingGuard],
    component: QuizGameComponent,
    data: { pageOpenEvent: { name: 'QuizPage' } },
  },
  {
    path: 'feedback',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
    data: { pageOpenEvent: { name: 'FeedbackPage' } },
  },
  {
    path: 'feedback/:category',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
    data: { pageOpenEvent: { name: 'FeedbackCategoryPage' } },
  },
  {
    path: 'login/doctor',
    component: LoginDoctorComponent,
    data: { pageOpenEvent: { name: 'DoctorLoginPage' } },
  },
  {
    path: 'empty',
    canActivate: [authenticationGuard, onboardingGuard],
    component: EmptyComponent,
  },
  {
    path: 'addonProduct/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
    data: { pageOpenEvent: { name: 'AddOnProductArticlePage' } },
  },
  {
    path: 'article/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
    data: { pageOpenEvent: { name: 'ArticleIdPage' } },
  },
  {
    path: 'product/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductDescriptionComponent,
    data: { pageOpenEvent: { name: 'ProductDescriptionPage' } },
  },
  {
    path: 'iframe',
    component: IframeComponent,
  },
  {
    path: 'user',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: UserViewComponent,
        pathMatch: 'full',
      },
      { path: 'testimonialsNew', component: TestimonialsNewComponent, data: { pageOpenEvent: { name: 'TestimonialNewPage' } } },
      { path: 'testimonialsNew/:type/:testimonialId',
        component: TestimonialsDescComponent,
        data: { pageOpenEvent: { name: 'TestimonialNewIdPage' } } },
      { path: 'caseStudies/:caseStudyId',
        component: TestimonialCaseStudiesInfoComponent,
        data: { pageOpenEvent: { name: 'CaseStudyPage' } } },
      { path: 'checkout', component: UserCheckoutComponent, data: { pageOpenEvent: { name: 'CheckoutPage' } } },
      { path: 'followups', component: UserCheckupComponent, data: { pageOpenEvent: { name: 'FollowUpPage' } } },
      { path: 'checkup', component: UserCheckupComponent, data: { pageOpenEvent: { name: 'CheckUpPage' } } },
      { path: 'notifications', component: UserNotificationComponent, data: { pageOpenEvent: { name: 'NotificationPage' } } },
      { path: 'followup/reminder', component: UserCheckupComponent, data: { pageOpenEvent: { name: 'FollowupReminderPage' } } },
      {
        path: 'regimen',
        data: { pageOpenEvent: { name: 'RegimenPage' } },
        children: [
          { path: 'products',
            canActivate: [regimenProductsDetailGuard],
            component: UserRegimenProductsViewComponent,
            data: { pageOpenEvent: { name: 'RegimenProductPage' } } },
          { path: 'revamp-products',
            component: RegimenProductsDetailComponent,
            data: { pageOpenEvent: { name: 'RegimenRevampProductPage' } } },
          { path: 'doctor',
            component: UserRegimenDoctorComponent,
            data: { pageOpenEvent: { name: 'RegimenDoctorPage' } } },
          { path: 'services', component: UserRegimenServicesComponent, data: { pageOpenEvent: { name: 'RegimenServicePage' } } },
          { path: 'plan-info', component: ChoosePlanInfoComponent, data: { pageOpenEvent: { name: 'RegimenPlainInfoPage' } } },
        ],
      },
      {
        path: 'address',
        children: [
          { path: '', component: UserAddressListComponent, data: { pageOpenEvent: { name: 'AddressPage' } } },
          { path: ':id/edit', component: UserAddressEditComponent, data: { pageOpenEvent: { name: 'AddressEditPage' } } },
          { path: 'new', component: UserAddressEditComponent, data: { pageOpenEvent: { name: 'AddressNewPage' } } },
        ],
      },
      { path: 'addresses', component: UserAddressListComponent, data: { pageOpenEvent: { name: 'AddressesPage' } } },
      { path: 'report/:id', component: UserFollowupReportComponent, data: { pageOpenEvent: { name: 'ReportIdPage' } } },
      {
        path: 'instantCheckups',
        children: [
          { path: '', component: CheckupListComponent, data: { pageOpenEvent: { name: 'InstantCheckupListPage' } } },
          { path: 'preview', redirectTo: '/instant-checkup/preview', pathMatch: 'full' },
        ],
      },
      {
        path: 'instantCheckup',
        children: [
          { path: '', component: SelectCheckupTypeComponent, data: { pageOpenEvent: { name: 'InstantCheckupPage' } } },
          { path: 'menu', component: SelectCheckupTypeComponent },
          { path: 'preview', component: CheckupPhotoInstructionComponent },
          { path: 'capture', component: CheckupCaptureComponent },
          { path: 'list', component: CheckupListComponent },
          { path: ':id', component: CheckupViewContainerComponent },
        ],
      },
      {
        path: 'orders',
        children: [
          { path: '', component: UserOrderListComponent, data: { pageOpenEvent: { name: 'OrdersListPage' } } },
          { path: 'success', component: UserOrderSuccessComponent, data: { pageOpenEvent: { name: 'OrdersListSuccessPage' } } },
          { path: 'failure', component: UserOrderFailureComponent, data: { pageOpenEvent: { name: 'OrdersListFailurePage' } } },
          { path: 'reorder', component: UserOrderReorderComponent, data: { pageOpenEvent: { name: 'OrdersListReorderPage' } } },
        ],
      },
      {
        path: 'order',
        children: [
          { path: '', component: UserOrderListComponent, data: { pageOpenEvent: { name: 'OrderPage' } } },
          { path: 'list', component: UserOrderListComponent, data: { pageOpenEvent: { name: 'OrderListPage' } } },
          { path: 'reorder', component: UserOrderReorderComponent, data: { pageOpenEvent: { name: 'OrderListReorderPage' } } },
          { path: 'success', component: UserOrderSuccessComponent, data: { pageOpenEvent: { name: 'OrderListSuccessPage' } } },
          { path: 'failure', component: UserOrderFailureComponent, data: { pageOpenEvent: { name: 'OrderListFailurePage' } } },
          { path: 'addon', component: UserOrderAddonComponent, data: { pageOpenEvent: { name: 'OrderAddOnPage' } } },
          { path: ':id/payment', component: UserOrderPaymentComponent, data: { pageOpenEvent: { name: 'OrderIdPaymentPage' } } },
          { path: ':id', component: UserOrderViewComponent, data: { pageOpenEvent: { name: 'OrderIdViewPage' } } },
        ],
      },
      { path: 'prescription', component: UserPrescriptionViewComponent, data: { pageOpenEvent: { name: 'PrescriptionPage' } } },
      { path: 'refer', component: UserReferComponent, data: { pageOpenEvent: { name: 'ReferPage' } } },
      {
        path: 'wallet',
        children: [
          { path: '', component: UserWalletViewComponent, data: { pageOpenEvent: { name: 'WalletViewPage' } } },
          { path: 'transactions', component: UserWalletTransactionsComponent, data: { pageOpenEvent: { name: 'WalletTransactionPage' } } },
        ],
      },
      {
        path: 'profile',
        children: [
          { path: '', component: UserProfileComponent, data: { pageOpenEvent: { name: 'ProfileViewPage' } } },
          { path: 'edit', component: UserProfileEditComponent, data: { pageOpenEvent: { name: 'ProfileEditPage' } } },
        ],
      },
    ],
  }, {
    path: 'reminder/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReminderFullPageComponent,
    outlet: 'reminder',
  }, {
    path: '**',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/',
  },
];

const declarations: any = [];

export { AppRoutes, declarations };

import { Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-call-completed-banner',
  standalone: true,
  imports: [],
  templateUrl: './call-completed-banner.component.html',
})
export class CallCompletedBannerComponent {
  user: any;
  username: string;
  constructor(
    private readonly conn: ConnectionService,
    private readonly eventLogger: EventLoggerService) {
  }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.username = this.user?.get('PatientName')?.split(' ')[0];
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'regimen_unpaid_user_call_completed' }));
  }
}

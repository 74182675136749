<div class="bottom-sheet tw-font-body tw-py-6 tw-px-4 tw-rounded-tl-4 tw-rounded-tr-4 tw-bg-white">
  <div class="tw-pb-4 tw-border-b tw-border-b-gray-400 tw-border-solid">
    <h2 class="tw-text-400 tw-text-black tw-font-bold" i18n="@@reasonForCancellation"> Reason for Cancellation </h2>
    <p class="tw-text-200 tw-font-medium tw-mt-1.5" i18n="@@pleaseTellUsCorrectReasonForCancellation"> Please tell us correct reason for cancellation. <br /> This information is only used to improve our service. </p>
  </div>
  <div class="tw-pt-4">
    <h1 class="tw-mb-3 tw-text-100 tw-font-bold tw-text-black tw-uppercase" i18n="@@selectReason"> select reason </h1>
    <form [formGroup]="orderCancelForm" (ngSubmit)="onCancelFormSubmit()">
      <div class="tw-flex tw-flex-col tw-gap-4">
        <div *ngFor="let reason of cancelReasons" class="tw-flex tw-items-center tw-gap-1">
          <input class="tw-relative tw-w-4 tw-h-4 tw-border tw-border-gray-400 tw-border-solid tw-rounded-full tw-outline-none checked:tw-border-solid checked:tw-border checked:tw-border-teal-600  checked:before:tw-contents-[''] checked:before:tw-w-2.5 checked:before:tw-h-2.5 checked:before:tw-absolute checked:before:tw-top-[50%] checked:before:tw-left-[50%] checked:before:tw-transform checked:before:-tw-translate-x-[50%] checked:before:-tw-translate-y-[50%] checked:before:tw-bg-teal-600 checked:before:tw-rounded-full" 
                 type="radio"
                 formControlName="reason" 
                 [value]="reason" 
                 [id]="reason"/>
          <label class="tw-mb-0" [for]="reason"><translate-string [key]="reason"></translate-string></label>
        </div>
      </div>
      <textarea class="tw-w-full tw-font-body tw-mt-4 tw-min-h-0 placeholder:tw-text-200 placeholder:tw-text-gray-300" formControlName="additionalComments" rows="3" maxlength="50" i18n-placeholder="@@additionalComments" placeholder="Addiitonal Comments" (input)="onTextareaInput($event)"></textarea>
      <div *ngIf="isTextAreaLimitReached" class="tw-text-red-400 tw-border-b-red-400 tw-flex tw-items-center tw-pl-3 tw-gap-1">
        <img src="/assets/images/warn-err.png" class="tw-h-3">
        <p class="tw-text-red-400 tw-text-200" i18n="@@pleaseKeepYourInputUnder50Characters"> Please keep your input under 50 characters </p>
      </div>
      <div class="tw-flex tw-gap-2 tw-mt-8">
        <span (click)="dontCancel()" class="tw-flex tw-justify-center tw-items-center tw-font-bold tw-w-[50%] tw-px-4 tw-py-2 tw-text-teal-600 tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize" i18n="@@dontCancel">don't cancel</span>
        <span [ngClass]="orderCancelForm.invalid ? 'tw-bg-gray-300' : 'tw-bg-teal-600'"
              (click)="orderCancelForm.invalid ? null : onCancelFormSubmit()"
              class="tw-flex tw-justify-center tw-items-center tw-font-bold tw-w-[50%] tw-px-4 tw-py-2 tw-text-white tw-rounded-xl tw-text-300 tw-overflow-hidden tw-capitalize"
              i18n="@@cancel">Cancel</span>
      </div>
    </form>
  </div>
</div>
import { Component, Input } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { NgIf } from '@angular/common';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-doctor-call-unsuccessful-banner',
  standalone: true,
  imports: [
    NgIf,
  ],
  templateUrl: './doctor-call-unsuccessful-banner.component.html',
})
export class DoctorCallUnsuccessfulBannerComponent {
  user: any;
  username: string;
  @Input() status: any;
  constructor(
    private readonly conn: ConnectionService,
    public appConfig: AppConfig,
    private readonly eventLogger: EventLoggerService) {
  }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.username = this.user?.get('PatientName')?.split(' ')[0];
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'regimen_unpaid_user_call_RNR' }));
  }
}

/**
 * Extract unique regimen products
 *
 * This function will return the list of products in regimen.
 * All the products will have an extra property `use`, which contains the
 * 'monring', 'night' or both based on the usage.
 *
 * The order of products array -
 * 1. all the morning products.
 * 2. all the night products excluding morning products.
 *
 * @param regimen
 * @returns array of products
*/
export function extractRegimenProducts(regimen: any): any[] {
  const productMap = new Map();
  const morningProducts = (<any[]>regimen?.morning);
  const nightProducts = (<any[]>regimen?.night);

  morningProducts?.forEach((p: any): void => {
    if (!productMap.has(p.product.objectId)) productMap.set(p.product.objectId, { ...p, use: ['morning'] });
  });

  nightProducts?.forEach((p: any): void => {
    if (!productMap.has(p.product.objectId)) productMap.set(p.product.objectId, { ...p, use: ['night'] });
    else productMap.get(p.product.objectId)?.use.push('night');
  });
  const uniqueProducts = Array.from(productMap.values());

  return uniqueProducts;
}

export function setRegimenPricing(param: any): any {
  if (!param) return null;
  const regimen: any = { ...param };
  regimen.totalMRP = regimen?.fixedPriceMRP;
  regimen.totalSP = regimen?.fixedPrice;
  regimen.discount = Math.floor(((regimen.totalMRP - regimen.totalSP) * 100) / regimen.totalMRP);

  return regimen;
}

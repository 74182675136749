import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { WindowRefService } from '@services/window-ref-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { ElasticService } from '@services/elastic-search';
import { AppConfig } from '../../../../../../app.config';

@Component({ selector: 'audio-chat', templateUrl: './audio.html', styleUrls: ['./audio.scss'] })
export class AudioComponent {
  chat: any;
  isInternalUser: any;
  @Input()
  set data(data: any) {
    this.chat = data;
  }
  @Input('user') user: any;
  @Input('doctor') doctor: any;
  @Input('isDoctorCallExperiment') isDoctorCallExperiment: boolean = false;
  constructor(private dom: DomSanitizer,
    private window: WindowRefService,
    private conn: ConnectionService,
    private router: Router,
    private elasticService: ElasticService,
    public appConfig: AppConfig,
    private eventLogger: EventLoggerService) {
  }

  ngOnInit(): void {
    this.isInternalUser = this.conn.isInternalUser();
  }

  async onAudioPlay(event: any, chat: any): Promise<void> {
    if (this.isDoctorCallExperiment) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'unpaid-doctor-audio-play' }));
    }
    if (this.chat.className === 'SupportChat') {
      await this.conn.trackVoiceNotesAndScrollEvents({
        objectId: this.chat.id,
        eventName: 'audioPlayed',
        trackingTable: 'supportChat',
      });
    }
    let operator = this.user.get('allocatedOperator');
    if (!operator?.createdAt) operator = await this.conn.findUserByObjectId(operator?.id);
    this.eventLogger.trackEvent('AUDIO_MESSAGE_PLAYED',
      { assistantId: chat.id, sentBy: operator?.get('username') });
  }

  async onAudioPlayEnd(event: any, chat: any): Promise<void> {
    if (this.isDoctorCallExperiment) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'unpaid-doctor-audio-end' }));
    }
    if (this.chat.className === 'SupportChat') {
      await this.conn.trackVoiceNotesAndScrollEvents({
        objectId: this.chat.id,
        eventName: 'audioFinished',
        trackingTable: 'supportChat',
      });
    }
    let operator = this.user.get('allocatedOperator');
    if (!operator?.createdAt) operator = await this.conn.findUserByObjectId(operator?.id);
    this.eventLogger.trackEvent('AUDIO_MESSAGE_PLAY_ENDED',
      { assistantId: chat?.id, sentBy: operator?.get('username') });
  }

  audioError(event: any): void {
    const log = (message: string): void => this.elasticService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      controller: 'user-chat',
      feature: 'audio',
      message,
    });

    switch (event.target.error.code) {
      case event.target.error.MEDIA_ERR_ABORTED:
        log('You aborted the video playback.');
        break;
      case event.target.error.MEDIA_ERR_NETWORK:
        log('A network error caused the audio download to fail.');
        break;
      case event.target.error.MEDIA_ERR_DECODE:
        log('The audio playback was aborted due to a corruption problem or because the video used features your browser did not support.');
        break;
      case event.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
        log('The video audio not be loaded, either because the server or network failed or because the format is not supported.');
        break;
      default:
        log('An unknown error occurred.');
        break;
    }
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { ToolbarComponent } from '@components/toolbar/toolbar.component';
import { ToolbarTabsComponent } from '@components/toolbar-tabs/toolbar-tabs.component';
import { AppConfig } from 'client/app/app.config';
import { Router } from '@angular/router';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'user-prescription-list',
  templateUrl: './user-prescription-view.html',
})
export class UserPrescriptionViewComponent implements OnInit {
  @ViewChild('toolbar', { static: false }) toolbar: ToolbarComponent;
  @ViewChild('toolbarTabs', { static: false }) toolbarTabs: ToolbarTabsComponent;
  user: any;
  iconSrc: string;
  doctor: any;
  isTabActive: boolean = false;
  tabData: any = { count: 2 };
  facePrescription: any = [];
  hairPrescription: any = [];
  regimenList: any = [];
  regimen: any;
  hairRegimenActive: boolean;
  faceRegimenActive: boolean;
  morning: Array<any> = [];
  night: Array<any> = [];
  regimenClasses: Array<any> = [];
  latestPrescription: any;
  loading: boolean = true;
  doctorDisplayName: string = '';
  signatureUrl: string;
  isInternalUser: boolean = false;
  constructor(private conn: ConnectionService,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private router: Router,
    private eventLogger: EventLoggerService) { }

  async ngOnInit(): Promise<any> {
    this.user = await this.conn.getActingUser();
    this.isInternalUser = this.conn.isInternalUser();
    this.regimenClasses = [
      this.appConfig.Shared.Regimen.Class.FACE,
      this.appConfig.Shared.Regimen.Class.HAIR,
    ];
    if (!this.user.get('allocatedDoctor')) {
      this.tabData.selectedTab = this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.FACE);
      this.setImageUrl();
      this.loading = false;
      return;
    }
    if (this.user.get('allocatedDoctor')) {
      this.doctor = await this.conn.findUserByObjectId(this.user.get('allocatedDoctor')?.id);
    }
    await this.fetchPrescription();
    this.setImageUrl();
    this.loading = false;
  }

  async fetchPrescription(): Promise<any> {
    this.regimenList = await this.conn.fetchRegimens();
    let facePrescription = [];
    let hairPrescription: any[] = [];
    this.regimenList.forEach(async (element: any): Promise<any> => {
      if (element.active && element.class === this.appConfig.Shared.Regimen.Class.FACE) {
        this.faceRegimenActive = true;
      }
      if (element.active && element.class === this.appConfig.Shared.Regimen.Class.HAIR) {
        this.hairRegimenActive = true;
      }
      this.fetchAllProductsByRegimen(element);
      const prescription = await this.conn.fetchPrescription(this.user.id, element.objectId);
      if (prescription.length) {
        if (element.class === this.appConfig.Shared.Regimen.Class.FACE) {
          prescription[prescription.length - 1].regimenData = element;
          facePrescription = prescription;
          this.facePrescription = facePrescription;
        }
        if (element.class === this.appConfig.Shared.Regimen.Class.HAIR) {
          prescription[prescription.length - 1].regimenData = element;
          hairPrescription = prescription;
          this.hairPrescription = hairPrescription;
          this.tabChange(this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.HAIR));
        }
        const orderData = await this.conn.getOrderId(prescription[0]?.regimenData?.regimenId);
        await this.conn?.checkForPrescriptionStatus(orderData[0]?.id);
        this.signatureUrl = this.conn.getDoctorSignature;
        this.doctorDisplayName = this.conn.getdoctorName;
      } else {
        this.tabData.selectedTab = this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.FACE);
      }
    });
  }

  fetchAllProducts(): void {
    this.morning = this.regimen.get('morning')
      .map((x: any): void => x.productName.split('[')[0])
      .map((name: any): void => {
        if (name.includes('(')) {
          return name.split('(')[0];
        }
        return name;
      });

    this.night = this.regimen.get('night')
      .map((x: any): void => x.productName.split('[')[0])
      .map((name: any): void => {
        if (name.includes('(')) {
          return name.split('(')[0];
        }
        return name;
      });
  }

  setImageUrl(): void {
    const regimenClass: string = this.regimenClasses[this.tabData.selectedTab] || this.regimenClasses[0];
    if (this.user?.get('Gender') === 'Female') {
      this.iconSrc = `https://cdn.cureskin.com/app/img/female-${regimenClass.toLowerCase()}.png`;
    } else {
      const lowerCaseRegimen = this.capitalizeWord(regimenClass.toLowerCase());
      this.iconSrc = `https://cdn.cureskin.com/app/img/Male%20${lowerCaseRegimen}_1.png`;
    }
  }

  /**
   * Triggers tree for the regimen and routes to chat tab to start answering the BOT questions. if the consultation session exists.
   * Else route to main concern page of specific class to start the consultation.
   */
  async triggerTreeForRegimen(className: any): Promise<void> {
    const session = await this.conn.findConsultationSession(className);
    if (session && session.get('PrivateMainConcern')) {
      this.navigateTo(`/user?tab=regimen&class=${className}`);
    } else {
      await this.router.navigate(['/mainConcern'], { queryParams: { class: className, redirectOnBack: '/home' } });
    }
  }

  navigateTo(url: string = ''): void {
    if (!url) return;
    const queryParams: Record<string, any> = {};
    const [URL, qParamString]: string[] = url.split('?');
    if (qParamString) {
      qParamString.split('&').forEach((each: string): void => {
        const [key, value]: string[] = each.split('=');
        queryParams[key] = value;
      });
    }
    this.eventLogger.trackEvent('home_page_url_open', { url });
    this.router.navigate([URL], { queryParams });
  }

  capitalizeWord(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  fetchAllProductsByRegimen(regimen: any): void {
    if (this.facePrescription.length) {
      this.tabData.selectedTab = this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.FACE);
    } else if (!this.facePrescription.length && this.hairPrescription.length) {
      this.tabData.selectedTab = this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.HAIR);
    } else {
      this.tabData.selectedTab = this.regimenClasses.indexOf(this.appConfig.Shared.Regimen.Class.FACE);
    }
    // eslint-disable-next-line no-param-reassign
    regimen.morning = regimen.morning
      .map((x: any): void => x.productName.split('[')[0])
      .map((name: any): void => {
        if (name.includes('(')) {
          return name.split('(')[0];
        }
        return name;
      });

    // eslint-disable-next-line no-param-reassign
    regimen.night = regimen.night
      .map((x: any): void => x.productName.split('[')[0])
      .map((name: any): void => {
        if (name.includes('(')) {
          return name.split('(')[0];
        }
        return name;
      });
    return regimen;
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }
  tabChange(index: any): void {
    this.tabData.selectedTab = index;
    this.setImageUrl();
  }
}

import { ChangeDetectorRef, Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import { Table } from '@cureskin/api-client';
import { EventLoggerService } from '@services/event-logger-service';
import { TimeService } from '@services/time-service';
import { AnimationOptions } from 'ngx-lottie';
import { AppConfig } from '../app.config';

@Component({ selector: 'quiz-game', templateUrl: './quiz-game.html', styleUrls: ['./quiz-game.scss'] })
export class QuizGameComponent {
  maxRewardAmount: number = 10;
  currentQuestion: any;
  selectedOption: any = null;
  lastTwoQuestions: any[] = [];
  alreadyAnswered: boolean = false;
  noMoreQuestions: boolean = false;
  rewardMoney: number;
  showConfettiAnimation: boolean = false;
  loading: boolean = true;
  isInternalUser: boolean = false;
  isCorrectAnswer: boolean = false;
  isWrongAnswer: boolean = false;
  userAnswers: any[] = [];
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    position: 'fixed',
    bottom: '0',
  };
  quizExp: any;
  user: any;

  sequenceCounter: number = 0;
  constructor(
    private conn: ConnectionService,
    public appConfig: AppConfig,
    private timeService: TimeService,
    private eventLogger: EventLoggerService,
  ) { }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getActingUser();
    this.isInternalUser = this.conn.isInternalUser();
    const experiments = await this.conn.findUserActiveExperiments();
    experiments.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'game_new') {
        this.maxRewardAmount = isNaN(+experiment.variant.maxRewardAmount) ? 10 : +experiment.variant.maxRewardAmount;
      }
    });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'quiz-multi-question' }));
    this.startGame();
  }

  async startGame(): Promise<void> {
    this.currentQuestion = null;
    this.isCorrectAnswer = false;
    this.isWrongAnswer = false;
    this.selectedOption = false;
    this.loading = true;

    let questions = await this.conn.fetchQuizQuestions();
    this.userAnswers = await this.conn.fetchUserAnswers(this.user);
    if (!this.userAnswers.length) {
      this.currentQuestion = questions[0];
      this.loading = false;
      return;
    }
    if (this.userAnswers.length) {
    // check if latest 3rd answer is today date
      const latestAnswerDate = new Date(this.userAnswers[0].get('createdAt'));
      if (!this.timeService.isAfter(new Date(), latestAnswerDate, 'day')) {
        this.alreadyAnswered = true;
        this.loading = false;
        return;
      }
      this.userAnswers.forEach((element: any): any => {
        if (!element.get('answeredCorrect')
        && this.timeService.isAfter(new Date(), element.get('createdAt'), 'day')
        && element.get('questionId')?.id) {
          questions.push(element.get('questionId'));
        }
        questions = questions.filter((question: any): any => question.id !== element.get('questionId')?.id);
      });
    }
    const questionsIds = questions.map((element: any): any => (typeof element === 'string' ? element : element.id));

    const remainingQuestions = await this.conn.findQuestionById(questionsIds);
    if (!remainingQuestions?.length) {
      this.noMoreQuestions = true;
    } else if (remainingQuestions.length) {
      this.currentQuestion = remainingQuestions[0];
    }

    this.loading = false;
  }

  back(): void {
    this.conn.navigateToURL('/user?tab=home', false, true);
  }

  async submit(option: any): Promise<any> {
    if (this.isInternalUser || this.selectedOption) {
      return;
    }
    this.selectedOption = option;
    if (option.id === this.currentQuestion.get('answer').id) {
      this.isCorrectAnswer = true;
    } else {
      this.isWrongAnswer = true;
    }

    const quizAnswers = new Table.QuizAnswers();
    quizAnswers.set('answeredCorrect', this.isCorrectAnswer);
    quizAnswers.set('questionId', this.currentQuestion);
    quizAnswers.set('user', this.conn.getActingUser());
    await quizAnswers.save();

    if (this.isCorrectAnswer) {
      const claim = await this.giveReward();
      await this.conn.claimReward(claim.id);
      const timeout = setTimeout((): void => {
        this.showConfettiAnimation = true;
        clearTimeout(timeout);
      }, 1500);
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'quiz-correct-answer' }));
    } else if (this.isWrongAnswer) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ pageName: 'quiz-wrong-answer' }));
    }
  }

  giveReward(): any {
    // reward between 1 to 15 rs
    this.rewardMoney = Math.floor(Math.random() * (this.maxRewardAmount) + 1);
    const reward = new Table.Reward();
    reward.set('type', 'GAME_REWARD');
    reward.set('isClaimed', false);
    reward.set('amount', this.rewardMoney);
    reward.set('user', this.conn.getActingUser());
    reward.set('isReferrer', false);
    reward.set('source', 'Game Reward');
    return reward.save();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from '@components/button';
import { CsIconModule } from '@components/icon';
import { InputModule } from '@components/inputs';
import { OrderListSheetComponent } from './order-list-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
    InputModule,
  ],
  declarations: [OrderListSheetComponent],
  exports: [OrderListSheetComponent],
})
export class OrderListSheetSheetModule { }

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Regimen } from '@cureskin/api-client/src/server-api/api';
import { Catalog } from '@cureskin/api-client/src/server-api';
import { TimeService } from '@services/time-service';
import { AppConfig } from '../../../app.config';
import { CollapsableCardItem, CollapsableCardState } from '../../../app-constant-types';

export enum KnowMoreClicked {
  'AUTO',
  'NEW',
  'NOT_YET_STARTED',
}
@Component({
  selector: 'app-instruction-card',
  templateUrl: './instruction-card.component.html',
  standalone: true,
  imports: [
    CsIconModule,
    NgClass,
    NgStyle,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class InstructionCardComponent {
  @Input() item: any;
  @Input() isDayTime: boolean;
  @Input() todayDayVal: number;
  @Input() days: any[];
  @Input() user: any;
  @Input() userLanguage: string;
  @Input() index: number;
  @Input() dayAfterDelivery: number;
  @Input() regimen: Regimen;
  @Input() changeInstructionForRepairProduct: boolean;
  @Input() removedProducts: Catalog[];
  @Input() collapsableCardState: CollapsableCardState = {
    onHold: [],
    removed: [],
    instructionChanged: [],
  };

  @Output() playButtonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() knowMoreClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() toggleProductCollapse: EventEmitter<any> = new EventEmitter<any>();

  private readonly PRODUCT_DELIVERY_WINDOW_DAYS: number = 7;

  constructor(
    public readonly appConfig: AppConfig,
    private readonly timeService: TimeService,
  ) { }

  onPlayButtonClick(): void {
    this.playButtonClick.emit(this.item);
  }

  onKnowMoreClicked(fromInstruction: KnowMoreClicked): void {
    this.knowMoreClicked.emit({ item: this.item, fromInstruction });
  }

  onToggleProductCollapse(productId: string): void {
    this.toggleProductCollapse.emit(productId);
  }

  isOnHoldCardOpen(productId: string): boolean {
    return !!this.collapsableCardState.onHold.find((p: CollapsableCardItem): boolean => p.productId === productId)?.isOpen;
  }

  isProductDelivered = (productId: string): boolean => {
    if (!this.regimen?.productTimestamps) return false;

    const deliveredTimestamp = this.regimen.productTimestamps?.[productId]?.deliveredTimestamp?.iso;
    if (!deliveredTimestamp) return false;

    const deliveredDate = new Date(deliveredTimestamp);
    const currentDate = new Date();
    const diffDays = this.timeService.differenceInDays(deliveredDate, currentDate);

    return diffDays <= this.PRODUCT_DELIVERY_WINDOW_DAYS;
  };

  isInstructionChanged = (productId: string): boolean => this.collapsableCardState.instructionChanged.includes(productId);

  isRemovedProductsInstruction = (productId: string): boolean => !!this.collapsableCardState
    .removed.find((item: CollapsableCardItem): boolean => item.productId === productId);
  isRemovedProductsInstructionOpen(productId: string): boolean {
    return !!this.collapsableCardState.removed.find((p: CollapsableCardItem): boolean => p.productId === productId)?.isOpen;
  }

  getKnowMoreClickType(): KnowMoreClicked {
    return this.isProductDelivered(this.item?.product?.objectId)
      ? KnowMoreClicked.NEW
      : KnowMoreClicked.NOT_YET_STARTED;
  }
}

<div class="tw-bg-white tw-flex tw-flex-col tw-rounded-[11px] tw-py-[30px]">
    <div class="bold">
        <span class="tw-flex tw-items-center tw-mx-[-15px]">
            <img class="tw-w-[43.5%] tw-mr-[5px]"
                [src]='oldProduct.rebrandedImageWithBackground && oldProduct.rebrandedImageWithBackground?.length ? oldProduct.rebrandedImageWithBackground[oldProduct?.rebrandedImageWithBackground?.length - 1] : false || oldProduct?.productUnboxedImage[oldProduct.productUnboxedImage.length - 1] || oldProduct?.images[oldProduct.images.length - 1]' />
            <div class="tw-absolute tw-left-[40%] tw-rounded-full tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-white tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
                <cs-icon class="tw-h-6 tw-w-6 tw-text-black" [iconName]="'arrow_right'"></cs-icon>
            </div>
            <img class="tw-w-[54.5%]" [src]='newProduct?.rebrandedImageWithBackground && newProduct?.rebrandedImageWithBackground.length ? newProduct.rebrandedImageWithBackground[newProduct?.rebrandedImageWithBackground.length - 1] : false || newProduct?.productUnboxedImage[newProduct?.productUnboxedImage.length - 1] || newProduct?.images[newProduct?.images.length - 1]' />
        </span>
        <div class="tw-pt-0 tw-pr-0 tw-pb-6 tw-text-center" *ngIf="oldProduct && newProduct" i18n="@@isUpgradedTo">
            <div class="font20 fw-500">
                {{oldProduct?.title || oldProduct?.hiddenTitle}}
            </div>
            <div class="font20 fw-500">&nbsp;is upgraded to</div>
            <div class="font20 fw-700">
                &nbsp;{{newProduct?.title || newProduct?.hiddenTitle}}
            </div>
        </div>
    </div>
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-3 tw-px-[10px]">
        <div class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-bg-green-400 tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-font-body tw-font-bold tw-text-200 tw-text-white tw-rounded-full" (click)="changeProduct.emit()">
            <span i18n="@@addtoCart">add to cart</span><cs-icon class="tw-h-4 tw-w-4 tw-text-white" [iconName]='"chevron_right"'></cs-icon>
        </div>
        <div class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-gap-3 tw-pl-4 tw-pr-3 tw-h-10 tw-border tw-border-gray-900 tw-font-body tw-font-bold tw-text-200 tw-text-black-500 tw-rounded-full"
            (click)="articleOpen.emit()">
            <span class="tw-lowercase"
                i18n="@@readMore">Read More</span>
            <cs-icon class="tw-h-4 tw-w-4 tw-text-green-400"
                    [iconName]='"chevron_right"'></cs-icon>
        </div>
    </div>
</div>

<div class="tw-fixed tw-left-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back.emit(true)">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'arrow_back'"></cs-icon>
</div>
<div class="tw-fixed tw-right-6 tw-top-6 tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" *ngIf="isPaidUser" routerLink="/cart">
  <cs-icon class="tw-h-6 tw-w-6 tw-text-white" [iconName]="'shopping_cart'"></cs-icon><span class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{ numberOfProductsInCart }}</span>
</div>
<div class="tw-flex tw-flex-col tw-h-full">
  <div class="tw-flex-1 tw-overflow-y-auto" #articleBlock="" [ngClass]="{ 'overrideBootstrap': article && article.get('cssLink') }">
    <link *ngIf="cssLink" itemprop="url" rel="stylesheet" [href]="cssLink" />
    <img *ngIf="articleHeaderImage" [src]="articleHeaderImage" />
    <div *ngIf="innerHTML" [innerHTML]="innerHTML"></div>
    <refund-policy *ngIf="showRefundPolicyOption"></refund-policy>
  </div>
<!--  Old CTA-->
  <div class="tw-flex-none tw-shadow-z1 tw-animate-slide_in_bottom" *ngIf="isPaidUser && product?.price !== 0 && innerHTML && clickToAction && clickToAction === appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT && !isDiscontinued && !buyNowExperiment">
    <div class="tw-flex tw-item-center tw-justify-center tw-py-2 tw-bg-yellow-100" *ngIf="+discount >= 5">
      <p class="tw-font-body tw-text-200 tw-text-black tw-flex tw-items-center tw-gap-1 tw-my-0">
      <cs-icon class="tw-h-5 tw-w-5 tw-text-green-400" [iconName]="'sale'"></cs-icon><span i18n="@@discountAppliedForProducts">
        {{ discount }}% discount applied</span><span i18n="@@amountSaved">(₹{{ product.mrp - product.price }} saved)</span>
      </p>
    </div>
    <div class="tw-flex tw-gap-4 tw-items-center tw-p-5">
      <div class="tw-flex-none" *ngIf="product.mrp !== product.price">
        <del class="tw-font-body tw-font-bold tw-text-600 tw-text-gray-900">₹{{ product.mrp }}</del>
        <span class="tw-font-body tw-font-bold tw-text-600 tw-text-orange-500">&nbsp;₹{{ product.price }}</span>
      </div>
      <div class="tw-flex-none" *ngIf="product.mrp === product.price">
        <span class="tw-font-body tw-font-bold tw-text-600 tw-text-green-400">₹{{ product.price }}</span>
      </div>
      <div class="tw-flex-auto tw-flex tw-items-center tw-justify-center tw-h-12" *ngIf="isAddRemovePending">
        <loading></loading>
      </div>
      <div class="button tw-flex-auto" *ngIf="!productInCart?.quantity && !isAddRemovePending">
        <cs-button [class]="'primary w100 shine tw-gap-2 tw-items-center tw-leading-none'" (callback)="addProductToCart()">
          <cs-button-text class="tw-font-body tw-font-bold tw-text-300" i18n="@@addToCart">Add to Cart</cs-button-text>
          <cs-icon class="tw-h-7 tw-w-7 tw-text-white" [iconName]="'chevron_right'"></cs-icon>
        </cs-button>
      </div>
      <div class="button tw-flex-auto" *ngIf="productInCart?.quantity && !isAddRemovePending">
      <div class="tw-border tw-rounded-full tw-border-green-400 tw-flex tw-items-center tw-justify-around tw-h-12">
        <div class="tw-flex-1 tw-flex tw-justify-center" (click)="removeProductFromCart()">
          <cs-icon class="tw-h-7 tw-w-7" [iconName]="'remove'"></cs-icon>
        </div>
        <p class="tw-flex-none tw-m-0 tw-font-body tw-font-300">{{ productInCart?.quantity }}</p>
        <div class="tw-flex-1 tw-flex tw-justify-center" (click)="addProductToCart()">
          <cs-icon class="tw-h-7 tw-w-7" [iconName]="'add'"></cs-icon>
        </div>
      </div>
      </div>
    </div>
  </div>
<!--New CTA-->
  <ng-container *ngIf="isPaidUser && product?.price !== 0 && innerHTML && clickToAction && clickToAction === appConfig.Shared.ArticleData.clickToAction.BUY_PRODUCT && !isDiscontinued && buyNowExperiment">
    <product-cta-container
      [isPaidUser]="isPaidUser"
      [product]="product"
      [discount]="discount"
      [isAddRemovePending]="isAddRemovePending"
      [productInCart]="productInCart"
      [buyNowExperiment]="buyNowExperiment"
      [clickToAction]="clickToAction"
      [isDiscontinued]="isDiscontinued"
      [innerHTML]="innerHTML"
      [appConfig]="appConfig"
      (addToCart)="addProductToCart()"
      (removeFromCart)="removeProductFromCart()"
      (handleBuyNow)="handleBuyNow()">
    </product-cta-container>
  </ng-container>
</div>

import { Component } from '@angular/core';
import { ConnectionService } from '@services/connection-service';
import {
  CallScheduledBottomsheetComponent,
} from '@shared/bottom-sheet-layouts/pending-call-bottomsheet/call-scheduled-bottomsheet/call-scheduled-bottomsheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-call-scheduled-banner',
  standalone: true,
  imports: [],
  templateUrl: './call-scheduled-banner.component.html',
})
export class CallScheduledBannerComponent {
  user: any;
  mobileNumber: string;
  constructor(
    private readonly conn: ConnectionService,
    private readonly bottomSheet: MatBottomSheet,
  ) {
  }

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.mobileNumber = this.user?.get('MobileNumber');
  }

  bannerClick(): void {
    const bottomSheet = this.bottomSheet.open(CallScheduledBottomsheetComponent, {});
    bottomSheet.backdropClick().subscribe((): void => {
      bottomSheet.dismiss();
    });
  }
}

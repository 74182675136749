<div class="tw-border tw-border-[#DCE8E5] tw-p-4 tw-rounded-3 tw-bg-gradient-light-teal tw-font-body tw-shadow-z1">
  <span>
    <img src="https://cdn.cureskin.com/app/img/warning_rounded.svg" />
  </span>
  <h1 class="tw-text-600 tw-font-bold tw-text-teal-600 tw-mt-2 tw-mb-3 tw-leading-6"><span class="tw-capitalize">{{ username }}</span>, our doctor tried reaching you</h1>
  <ng-container *ngIf="status === this.appConfig.Shared.PendingCall.Status.NotReachable">
    <p id="not-reachable" class="tw-font-body tw-leading-5 tw-text-300 tw-text-gray-500 tw-mb-2">
      We attempted to reach you multiple times but could not get a response. For further support, call our customer care at <span class="tw-text-nowrap">+91 81-500-96500.</span>
    </p>
  </ng-container>
  <ng-container *ngIf="status === this.appConfig.Shared.PendingCall.Status.CallLater">
    <p id="call-later" class="tw-font-body tw-leading-5 tw-text-300 tw-text-gray-500 tw-mb-2">
      We will try again in some time. Please pick up to continue your online consultation, or it will be automatically cancelled.
    </p>
  </ng-container>
</div>

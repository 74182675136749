import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../../app.config';

@Component({
  selector: 'app-pending-call-bottomsheet',
  standalone: true,
  imports: [],
  templateUrl: './request-call-bottomsheet.component.html',
})
export class RequestCallBottomsheetComponent {
  user: any;
  userNumber: number;
  currentDisplayText: string;
  regimen: any;
  loading: boolean = false;
  constructor(
    // eslint-disable-next-line new-cap
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public appConfig: AppConfig,
    private readonly bottomSheetRef: MatBottomSheetRef,
    public readonly conn: ConnectionService,
    private readonly eventLogger: EventLoggerService,
  ) {}

  ngOnInit(): void {
    this.user = this.conn.getActingUser();
    this.userNumber = this.user.get('MobileNumber');
    this.currentDisplayText = this.data.currentDisplayText;
    this.regimen = this.data.regimen;
  }

  closePopup(): void {
    this.bottomSheetRef.dismiss();
  }

  async requestCall(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'regimen_unpaid_user_call_request_popup' }));
    const regimenObjId = this.regimen.objectId;
    const req = await this.conn.createUnpaidUserDoctorCall(regimenObjId);
    this.loading = true;
    if (req) {
      this.loading = false;
      this.bottomSheetRef.dismiss({ pendingCallCreated: true });
    } else {
      this.loading = false;
      this.closePopup();
    }
  }
}

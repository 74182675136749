import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from '@components/toolbar';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { InputModule } from '@components/inputs';
import { DiscountTimerModule } from '@components/discount-timer/discount-timer.module';
import { TranslateStringsModule } from '@components/translate-string';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { DirectivesModule } from '@directives/directives.module';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { PopUpModule } from '@components/pop-up';
import { CsIconModule } from '@components/icon';
import { RegimenInstructionModule } from '@shared/regimen-instruction/regimen-instruction.module';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { SwiperModule } from 'swiper/angular';
import { ChoosePlanModule } from '@shared/choose-plan/choose-plan.module';
import { SkinReportSheetModule } from '@shared/bottom-sheet-layouts/skin-report/skin-report-sheet.module';
import { HomeBannersModule } from '@shared/home-banners/home-banners.module';
import { ReportModule } from 'client/app/report/report.module';
import { RefundPolicyModule } from '@shared/refund-policy/refund-policy.module';
import { RegimenPropositionModule } from '@shared/regimen-proposition/regimen-proposition.module';
import { RegimenOfferProductBannerModule } from '@shared/regimen-offer-product-banner/regimen-offer-product-banner.module';
import { TestimonialModule } from 'client/app/testimonial/testimonial.module';
import { RequestCallBannerComponent } from '@shared/pending-call-banners/request-call-banner/request-call-banner.component';
import { CallScheduledBannerComponent } from '@shared/pending-call-banners/call-scheduled-banner/call-scheduled-banner.component';
import { CallCompletedBannerComponent } from '@shared/pending-call-banners/call-completed-banner/call-completed-banner.component';
import {
  DoctorCallUnsuccessfulBannerComponent,
} from '@shared/pending-call-banners/doctor-call-unsuccessful-banner/doctor-call-unsuccessful-banner.component';
import { UserViewRegimenComponent } from './user-view-regimen.component';
import { UserViewRegimenConsultationComponent } from './user-view-regimen-consultation/user-view-regimen-consultation.component';
import { UserViewRegimenPreparationComponent } from './user-view-regimen-preparation/user-view-regimen-preparation.component';
import { RegimenPrescriptionComponent } from './user-view-regimen-unpaid/prescription/prescription.component';
import { UserViewRegimenUnpaidComponent } from './user-view-regimen-unpaid/user-view-regimen-unpaid.component';
import { UserViewRegimenEmptyComponent } from './user-view-regimen-empty/user-view-regimen-empty.component';
import { AudioModule } from '../user-assistant/user-chat/components/audio/audio.module';
import { ChatV2ViewModule } from '../../../chatV2-view/chatV2-view.module';
import { BannerScrollbarModule } from '../user-home/banner-scrollbar/banner-scrollbar.module';
import { ScrollSpyDirective } from './scroll-spy/scroll-spy.directive';
import { CrossRegimenBannerModule } from '../user-home/cross-regimen-banner/cross-regimen-banner.module';

export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    SwiperModule,
    ToolbarModule,
    RouterModule,
    LoadingModule,
    CsIconModule,
    ButtonModule,
    ToolbarTabsModule,
    DirectivesModule,
    DiscountTimerModule,
    InputModule,
    ProductListModule,
    TranslateStringsModule,
    VideoPlayerModule,
    RegimenInstructionModule,
    ChatV2ViewModule,
    PopUpModule,
    AudioModule,
    CrossRegimenBannerModule,
    BannerScrollbarModule,
    ChoosePlanModule,
    TestimonialModule,
    [LottieModule.forRoot({ player: playerFactory })],
    ReportModule,
    SkinReportSheetModule,
    RefundPolicyModule,
    HomeBannersModule,
    RegimenPropositionModule,
    RegimenOfferProductBannerModule,
    RequestCallBannerComponent,
    CallScheduledBannerComponent,
    CallCompletedBannerComponent,
    DoctorCallUnsuccessfulBannerComponent,
  ],
  declarations: [
    UserViewRegimenComponent,
    UserViewRegimenConsultationComponent,
    UserViewRegimenPreparationComponent,
    UserViewRegimenUnpaidComponent,
    UserViewRegimenEmptyComponent,
    RegimenPrescriptionComponent,
    ScrollSpyDirective,
  ],
  exports: [UserViewRegimenComponent],
})
export class UserViewRegimenModule {
}

<section class="takePhoto getSkinAnalysis pos-rel flex-col m16 bdr-rad-20 pB20 rebranded-diet-banner" id="takePhoto" *ngIf="userStatus.PAID && showGameBanner" (click)="handleClick()">
  <div class="game-img tw-bg-green-200 bdr-rad-20 tw-mb-4 tw-overflow-hidden">
    <img class="tw-bg-[#76539a] tw-w-full tw-h-auto" src="https://cdn.cureskin.com/article_image/1686717465_e71180c96f27476bbadc5e71178432c0.gif" />
  </div>
  <div class="fw-700 font20 mL16" i18n="@@takeQuiz3">
    Answer 3 easy questions
  </div>
  <div class="tw-flex tw-items-end">
    <div class="tw-flex-1 tw-flex tw-flex-col tw-self-center">
      <div class="mL16 tw-mr-3" i18n="@@newQuizDesc">
        Learn more about skin and hair by playing this game daily
      </div>
    </div>
    <div class="tw-mr-4">
      <cs-button [class]="'primary btn-xxxs tw-px-4'"><cs-button-text i18n="@@playNow">Play now</cs-button-text><cs-icon class="tw-h-4 tw-w-4" [iconName]="'chevron_right'"></cs-icon></cs-button>
    </div>
  </div>
</section>
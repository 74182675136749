import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AppConfig } from 'client/app/app.config';

@Component({
  selector: 'wrong-photo-detected',
  templateUrl: './wrong-photo-detected.component.html',
})
export class WrongPhotoDetectedComponent implements OnInit {
  showFrontFaceContent: boolean = false;
  showSideFaceContent: boolean = false;
  frontFaceTypes: any;
  sideFaceTypes: any;
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  private bottomSheetRef: MatBottomSheetRef,
  private appConfig: AppConfig,
  private changeDetection: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.frontFaceTypes = [this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE];
    this.sideFaceTypes = [this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE,
      this.appConfig.Shared.InstantCheckup.Type.LEFT_SIDE_FACE, this.appConfig.Shared.InstantCheckup.Type.RIGHT_SIDE_FACE];
    if (this.frontFaceTypes.includes(this.data.photoTypes.expectedPhoto)) {
      this.showFrontFaceContent = true;
    } else {
      this.showSideFaceContent = true;
    }
    this.changeDetection.detectChanges();
  }

  closePopup(): void {
    this.bottomSheetRef.dismiss();
  }

  handleRetakePhoto(): void {
    if (this.data?.retakePhotoFromBottomSheet) this.data.retakePhotoFromBottomSheet();
    this.bottomSheetRef.dismiss();
  }
}

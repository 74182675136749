<div class="tw-grid tw-grid-cols-1 tw-grid-rows-[auto_auto_1fr_auto] tw-pt-4 tw-pb-8 tw-bg-gray-80 tw-rounded-t-6">
  <div class="tw-flex tw-justify-end tw-items-center tw-px-6">
    <div id="close-btn" class="tw-flex tw-justify-center tw-items-center tw-shrink-0 tw-w-8 tw-h-8 tw-bg-transparent tw-rounded-full"
      (click)="closeBottomSheet()">
      <span class="tw-text-400 tw-text-gray-800 tw-font-bold">&#10005;</span>
    </div>
  </div>
  <div class="tw-mt-1 tw-px-6">
    <span class="tw-font-body tw-text-700 tw-text-gray-800 tw-font-bold tw-tracking-normal"
      i18n="@@chooseYourPreferredLanguage">Choose your
      preferred language</span>
  </div>
  <div
    class="tw-grid tw-grid-cols-2 tw-gap-2 tw-max-h-[240px] tw-w-full tw-mt-4 tw-mb-5 tw-px-6 tw-pt-2 tw-pb-2 tw-overflow-x-hidden tw-overflow-y-auto">
    <div *ngFor="let language of languages; let i = index" (click)="handleLanguageChange(language)"
      class="tw-flex tw-items-center tw-p-4 tw-bg-gray-75 tw-rounded-[10px] tw-shadow-[0_2px_4px_0px_rgba(0,0,0,0.10)]">
      <div class="tw-flex tw-items-center tw-gap-4">
        <div *ngIf="language.code !== selectedLanguage.code"
          class="tw-w-[18px] tw-h-[18px] tw-m-1 tw-bg-gray-50 tw-border-2 tw-border-solid tw-border-gray-400 tw-rounded-full tw-shrink-0">
        </div>
        <div *ngIf="language.code === selectedLanguage.code"
          class="tw-relative tw-w-[18px] tw-h-[18px] tw-m-1 tw-border-2 tw-border-solid tw-border-gray-400 tw-rounded-full tw-shrink-0 after:tw-content-[''] after:tw-absolute after:tw-w-2 after:tw-h-2 after:tw-bg-green-400 after:tw-rounded-full after:tw-top-1/2 after:tw-left-1/2 after:tw-translate-x-[-50%] after:tw-translate-y-[-50%]">
        </div>
        <span class="tw-font-body tw-text-400 tw-text-gray-800 tw-font-normal">{{language.displayName}}</span>
      </div>
    </div>
  </div>
  <div class="tw-flex tw-justify-center tw-items-center tw-px-6">
    <cs-button class="tw-w-full" [class]="'w100 primary btn btn-padding tw-px-6'" [disabled]="saveInProcess"
      [loading]="saveInProcess" (click)="handleConfirmClick()">
      <cs-button-text>
        <span class="tw-font-body tw-tracking-normal" i18n="@@confirm">Confirm</span>
      </cs-button-text>
    </cs-button>
  </div>
</div>
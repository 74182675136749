import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CsIconModule } from '@components/icon';
import { ButtonModule } from '@components/button';
import { TranslateStringsModule } from '@components/translate-string';
import { CancelOrderComponent } from './cancel-order.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CsIconModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateStringsModule,
  ],
  declarations: [CancelOrderComponent],
  exports: [CancelOrderComponent],
})

export class CancelOrderModule { }

<div
  class="tw-p-4 tw-rounded-md tw-shadow-lg tw-w-full tw-max-w-md tw-flex tw-flex-col tw-h-[70vh] sm:tw-h-screen tw-overflow-hidden"
>
  <h2 class="tw-text-100 tw-font-body tw-font-bold tw-text-gray-800 tw-border-b tw-pb-2 tw-text-lg sm:tw-text-xl" i18n="@@selectOrder">
    SELECT ORDER
  </h2>
  <div
    class="tw-overflow-y-auto tw-overflow-x-hidden tw-flex-1 tw-mb-[-40px] tw-px-2 tw-pt-2"
  >
    <div
      *ngFor="let order of data?.content; let isLast = last; let isFirst = first"
      class="tw-border-b tw-py-3 tw-w-full"
      [ngClass]="{ 'tw-border-b': !isLast, 'tw-pt-4': isFirst }"
    >
      <radio-btn
        [checked]="selectedOrder === order.orderId"
        (checkedChange)="selectedOrder = order.orderId"
      >
        <div class="tw-flex tw-justify-between tw-items-center">
          <span
            class="tw-text-200 tw-font-bold tw-font-body"
            [ngClass]="{ 'selected': selectedOrder === order.orderId }"
          >
            {{ order.createdAt | date: 'dd/MM/yy' }}
          </span>
          <span
            class="tw-text-200 tw-capitalize tw-font-body"
            [ngClass]="{
              'tw-text-green-400': order.stage === 'Delivered',
              'tw-text-yellow-400': order.stage === 'In Transit',
              'tw-text-gray-400': order.stage === 'Canceled'
            }"
          >
            {{ order.stage.toUpperCase() === appConfig.Shared.Order.Stage.CANCELED ? 'Cancelled' : order.stage }}
            <span
              class="tw-ml-1 tw-text-lg tw-inline-block tw-relative tw-top-[3px]"
            >
              <img
                *ngIf="order.stage === 'In Transit'"
                class="tw-h-4 tw-aspect-square tw-rounded-full"
                src="/assets/icons/schedule.svg"
                alt="transit order"
              />
              <img
                *ngIf="order.stage === 'Delivered'"
                class="tw-h-4 tw-aspect-square tw-rounded-full"
                src="/assets/icons/teal-tick-circle.svg"
                alt="delivered order"
              />
              <img
                *ngIf="order.stage === 'Canceled'"
                class="tw-h-4 tw-aspect-square tw-rounded-full"
                src="/assets/icons/cancel.svg"
                alt="cancel order"
              />
            </span>
          </span>
        </div>
        <p class="tw-font-body tw-text-200 tw-text-gray-500">
          {{ order.subText }}
        </p>
        <!-- Responsive Image Display with "+N more" -->
        <div
          class="tw-flex tw-gap-2 tw-mt-2 tw-flex-wrap"
          *ngIf="hasAnyImages(order)"
        >
          <ng-container
            *ngFor="let image of getImagesAndCount(order).visibleImages; let i = index"
          >
            <img
              [src]="image"
              alt="Product Img"
              class="tw-w-[42px] tw-h-12 tw-rounded-md tw-border"
            />
          </ng-container>
          <span
            *ngIf="getImagesAndCount(order).noImageCount > 0"
            class="tw-text-gray-500 tw-text-100 tw-text-center tw-pt-2 tw-rounded-[6px] tw-bg-gray-100 tw-h-[48px] tw-w-[42px] tw-font-body tw-self-center"
          >
            +{{ getImagesAndCount(order).noImageCount }}
            <span class="tw-block">more</span>
          </span>
        </div>
      </radio-btn>
    </div>
  </div>
  <!-- Footer Buttons -->
  <div
    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-pt-[42px] tw-pb-4 tw-sticky tw-bottom-0 tw-bg-white tw-z-10"
    style="
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #fff 36.5%,
        #fff 79.84%
      ) !important;
    "
  >
    <button class="tw-text-teal-600 tw-font-body tw-w-1/2 tw-justify-center tw-items-center tw-font-bold tw-text-sm sm:tw-text-base" (click)="closedPopup()" i18n="@@cancel">Cancel</button>
    <button
      [ngClass]="{'tw-bg-[#CECECE] tw-rounded-[12px] tw-pointer-events-none' : !selectedOrder?.length, 'tw-bg-teal-600 tw-text-300 tw-rounded-[12px]' : selectedOrder?.length}"
      class="tw-w-1/2 tw-font-body tw-justify-center tw-text-white tw-text-300 tw-py-2 tw-rounded-xl tw-h-[40px] tw-flex tw-justify-center tw-items-center tw-font-bold"
      (click)="openNativePopup()" i18n="@@continue">Continue</button>
  </div>
</div>

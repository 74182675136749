<div class="tw-flex tw-gap-2">
  <cs-icon class="tw-h-4 tw-w-4" [ngClass]="{'tw-text-yellow-400': isDayTime, 'tw-text-purple-400': !isDayTime}" [iconName]="isDayTime ? 'light_full' : 'night_full'"></cs-icon>
  <div class="tw-font-body tw-text-200 tw-mb-[8px]" [ngClass]="{'tw-text-gray-900': isDayTime, 'tw-text-white': !isDayTime, 'tw-line-through': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}">
    <span *ngIf="isDayTime" class="tw-font-body tw-font-bold" i18n="@@morning"> Morning </span>
    <span *ngIf="!isDayTime" class="tw-font-bold tw-capitalize" i18n="@@night"> Night </span>
    <span class="tw-font-bold tw-capitalize" i18n="@@step">step {{ index + 1 }} &nbsp;</span>
    <span class="tw-font-bold">-&nbsp;</span>
    <span>{{ item.product.purpose ?? item.removedProduct.purpose ?? '' }}</span>
  </div>
</div>
<!-- Container wrapper (only renders if item is added, delivered recently or instruction is changed) -->
<ng-container *ngIf="(isInstructionChanged(item?.product?.objectId) || item?.addedTimestamp) && !item?.onHold">
  <div class="tw-p-1 tw-bg-orange-100 tw-mb-4 tw-rounded-2 tw-relative">
    <div class="tw-rounded-t-md tw-overflow-hidden">
      <ng-container *ngTemplateOutlet="whiteCard"></ng-container>
    </div>
<!--    container footer-->
    <div class="tw-h-12 tw-bg-orange-100 instruction-card-container-bottom tw-flex tw-justify-between tw-items-center tw-p-4 tw-rounded-1">
      <ng-container *ngIf="isInstructionChanged(item?.product?.objectId)">
        <p class="tw-text-200 tw-font-body tw-flex tw-gap-0.5" i18n="@@instructionChangedByDoctor"><span class="tw-text-orange-400 tw-font-bold">Note:</span><span class="">Instruction changed by doctor</span></p>
      </ng-container>
      <ng-container *ngIf="!isInstructionChanged(item?.product?.objectId)">
        <p class="tw-flex tw-gap-1.5 tw-items-center">
          <cs-icon class="tw-h-4 tw-w-4 tw-text-bold tw-text-orange-400" [iconName]='"orange_tick_new"'></cs-icon>
          <span class="tw-font-body tw-text-orange-400 tw-text-200 tw-whitespace-nowrap" i18n="@@drAdded">
            Dr. Added
          </span>
        </p>
        <p class="tw-text-teal-600 tw-font-bold tw-text-300 tw-font-body" i18n="@@knowMore" (click)="onKnowMoreClicked(getKnowMoreClickType())">Know More</p>
      </ng-container>
    </div>
<!--    tags-->
    <div class="tw-absolute tw-top-3 tw-right-[-5px] tw-h-5">
      <div class="tw-relative tw-h-full">
        <div class="tw-px-2 tw-font-body tw-h-4 tw-text-100 tw-font-bold tw-text-white tw-flex tw-items-center"
             [ngClass]="{'tw-bg-orange-400': !isProductDelivered(item?.product?.objectId), 'tw-bg-teal-500': isProductDelivered(item?.product?.objectId)}">
          <span *ngIf="!isProductDelivered(item?.product?.objectId)" i18n="@@notYetStarted" class="tw-uppercase">Not yet started</span>
          <span *ngIf="isProductDelivered(item?.product?.objectId)" i18n="@@new" class="tw-uppercase"> NEW </span>
        </div>
        <img [src]="'/assets/images/' + (isProductDelivered(item?.product?.objectId) ? 'green' : 'orange') + 'tag.svg'"
             [alt]="'tag' + (isProductDelivered(item?.product?.objectId) ? 'Green' : 'Orange')"
             class="tw-absolute tw-right-0 tw-top-0 bot"/>
      </div>
    </div>
  </div>
</ng-container>

<!-- Regular white card -->
<ng-container *ngIf="!isInstructionChanged(item?.product?.objectId) && !item?.addedTimestamp && !item?.onHold && !isRemovedProductsInstruction(item.product.id)">
  <div class="tw-mb-4 tw-border tw-border-gray-200 tw-rounded-[8px] tw-overflow-hidden">
   <ng-container *ngTemplateOutlet="whiteCard"></ng-container>
  </div>
</ng-container>

<!--on Hold card-->
<ng-container *ngIf="!isRemovedProductsInstruction(item.product.id) && item?.onHold">
  <div (click)="onToggleProductCollapse(item.product.objectId)" class="tw-border-gray-200">
    <div *ngIf="isOnHoldCardOpen(item.product.objectId)" class="tw-mb-4 tw-border tw-border-gray-200 tw-rounded-[8px] tw-overflow-hidden">
      <ng-container *ngTemplateOutlet="whiteCard"></ng-container>
    </div>
    <div *ngIf="!isOnHoldCardOpen(item.product.objectId)">
      <ng-container *ngTemplateOutlet="collapsedOnHoldCard"></ng-container>
    </div>
  </div>
</ng-container>

<!--removed Card-->
<ng-container *ngIf="isRemovedProductsInstruction(item.product.id)">
  <div (click)="onToggleProductCollapse(item.product.id)" class="tw-border-gray-200">
    <div *ngIf="isRemovedProductsInstructionOpen(item.product.id)" class="tw-mb-4 tw-border tw-border-gray-200 tw-rounded-[8px] tw-overflow-hidden">
      <ng-container *ngTemplateOutlet="whiteRemovedCard"></ng-container>
    </div>
    <div *ngIf="!isRemovedProductsInstructionOpen(item.product.id)">
      <ng-container *ngTemplateOutlet="collapsedRemovedCard"></ng-container>
    </div>
  </div>
</ng-container>

<!-- Template for white card content - defined once, used multiple times -->
<ng-template #whiteCard>
    <div class="tw-relative tw-bg-white tw-h-[194px]" [ngClass]="{'tw-bg-white': !item?.onHold, 'tw-bg-gray-75 tw-opacity-80': item?.onHold,}">
      <div class="tw-flex tw-h-[194px]">
        <div class="tw-flex-none tw-w-[85.7px] tw-h-[194px] tw-object-contain tw-object-center">
          <img class="tw-w-full tw-h-[194px] tw-object-contain tw-object-center tw-p-4"
               [src]="
          (item.product?.rebrandedImageWithoutBackground?.length ?
          item.product.rebrandedImageWithoutBackground[item.product.rebrandedImageWithoutBackground.length - 1] :
          null)
          ||
          (item?.product?.productUnboxedImage?.length ?
          item.product.productUnboxedImage[item.product.productUnboxedImage.length - 1] :
          null)
        "
               *ngIf="item.product">
        </div>
        <div class="tw-flex tw-flex-col tw-justify-between tw-pl-[12px] tw- tw-pr-3 tw-pt-[21px] tw-pb-4 tw-w-full tw-h-[193px] tw-border-l tw-border-gray-200">
          <!--      card title , list of instructions and image container-->
          <div>
            <img class="tw-h-[86px] tw-aspect-square tw-float-right" *ngIf="item.instructionSet.quantityImage?.length" [src]="item.instructionSet.quantityImage[0]" />
            <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-[9px] tw-w-28">
              {{item.product.title}}
            </h2>
            <p class="tw-font-body tw-text-100 tw-text-gray-500" *ngIf="item.instructionSet?.frequencyOfApplication?.includes(',')" i18n="@@alternateDays"> (Alternate Days) </p>
            <ul class="tw-text-gray-900 tw-list-none">
              <ng-container *ngIf="changeInstructionForRepairProduct && item.product?.reorderType === appConfig.Shared.Product.ReOrderType.LIMITED">
                <ng-container *ngIf="dayAfterDelivery === -1">
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1">Instructions will be visible after product delivery.</span>
                  </li>
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2">Please start using this 2 days after starting other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 0">
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1-D0">Instructions will be visible after 2 days.</span>
                  </li>
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
                <ng-container *ngIf="dayAfterDelivery === 1">
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint1-D1">Instructions will be visible tomorrow.</span>
                  </li>
                  <li>
                    <span class="tw-text-gray-900 tw-font-body tw-text-200" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                  </li>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!changeInstructionForRepairProduct || (item.product?.reorderType !== appConfig.Shared.Product.ReOrderType.LIMITED)">
              <div class="tw-pl-[7px] tw-space-y-[2px]">
                <li *ngIf="item?.instructionSet && item.instructionSet?.frequencyOfApplication?.length && !item?.onHold" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-2 tw-capitalize">
                  <span class="tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[5px]"></span>
                  <span class="tw-break-all tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.frequencyOfApplication}}</span>
                </li>
                <li *ngIf="item?.instructionSet && item.instructionSet.quantityUnit?.length" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-2">
                  <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
                  <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet.quantity}}
                    {{item.instructionSet?.quantityUnit}}</span>
                </li>
                <li *ngIf="item.instructionSet?.areaOfApplication" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-2">
                  <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
                  <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.areaOfApplication}}</span>
                </li>
                <li *ngIf="item.instructionSet?.durationOfApplication" class="tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-2">
                  <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
                  <span class="tw-font-body tw-text-100 tw-text-gray-900 tw-leading-[14px]">{{item.instructionSet?.durationOfApplication}}</span>
                </li>
              </div>
              </ng-container>
            </ul>
          </div>
          <!--      play video container-->
          <div *ngIf="!item.onHold">
            <ng-container *ngIf="user?.isPaid() && item.instructionSet?.paidInstructionVideo && (item.instructionSet?.paidInstructionVideo[userLanguage] || item.instructionSet?.paidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-teal-600 tw-capitalize tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick()"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/play-icon-teal.svg" (click)="onPlayButtonClick()" />
              </div>
            </ng-container>
            <ng-container *ngIf="!user?.isPaid() && item.instructionSet?.unpaidInstructionVideo && (item.instructionSet?.unpaidInstructionVideo[userLanguage] || item.instructionSet?.unpaidInstructionVideo.en)">
              <div class="tw-flex tw-items-center tw-mt-1">
                <div class="tw-font-body tw-text-300 tw-text-teal-600 tw-capitalize tw-font-bold" i18n="@@playVideo" (click)="onPlayButtonClick()"> Play video </div>
                <img class="tw-ml-2" src="/assets/icons/play-icon-teal.svg" (click)="onPlayButtonClick()" />
              </div>
            </ng-container>
          </div>
          <div *ngIf="item?.onHold">
            <ng-container *ngTemplateOutlet="onHold"></ng-container>
          </div>
        </div>
      </div>
      <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-center" id="overlay" [ngClass]="{'tw-bg-[#ffffff99] tw-flex': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}" [ngStyle]="{ 'display': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal]) ? 'block' : 'none' }">
        <div class="tw-bg-orange-100 tw-h-14 tw-flex-1 tw-backdrop-blur-sm tw-absolute tw-w-full tw-m-auto tw-text-center tw-p-3 tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])">
          <p class="tw-text-200 tw-text-orange-400 tw-font-bold" i18n="@@notToUseToday"> Not to be used today </p>
          <p class="tw-text-200 tw-text-gray-500" i18n="@@checkTomorrow"> Please check back tomorrow. </p>
        </div>
      </div>
    </div>
  </ng-template>
<!--template for when onHold card is collapsed-->
<ng-template #collapsedOnHoldCard>
  <div class="tw-relative tw-border tw-border-gray-200 tw-h-16 tw-flex tw-rounded-md tw-mb-4 tw-opacity-80" [ngClass]="{'tw-bg-white': !item?.onHold, 'tw-bg-gray-75': item?.onHold,}">
      <div class="tw-flex-none tw-w-[89px] tw-object-contain tw-object-center tw-flex tw-justify-center tw-items-center">
        <img class="tw-w-full tw-h-12 tw-object-contain tw-object-center"
             [src]="
          (item.product?.rebrandedImageWithoutBackground?.length ?
          item.product.rebrandedImageWithoutBackground[item.product.rebrandedImageWithoutBackground.length - 1] :
          null)
          ||
          (item?.product?.productUnboxedImage?.length ?
          item.product.productUnboxedImage[item.product.productUnboxedImage.length - 1] :
          null)
        "
             *ngIf="item.product">
      </div>
<div class="tw-border-l tw-border-gray-200 tw-pl-3 tw-flex tw-flex-col tw-justify-center tw-w-full">
  <h1 class="tw-text-300 tw-font-bold tw-font-body">
    {{item.product.title}}
  </h1>
  <div *ngIf="item?.onHold" class="tw-mt-0.5 tw-opacity-80 tw-flex tw-justify-between tw-items-center tw-w-full">
    <ng-container *ngTemplateOutlet="onHold"></ng-container>
    <cs-icon class="tw-justify-center tw-h-6 tw-mr-[9px]" [iconName]="'chevron_gray_down'"></cs-icon>
  </div>
</div>
  </div>
</ng-template>

<!--template for when removed card is collapsed-->
<ng-template #collapsedRemovedCard>
  <div class="tw-relative tw-h-16 tw-flex tw-rounded-md tw-mb-4 tw-bg-opacity-70" [ngClass]="{'tw-bg-white': !item?.onHold, 'tw-bg-gray-75': item?.onHold,}">
    <div class="tw-flex-none tw-w-[89px] tw-object-contain tw-object-center tw-flex tw-justify-center tw-items-center">
      <img class="tw-w-full tw-h-12 tw-object-contain tw-object-center tw-grayscale tw-opacity-30"
           [src]="
          (item.removedProduct?.rebrandedImageWithoutBackground?.length ?
          item.removedProduct.rebrandedImageWithoutBackground[item.removedProduct.rebrandedImageWithoutBackground.length - 1] :
          null)
          ||
          (item?.removedProduct?.productUnboxedImage?.length ?
          item.removedProduct.productUnboxedImage[item.removedProduct.productUnboxedImage.length - 1] :
          null)
        "
           *ngIf="item.removedProduct">
    </div>
    <div class="tw-border-l tw-border-gray-200 tw-pl-3 tw-pr-4 tw-flex tw-flex-col tw-justify-center tw-w-full">
      <h1 class="tw-text-300 tw-font-bold tw-font-body tw-opacity-30">
        {{item.removedProduct.title}}
      </h1>
      <div class="tw-mt-0.5 tw-opacity-80 tw-flex tw-justify-between tw-items-center tw-w-full">
        <ng-container *ngTemplateOutlet="removed"></ng-container>
        <cs-icon class="tw-justify-center tw-h-6 tw-mr-[-6px]" [iconName]="'chevron_gray_down'"></cs-icon>
      </div>
    </div>
  </div>
</ng-template>
<!-- Template for white removed card -->
<ng-template #whiteRemovedCard>
  <div class="tw-relative tw-h-[194px] tw-bg-gray-75 tw-bg-opacity-70">
    <div class="tw-flex tw-h-[194px]">
      <div class="tw-flex-none tw-w-[85.7px] tw-h-[194px] tw-object-contain tw-object-center">
        <img class="tw-w-full tw-h-[194px] tw-object-contain tw-object-center tw-p-4 tw-grayscale tw-opacity-30"
             [src]="
          (item.removedProduct?.rebrandedImageWithoutBackground?.length ?
          item.removedProduct.rebrandedImageWithoutBackground[item.removedProduct.rebrandedImageWithoutBackground.length - 1] :
          null)
          ||
          (item?.removedProduct?.productUnboxedImage?.length ?
          item.removedProduct.productUnboxedImage[item.removedProduct.productUnboxedImage.length - 1] :
          null)
        "
             *ngIf="item.removedProduct">
      </div>
      <div class="tw-flex tw-flex-col tw-justify-between tw-pl-[12px] tw-pr-3 tw-pt-[21px] tw-pb-4 tw-w-full tw-h-[193px] tw-border-l tw-border-gray-200">
        <!--      card title , list of instructions and image container-->
        <div>
          <img class="tw-h-[86px] tw-aspect-square tw-float-right tw-opacity-30" *ngIf="item.instructionSet.quantityImage?.length" [src]="item.instructionSet.quantityImage[0]" />
          <h2 class="tw-font-body tw-font-bold tw-text-300 tw-mb-[9px] tw-w-28 tw-opacity-30">
            {{item.removedProduct.title}}
          </h2>
          <p class="tw-font-body tw-text-100 tw-text-gray-500" *ngIf="item.instructionSet?.frequencyOfApplication?.includes(',')" i18n="@@alternateDays"> (Alternate Days) </p>
          <div class="tw-pl-[7px] tw-space-y-[2px]">
            <ul class="tw-text-gray-900 tw-opacity-30">
            <ng-container *ngIf="(item.removedProduct?.reorderType !== appConfig.Shared.Product.ReOrderType.LIMITED) || !changeInstructionForRepairProduct">
              <li *ngIf="item?.instructionSet && !item?.onHold" class="tw-capitalize tw-leading-[18px] tw-list-none tw-flex tw-items-start tw-gap-2">
                <span class="tw-bg-black tw-inline-block tw-w-[3px] tw-aspect-square tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
               <span class="tw-leading-[14px] tw-font-body tw-text-100 tw-text-gray-900 tw-break-all">{{item.instructionSet?.frequencyOfApplication}}</span>
              </li>
              <li *ngIf="item?.instructionSet && item.instructionSet.quantityUnit?.length" class="tw-list-none tw-flex tw-items-start tw-gap-2 tw-leading-[18px]">
                <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-translate-y-[6.5px] tw-rounded-full tw-flex-shrink-0"></span>
                <span class="tw-leading-[14px] tw-font-body tw-text-100 tw-text-gray-900">{{item.instructionSet.quantity}}
                  {{item.instructionSet?.quantityUnit}}</span>
              </li>
              <li *ngIf="item.instructionSet?.areaOfApplication" class="tw-leading-[18px] tw-flex tw-items-start tw-gap-2 tw-list-none">
                <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-flex-shrink-0 tw-rounded-full tw-translate-y-[6.5px]"></span>
                <span class="tw-font-body tw-text-100 tw-leading-[14px] tw-text-gray-900">{{item.instructionSet?.areaOfApplication}}</span>
              </li>
              <li *ngIf="item.instructionSet?.durationOfApplication" class="tw-leading-[18px] tw-flex tw-items-start tw-gap-2 tw-list-none">
                <span class="tw-inline-block tw-w-[3px] tw-aspect-square tw-bg-black tw-rounded-full tw-flex-shrink-0 tw-translate-y-[6.5px]"></span>
                <span class="tw-font-body tw-text-gray-900 tw-text-100 tw-leading-[14px]">{{item.instructionSet?.durationOfApplication}}</span>
              </li>
            </ng-container>
            <ng-container *ngIf="item.product?.reorderType === appConfig.Shared.Product.ReOrderType.LIMITED && changeInstructionForRepairProduct">
              <ng-container *ngIf="dayAfterDelivery === -1">
                <li>
                  <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1">Instructions will be visible after product delivery.</span>
                </li>
                <li>
                  <span class="tw-text-gray-900 tw-text-200 tw-font-body" i18n="@@repairProductHint2">Please start using this 2 days after starting other products.</span>
                </li>
              </ng-container>
              <ng-container *ngIf="dayAfterDelivery === 0">
                <li>
                  <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D0">Instructions will be visible after 2 days.</span>
                </li>
                <li>
                  <span class="tw-font-body tw-text-gray-900 tw-text-200" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                </li>
              </ng-container>
              <ng-container *ngIf="dayAfterDelivery === 1">
                <li>
                  <span class="tw-font-body tw-text-200 tw-text-gray-900" i18n="@@repairProductHint1-D1">Instructions will be visible tomorrow.</span>
                </li>
                <li>
                  <span class="tw-text-gray-900 tw-text-200 tw-font-body" i18n="@@repairProductHint2-D0">Please continue using other products.</span>
                </li>
              </ng-container>
            </ng-container>
          </ul>
          </div>
        </div>
        <div class="tw-opacity-80">
          <ng-container *ngTemplateOutlet="removed"></ng-container>
        </div>
      </div>
    </div>
    <div class="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-items-center" id="overlay" [ngClass]="{'tw-bg-[#ffffff99] tw-flex': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])}" [ngStyle]="{ 'display': item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal]) ? 'block' : 'none' }">
      <div class="tw-bg-orange-100 tw-flex-1 tw-backdrop-blur-sm tw-absolute tw-w-full tw-m-auto tw-text-center tw-p-3 tw-top-[50%] tw-left-[50%] tw-translate-x-[-50%] tw-translate-y-[-50%]" *ngIf="item?.instructionSet?.newFrequencyOfApplication && !item?.instructionSet?.newFrequencyOfApplication.includes(days[todayDayVal])">
        <p class="tw-text-200 tw-text-orange-400 tw-font-bold" i18n="@@notToUseToday"> Not to be used today </p>
        <p class="tw-text-200 tw-text-gray-500" i18n="@@checkTomorrow"> Please check back tomorrow. </p>
      </div>
    </div>
  </div>
</ng-template>


<!-- Template for on hold tag -->
<ng-template #onHold>
  <div class="tw-border tw-border-red-500 tw-text-red-500 tw-rounded-full tw-text-100 tw-font-bold tw-px-2 tw-font-body tw-w-min-16 tw-w-fit tw-h-4 tw-flex tw-justify-center tw-items-center">
    <span class="tw-text-nowrap" i18n="@@onHold">ON HOLD</span>
  </div>
</ng-template>

<!-- Template for on removed tag -->
<ng-template #removed>
  <div class="tw-border tw-border-black tw-text-black tw-rounded-full tw-text-100 tw-font-bold tw-px-2 tw-font-body tw-h-4 tw-flex tw-justify-center tw-items-center tw-w-min-16 tw-w-fit">
    <span class="tw-text-nowrap" i18n="@@removed">REMOVED</span>
  </div>
</ng-template>

<!-- Template for on removed tag -->
<ng-template #removed>
  <div class="tw-border tw-border-black tw-text-black tw-rounded-full tw-text-100 tw-font-bold tw-px-2 tw-font-body tw-w-16 tw-h-4 tw-flex tw-justify-center tw-items-center">
    <span class="tw-text-nowrap">REMOVED</span>
  </div>
</ng-template>

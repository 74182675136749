import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@components/button';
import { RegimenInstructionComponent } from '@shared/regimen-instruction/regimen-instruction.component';
import { VideoPlayerModule } from '@components/video-player/video-player.module';
import { LoadingModule } from '@components/loading';
import { CsIconModule } from '@components/icon';
import { InstructionCardComponent } from '@shared/regimen-instruction/instruction-card/instruction-card.component';

@NgModule({ imports: [CommonModule,
  CsIconModule,
  ButtonModule,
  VideoPlayerModule,
  LoadingModule, InstructionCardComponent,
],
declarations: [RegimenInstructionComponent],
exports: [RegimenInstructionComponent] })
export class RegimenInstructionModule {}

<div class="tw-bg-white font-noto tw-rounded-full">
  <div class="tw-flex tw-items-center tw-justify-end tw-px-2 tw-pt-4 tw-pb-0">
    <div class="tw-w-[35px] tw-h-[35px] tw-flex tw-justify-center tw-items-center tw-p-1 tw-rounded-full tw-bg-gray-100 tw-z-10" (click)="closePopup()">
      <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
    </div>
  </div>
  <div class="tw-pl-4 tw-pr-4 tw-pb-7">
    <h2 class="tw-max-w-[calc(100vw-64px)] tw-text-lg tw-font-semibold tw-text-700 tw-mb-2" i18n="@@unableToCancelOrder"> Unable to cancel order </h2>
    <p class="tw-my-2 tw-text-400 tw-text-gray-500" i18n="@@cannotCancelAtThisStage"> Your order has already been shipped and cannot be cancelled at this stage. </p>
  </div>
</div>
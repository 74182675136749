import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RequestCallBottomsheetComponent }
  from '@shared/bottom-sheet-layouts/pending-call-bottomsheet/request-call-bottomsheet/request-call-bottomsheet.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { EventLoggerService } from '@services/event-logger-service';

@Component({
  selector: 'app-request-call-banner',
  standalone: true,
  imports: [
  ],
  templateUrl: './request-call-banner.component.html',
})
export class RequestCallBannerComponent {
  @Input() experimentVariantData: any;
  @Input() regimen: any;
  @Output() pendingCallCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  appointmentTimeMessage: string;

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private readonly eventLogger: EventLoggerService,
  ) {}

  ngOnInit(): void {
    this.appointmentTimeMessage = this.getTimeBasedDisplayText();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'regimen_unpaid_user_call_banner_seen' }));
  }

  getMilitaryTime(): string {
    const now = new Date();
    let hours: any = now.getHours();
    let minutes: any = now.getMinutes();
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}${minutes}`;
  }

  getTimeBasedDisplayText(): string {
    const currentHour = this.getMilitaryTime();
    if (this.experimentVariantData?.timeBasedTAT?.length) {
      const matchingItem = this.experimentVariantData.timeBasedTAT.find(
        (data: any): any => currentHour >= data.startHour && currentHour <= data.endHour,
      );
      return matchingItem?.displayText || 'within one day';
    }
    return 'within one day';
  }

  bannerClick(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'regimen_unpaid_user_call_request_banner' }));
    const bottomSheet = this.bottomSheet.open(RequestCallBottomsheetComponent, {
      data: {
        currentDisplayText: this.appointmentTimeMessage,
        regimen: this.regimen,
      },
      disableClose: true,
    });
    bottomSheet.backdropClick().subscribe((): void => {
      bottomSheet.dismiss();
    });
    bottomSheet.afterDismissed().subscribe((result: any): void => {
      if (result && result.pendingCallCreated) {
        this.pendingCallCreated.emit(true);
      }
    });
  }
}

<div class="hbox bg-primary">
  <div class="flex-box trans-x-hide slideFromBottom">
    <div class="flex-heading text-center">
      <img class="anim-fadeIn" src="/assets/images/cancel.svg" />
      <div class="clr-white fontL4 mT20 fw-500" i18n="@@PaymentFailure">
        Payment failure
      </div>
      <div class="clr-white mT15" i18n="@@moneyNotCharged">
        You were not charged for this transaction.
      </div>
    </div>
    <div class="flex-content">
      <div class="mB30" i18n="@@paymentFailureDetail">
        Something went wrong with the online payment mode.
      </div>
      <div class="fontL1" i18n="@@weRecommendCOD">
        <span>We recommend to continue with your order for Rs {{(payment$ | async)?.get('order')?.get('amount')}} using</span><span class="pLR5 fw-500">Cash On Delivery (COD) option.</span>
      </div>
    </div>
    <div class="flex-footer">
      <div class="w100" *ngIf='!user?.get("disableCOD")'>
        <cs-button [class]='"primary w100 shine"' (callback)="placeCodOrder()" [loading]="(orderInProcess$ | async)"><cs-button-text i18n="@@continueWithCOD">Continue with COD</cs-button-text></cs-button>
      </div>
      <div class="w100 mT15">
        <cs-button [class]='"secondary w100"' (callback)="tryAgain()"><cs-button-text i18n="@@tryAgain">Try Again</cs-button-text></cs-button>
      </div>
    </div>
  </div>
</div>
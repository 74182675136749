/* eslint-disable arrow-body-style */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConnectionService } from '@services/connection-service';
import { BroadcastService } from '@services/broadcast-service';
import { LocalStorageService } from '@services/local-storage-service';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../../app.config';

@Component({
  selector: 'app-checkup-list',
  templateUrl: './checkup-list.component.html',
  styles: [':host {@apply tw-block tw-h-full; }'],
})
export class CheckupListComponent implements OnInit {
  months: any[];
  ui: any = {};
  user: any;
  instantCheckups: Array<any> = [];
  loading: boolean = false;

  constructor(
    private readonly conn: ConnectionService,
    private readonly router: Router,
    private readonly appConfig: AppConfig,
    private readonly broadcast: BroadcastService,
    private readonly event: EventLoggerService,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
  ) {
  }

  async ngOnInit(): Promise<any> {
    this.user = this.conn.getCurrentUser();

    this.event.trackEvent('previous_instant_checkup_opened', { username: this.user?.get('username') });
    this.months = this.appConfig.Shared.Months;
    this.loading = true;

    const type = this.route.snapshot.queryParams.username
      ? this.appConfig.Shared.InstantCheckup.Type
      : Object.keys(this.appConfig.Shared.InstantCheckup.Type).filter((type_: string): boolean => {
        return ![this.appConfig.Shared.InstantCheckup.Type.INVALID,
          this.appConfig.Shared.InstantCheckup.Type.REPORT,
          this.appConfig.Shared.InstantCheckup.Type.OTHER].includes(type_);
      });

    this.instantCheckups = await this.conn.fetchInstantCheckup({
      userId: this.user?.get('username'),
      project: ['type', 'imagePath'],
      type,
    });
    this.loading = false;
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  menuAction(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  routeTo(id: any, type: string): void {
    this.localStorageService?.setValue('checkupListExpectedFacePhoto', type);
    this.router.navigate([`/user/instantCheckup/${id}`], { queryParams: { fromList: true } });
  }
}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConnectionService } from '@services/connection-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-aov-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-aov-banner.html',
})
export class AovBannerComponent {
  @Input() amountRemaining: number = 0;
  @Input() freeProductBannerImage: string = '';
  @Input() percentageDone: number = 0;
  @Input() isEnglishLanguage: boolean = true;
  @Input() displayWhenAmountRemainingIsZero: boolean = false;

  constructor(
    private readonly conn: ConnectionService,
    private readonly router: Router,
  ) {}

  aovBannerClickHandler(): void {
    if (!this.router.url.includes('/cart')) {
      this.conn.navigateToURL('/cart');
    }
  }
}

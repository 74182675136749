<div class="tw-border tw-border-[#DCE8E5] tw-p-4 tw-rounded-3 tw-bg-gradient-light-teal tw-font-body tw-shadow-z1">
  <h1 class="tw-text-600 tw-font-bold tw-text-teal-600 tw-my-2 tw-leading-6"><span class="tw-capitalize">{{ username }}</span>, your call is completed</h1>
  <p class="tw-font-body tw-leading-5 tw-text-300 tw-text-gray-500 tw-mb-4">
    For any other query reach out to help
    & support. We are with you at each step of your healthy skin journey!
  </p>
    <div class="tw-w-full tw-h-[1px] tw-bg-[#CECECE] tw-mb-3"></div>
    <div class="tw-w-full tw-flex tw-justify-between tw-items-center tw-mb-1">
      <p class="tw-text-300">Customer support at +91 81-500-96500</p>
    </div>
</div>

/* eslint-disable prefer-template */
import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { Subscription } from 'rxjs/index';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { SentryErrorHandler } from '@services/sentry-logger';
import { CurrentComponentService } from '@services/current-component';
import { WindowRefService } from '@services/window-ref-service';
import { ChatProgressService } from '@services/chat-progress-service';
import { TimeService } from '@services/time-service';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { DOCUMENT } from '@angular/common';
import { InputType, SingleSelectorService } from '@shared/single-selector/single-selector.service';
import { ApiClientConstant, Table } from '@cureskin/api-client';
import { FilePickerComponent } from '@components/file-picker/file-picker.component';
import { CommonUtilityHelper } from '@services/common-utility-helper/common-utility-helper';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetComponent } from
  '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission-sheet.component';
import { LocalStorageService } from '@services/local-storage-service';
import { LanguageChangeComponent } from '@shared/bottom-sheet-layouts/language-change/language-change.component';
import { GetFeedbackSheetComponent } from '@shared/bottom-sheet-layouts/get-feedback-sheet/get-feedback-sheet.component';
import { ConsultationSessionService } from '@services/consultation-session-service';
import { ProgressConfig, ProgressConfigType } from './helper/progress-config';
import { AppConfig } from '../app.config';

@Component({
  selector: 'chatV2-view',
  templateUrl: './chatV2-view.html',
})
export class V2ChatComponent {
  isTabActive: boolean;
  @ViewChild('messageContainer', { static: false }) messageContainer: ElementRef;
  @ViewChild('unreadMessageDivider', { static: false }) unreadMessageDivider: ElementRef;
  @ViewChild(FilePickerComponent, { static: false }) filePickerComponent: FilePickerComponent;
  chats: Array<any> = [];
  startTimeOfBotReply: any;
  @Input('hideNavBar') hideNavBar: boolean;
  @Input('disableChat') disableChat: boolean;
  user: any;
  currentUser: Table.User;
  imageModal: any = {};
  isNotificationPermissionEnabled: any;
  @Input('where') where: any;
  @Input('dataFunctionName') dataFunctionName: string = 'getAssistantMessage';
  @Input('title') title: string;
  selectedChatType: any;
  @Input('chatScrollErrorY') chatScrollErrorY: number = 0;
  @Input('chatUniqueObject') set setChatUniqueObject(chatUniqueObject: any) {
    if (this.selectedChatType && chatUniqueObject && this.selectedChatType.id !== chatUniqueObject.id) {
      this.selectedChatType = chatUniqueObject;
      this.window.nativeWindow.location.reload();
    } else {
      this.selectedChatType = chatUniqueObject;
    }
  }
  @Output('chatScrollPossible') chatScrollPossible: EventEmitter<void> = new EventEmitter();
  @Output('chatLoadingStatus') chatLoadingStatus: EventEmitter<boolean> = new EventEmitter();
  @Output('botReplyInProcessChange') botReplyInProcessChange: EventEmitter<boolean> = new EventEmitter();
  isInternalUser: boolean;
  chatPayload: { limit?: number, loading: { old: boolean, new: boolean }, noMoreData?: boolean, input?: string } = {
    limit: 100,
    loading: {
      old: false,
      new: false,
    },
    noMoreData: false,
    input: undefined,
  };
  ui: {
    enableScrollToTop?: boolean;
    enableScrollToBottom: boolean;
    botReplyInProcess: boolean,
    userResponseSaveInProcess: boolean,
    botReplyTimeSeries: any,
    chatScrollTop?: number,
    chatInputEnable?: boolean;
  };
  waitForBotReplyTimer: any;
  responseModes: any[];
  subscriptions: Array<Subscription> = [];
  allocatedDoctor: any;
  interval: any;
  timer: any = {};
  experiments: Array<any> = [];
  allowAnimation: boolean = false;
  latestMessage: any;
  isHelpfull: boolean;
  @ViewChild('assistantPage', { static: false }) assistantPage: ElementRef;
  months: Array<any> = [];
  lastVisitedTime: Date;
  multiImageData: string[] = [];
  scrollThrottle: any;
  showCallPopup: boolean;
  afterSaveFeedback: boolean;
  dataLoadedTwice: boolean;
  startTimer:number;
  chatLanguageChangeEnabled: boolean = false;
  callBackExperimentEnable: boolean = false;
  newHelpTreeExperiment: boolean = false;
  newSupportQuestionId: string;
  disableChatInput: boolean = false;
  photoType: string;
  progressConfig: ProgressConfigType = ProgressConfig;
  progress: number = 0;
  processedQuestions: Set<any> = new Set();
  chatProgressExperiment: boolean = false;
  isTicketClosedBeforeTwoDays: boolean = false;
  inTransitUser: boolean = false;

  constructor(
    private conn: ConnectionService,
    private router: Router,
    private broadcast: BroadcastService,
    public appConfig: AppConfig,
    private route: ActivatedRoute,
    private appBridge: AppWebBridgeService,
    private zone: NgZone,
    private window: WindowRefService,
    private eventLogger: EventLoggerService,
    private timeService: TimeService,
    private currentComponent: CurrentComponentService,
    private sentry: SentryErrorHandler,
    private broadcastService: BroadcastService,
    private singleSelectorService: SingleSelectorService,
    private chatProgressService: ChatProgressService,
    private commonUtilityHelper: CommonUtilityHelper,
    private bottomSheet: MatBottomSheet,
    private localStorageService: LocalStorageService,
    private readonly consultationSessionService: ConsultationSessionService,
    // eslint-disable-next-line new-cap
    @Inject(DOCUMENT) private document: Document) {
    this.currentComponent.set(this);
  }

  async ngOnInit(): Promise<any> {
    this.chatLoadingStatus.emit(true);
    this.startTimer = new Date().getTime();
    this.currentComponent.set(this);
    this.isInternalUser = this.conn.isInternalUser();
    this.currentUser = this.conn.getCurrentUser();
    this.user = this.conn.getActingUser();
    await this.fetchActiveExperiments();
    this.months = this.appConfig.Shared.Months.map((each: any): void => each.name);
    this.subscriptions.push(this.broadcast.on<string>('NEW_MESSAGE_RECEIVED')
      .subscribe((): void => this.zone.run((): void => {
        if (!this.chatPayload.loading.old) this.loadNewChat();
      })));
    await this.initialize();
    if (!this.user) {
      this.user = await this.conn.getActingUser();
      this.selectedChatType = await this.conn.findSupportTicketById(this.route.snapshot.params.id, { translate: true });
      this.where = { supportTicket: this.selectedChatType };
    }
    this.inTransitUser = this.user.get('orderState') === this.appConfig.Shared.User.OrderState.PROCESSING;
    this.lastVisitedTime = this.selectedChatType.get('lastVisited');
    await this.loadOldChat();
    this.messageContainer.nativeElement.addEventListener('scroll', (event: any): void => this.scrollListener(event));
    /**
     * we log the last visited time of ticket in both ngOnInit and ngOnDestroy.
     * 1. In some case if ngOnDestroy doesn't update it (if ppl directly close app), we can atleast use ngOnInit time.
     * 2. ngOnDestroy takes delay to log it, meanwhile if user go back to home screen of support,
     *    there we need lastVisited time to determine new messages logic. So we can atleast use ngOnInit time for that moment.
     */
    if (!this.isInternalUser && this.selectedChatType?.className === 'SupportTicket') {
      this.conn.updateUserLastActiveDetails(this.selectedChatType.id);
    }
    if (this.hideNavBar) {
      // send with a delay of 1000 milliseconds so that page end event finish its notification.
      setTimeout((): void => {
        this.appBridge.notifyAssistantLoaded('consultationSession');
      }, 1000);
    }
    const time = new Date().getTime() - this.startTimer;
    this.checkTicketClosedDays();
    this.eventLogger.trackEvent('chat_screen_time', { timeInMillSec: time });
  }

  checkTicketClosedDays(): any {
    this.isTicketClosedBeforeTwoDays = this.timeService.differenceInDays(new Date(), this.selectedChatType?.get('completedAt')) > 2;
  }

  async fetchActiveExperiments(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((exp: any): void => {
      if (exp.key === 'chat_progress_bar' && this.appBridge?.requestOSInformation() !== 'iOS') {
        this.chatProgressExperiment = true;
      }
      if (exp.key === 'call_back_feature') {
        this.callBackExperimentEnable = true;
      }
      if (exp.key === 'new_help_tree') {
        this.newSupportQuestionId = exp.variant.questionId;
        this.newHelpTreeExperiment = true;
      }
      if (exp.key === 'chat_language_change') {
        this.chatLanguageChangeEnabled = true;
      }
    });
  }

  initialize(): void {
    this.ui = {
      chatInputEnable: false,
      botReplyInProcess: false,
      userResponseSaveInProcess: false,
      enableScrollToBottom: false,
      botReplyTimeSeries: { t1: 1000, t2: 1000 },
    };
    this.responseModes = this.appConfig.Shared.Assistant.ResponseModes;
  }

  addToChats(top: boolean, chats: Array<any>, fromAssistantReply?: boolean): void {
    if (!chats.length) return;
    if (top) {
      chats.reverse();
      this.recordScrollPosition();
    }
    chats.sort((item1: any, item2: any): number => {
      const t1 = item1.get('createdAt') ? item1.get('createdAt')
        .getTime() : new Date().getTime();
      const t2 = item2.get('createdAt') ? item2.get('createdAt')
        .getTime() : new Date().getTime();
      if (t1 === t2) {
        return 0;
      }
      return t1 > t2 ? 1 : -1;
    });
    this.loadChatProgress(chats);
    let prevImage;
    chats.forEach((chat_: any, index: number): void => {
      const chat = chat_;
      if (chat.get('showAfterTime') && !this.conn.isInternalUser()) { // if message has to be shown after certain time
        this.appBridge.logEventInBranchAndFirebaseFromiOS({ branch: { name: 'RegimenAllocated' }, firebase: { name: 'RegimenAllocated' } });
        const ageString = this.user?.get('Age');
        const isAdult = ageString !== undefined ? +ageString >= 18 : false;

        if (isAdult) {
          this.appBridge.logEventInBranchAndFirebaseFromiOS({
            branch: { name: 'RegimenAllocated18Plus' },
            firebase: { name: 'RegimenAllocated18Plus' },
          });
        }

        const timeLeft = (new Date(chat.get('showAfterTime')).getTime() - new Date().getTime());
        this.openActiveSessionRegimen();
        if (timeLeft > 0) setTimeout((): void => this.startRegimenPreparationTimer(timeLeft, chat), 0);
      }
      chat.displayDate = this.getDateFormatted(chat.get('createdAt'));
      if (this.isInternalUser) {
        if (chat.get('MessageInEnglish')) chat.set('Message', chat.get('MessageInEnglish'));
      }
      /**
       * If any chats are found to be created by (Owner != USER) after the lastVisitedTime of ticket,
       * Then we set 'unreadMessageStart' with length of chats below it (which length of unread messages).
       * Then we delete 'lastVisitedTime'. All needed data is set, so we delete it and let forEach continue.
       *
       */
      if (this.lastVisitedTime && chat.get('createdAt') > this.lastVisitedTime) {
        if (index > 0 && chats[index - 1].get('Owner') !== this.appConfig.Shared.Assistant.Owner.USER) {
          chat.set('unreadMessageStart', chats
            .slice(index, chats.length)
            .filter((each: any): boolean => ![this.appConfig.Shared.Assistant.Mode.SMS_COPY].includes(each.get('Mode')))
            .length);
        }
        delete this.lastVisitedTime;
      }

      if (chat.get('Mode') === this.appConfig.Shared.Assistant.Mode.REQUEST_IMAGE_RESPONSE) {
        if (prevImage) {
          chat.set('Mode', this.appConfig.Shared.Assistant.Mode.BLOCK);
        } else {
          prevImage = chat;
          prevImage.set('Mode', this.appConfig.Shared.Assistant.Mode.IMAGE_CLUBBED);
        }
        const imgArr = prevImage.get('imageArray') || [];
        imgArr.push(chat);
        prevImage.set('imageArray', imgArr);
        prevImage.displayDate = chat.displayDate;
      } else if (![this.appConfig.Shared.Assistant.Mode.BLOCK, this.appConfig.Shared.Assistant.Mode.UNBLOCK].includes(chat.get('Mode'))) {
        prevImage = undefined;
      }
    });

    this.consultationSessionService.setChats(chats);

    this.zone.run((): number => this.chats.push(...chats));
  }

  expandImages(chat: any): void {
    this.window.replaceHash('./#images', (): void => {
      this.multiImageData = [];
    });
    this.multiImageData = chat.get('imageArray');
  }

  openImageModal(chat:any): void {
    this.imageModal = {
      open: true,
      imageUrl: chat.get('Message'),
    };
  }

  closeImageModal(): void {
    this.imageModal = {
      open: false,
    };
  }

  handelDownload(): void {
    const imgSrc = this.imageModal.imageUrl; // Get the image source
    // Fetch the image as a blob
    fetch(imgSrc)
      .then((response:any): void => response.blob())
      .then((blob:any): void => {
        // Create a temporary anchor element
        const link = this.document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'image.jpeg'; // Set the desired file name
        // Simulate click on the link to trigger the download
        link.click();
        // Clean up - remove the Object URL after the download
        URL.revokeObjectURL(link.href);
      })
      .catch((error:any): void => {
        console.error('Error fetching the image:', error);
      });
  }

  handelShare(): void {
    this.broadcast.broadcast('SHARE', { type: 'IMAGE_ONLY', url: this.imageModal.imageUrl });
  }

  getDateFormatted(chatDate: any): string {
    const today = new Date();
    const date = chatDate ? new Date(chatDate) : new Date();
    let dateString = `${this.months[date.getMonth()]} ${date.getDate()}`;
    if (date.getDate() === today.getDate() && date.getMonth() === today.getMonth()) {
      dateString = 'Today';
    } else if (this.isInternalUser) dateString = `${dateString} ${date.getFullYear()}`;
    return dateString;
  }

  getLatestMessage(skipSMSCopy: boolean = false): any {
    let index = this.chats.length - 1;
    while (index >= 0) {
      if (skipSMSCopy && this.chats[index].get('Mode') === this.appConfig.Shared.Assistant.Mode.SMS_COPY) {
        index -= 1;
      } else {
        break;
      }
    }
    return this.chats[index];
  }

  async loadNewChat(): Promise<any> {
    if (!this.user || this.chatPayload.loading.new) return;
    this.chatPayload.loading.new = true;
    const where: any = { ...this.where };
    const latestMessage = this.getLatestMessage();
    if (latestMessage) {
      where.createdAt = { $gt: latestMessage.get('createdAt') };
    }
    try {
      const data = await this.conn[this.dataFunctionName]({
        where,
        ascending: 'createdAt',
      });
      if (data?.length && data?.[0].get('questionId') === 'ProgressCheckFaceFinalOk') {
        const notificationExperiment = this.experiments.find((item:any):any => item.key === 'notification_permission_popup');
        if (notificationExperiment) {
          setTimeout((): void => this.openBottomSheet(), 2000);
        }
      }
      if (this.selectedChatType?.className === 'SupportTicket' && data?.length
        && data[0].get('Owner') === this.appConfig.Shared.Assistant.Owner.BOT
        && !data[0].get('Inputs')?.length) {
        this.selectedChatType = await this.conn.findSupportTicketById(this.route.snapshot.params.id, { translate: true });
      }
      if (data.length === 0
        && latestMessage
        && latestMessage.get('messageAttendedByBot')
        && latestMessage.get('Owner') === this.appConfig.Shared.Assistant.Owner.USER) {
        this.startBotReplyTimer(true);
        return;
      }
      if (data.length === 1 && data?.[0]?.get('MessageInEnglish') === 'What do Cureskin members say?') {
        this.startBotReplyTimer(true);
        return;
      }
      this.cancelBotReplyWaitingTimer();
      await this.addToChats(false, data, true);
      this.processDataAfterFetch();
      this.chatPayload.loading.new = false;
      this.chatPayload.loading.old = false;
      this.chatLoadingStatus.emit(false);
      this.scrollChatToBottom();
    } catch (err) {
      if (err instanceof Error) {
        this.sentry.handleError(err);
      }
      this.chatPayload.loading.new = false;
      this.cancelBotReplyWaitingTimer();
      this.showError(err.message || err.toString());
    }
  }

  openBottomSheet(): void {
    // call function to check permission status true or false
    // if true then perimssionn already given
    this.isNotificationPermissionEnabled = this.appBridge.requestNotificationStatus();
    if (!this.isNotificationPermissionEnabled) {
      this.bottomSheet.open(NotificationPermissionSheetComponent, {
        data: {
          content: 'followup-completed',
          callback: (): void => {
            this.openNativePermissionPopup();
          },
        },
      });
    }
  }

  openNativePermissionPopup(): void {
    this.appBridge.requestNotificationPermission();
  }

  openLanguageChangeDrawer(): void {
    if (this.ui.botReplyInProcess) return;
    const botChats: Array<Table.ConsultationSessionChat> = (this.consultationSessionService.getChats()
      .filter((chat: any): boolean => chat.get('Owner') === 'BOT'));
    const latestBotChat = botChats.length > 0 ? botChats[botChats.length - 1] : undefined;
    this.bottomSheet.open(LanguageChangeComponent, {
      data: {
        translateLastBotQuestionData: {
          questionId: latestBotChat?.get('questionId'),
          regimenClass: this.selectedChatType?.get('PrivateMainConcernClass'),
        },
      },
    });
  }

  async loadOldChat(): Promise<any> {
    if (!this.user || this.chatPayload.loading.old) return;
    this.chatPayload.loading.old = true;
    const initialLoad: boolean = (this.chats.length === 0);
    const where: any = { ...this.where };
    if (this.chats.length) {
      where.createdAt = { $lt: this.chats[0].get('createdAt') };
    }
    try {
      const data = await this.conn[this.dataFunctionName]({
        where,
        descending: ['createdAt'],
        limit: this.chatPayload.limit,
        option: { context: { translate: true } },
      });
      if (!data.length) {
        this.chatPayload.loading.old = false;
        if (!this.dataLoadedTwice) {
          setTimeout((): void => { this.loadOldChat(); }, 1000);
          this.dataLoadedTwice = true;
        }
        return;
      }
      await this.addToChats(true, data);
      this.processDataAfterFetch(true, !initialLoad);
      this.chatPayload.loading.old = false;
      this.chatLoadingStatus.emit(false);
    } catch (err) {
      if (err instanceof Error) {
        this.sentry.handleError(err);
      }
      this.chatPayload.loading.old = false;
      this.showError(err.message || err.toString());
    }
  }

  isBotReplyDelayed(message: any): boolean {
    const latestMessage = message;
    if (latestMessage // if bot didn't reply for user message for more than 5 minutes, then open chat input.
      && latestMessage.get('Owner') === this.appConfig.Shared.Assistant.Owner.USER
      && latestMessage.get('messageAttendedByBot')) {
      const lastMessageTime: any = new Date(latestMessage.get('createdAt')).getTime();
      const minutes = (new Date().getTime() - lastMessageTime) / 1000 / 60;
      if (minutes >= 5) {
        this.cancelBotReplyWaitingTimer();
        this.ui.chatInputEnable = true;
        return true;
      }
    }
    return false;
  }

  enableChatInput(): boolean {
    const message = this.latestMessage;
    if (this.selectedChatType?.get('status') === this.appConfig.Shared.Ticket.status.Completed) {
      if (this.callBackExperimentEnable) {
        this.disableChatInput = false;
      }
      return false;
    }
    if (this.selectedChatType?.get('status') === this.appConfig.Shared.Ticket.status.Pending
        && this.selectedChatType?.get('treeCompleted') && this.callBackExperimentEnable
    ) {
      this.disableChatInput = true;
      return false;
    }
    if ((!message || message.get('blockInputAfterMessage') === false) && !this.selectedChatType.get('disableChat')) return true;
    if (this.isBotReplyDelayed(message)) return true;
    if (message?.get('blockInputAfterMessage')) return false;
    if ((message?.get('Inputs') && message.get('Inputs')?.length)) {
      return false;
    }
    return !(this.disableChat || this.selectedChatType.get('disableChat'));
  }

  processDataAfterFetch(scrollToUnreadMessage?: boolean, restoreScroll?: boolean): void {
    this.zone.run((): void => {
      this.latestMessage = this.getLatestMessage(true);
      this.ui.chatInputEnable = this.enableChatInput();
      if (restoreScroll) {
        this.restoreScrollPosition();
      } else {
        this.scrollChatToBottom(scrollToUnreadMessage);
      }
    });
  }

  scrollChatToBottom(scrollToUnreadMessage?: boolean): void {
    setTimeout((): void => {
      if (scrollToUnreadMessage && this.unreadMessageDivider) {
        this.unreadMessageDivider.nativeElement.parentElement.scrollIntoView();
        return;
      }
      const scrollView = this.messageContainer.nativeElement;
      if (scrollView) {
        this.ui.enableScrollToBottom = false;
        if (scrollView.scroll instanceof Function) {
          scrollView.scroll(0, scrollView.scrollHeight);
        }
        if (scrollView.scrollTop instanceof Function) scrollView.scrollTop = scrollView.scrollHeight;
        const lastVisibleNode = V2ChatComponent.lastVisibleNode(scrollView);
        if ((lastVisibleNode.offsetHeight + lastVisibleNode.offsetTop + this.chatScrollErrorY) > scrollView.offsetHeight) {
          this.chatScrollPossible.emit();
        }
      }
    }, 0);
  }

  onKeyBoardOpen(): void {
    const scrollView = this.messageContainer.nativeElement;
    setTimeout((): void => {
      if (scrollView.scroll instanceof Function) {
        scrollView.scroll(0, scrollView.scrollHeight);
      }
    }, 0);
  }

  scrollListener(event: any): void {
    if (this.scrollThrottle) return;
    this.updateOnScroll(event);
    this.scrollThrottle = true;
    setTimeout((): boolean => this.scrollThrottle = false, 200);
  }

  updateOnScroll(event: any): void {
    const containerCords = this.messageContainer.nativeElement.getBoundingClientRect();
    if (event.srcElement.scrollTop < (event.srcElement.offsetHeight * 2)
      && !this.chatPayload.noMoreData
      && !this.chatPayload.loading.old) {
      this.loadOldChat();
    }
    if (event.srcElement.scrollHeight - event.srcElement.scrollTop >= (containerCords.height * 2)) {
      this.ui.enableScrollToBottom = true;
    }
    if ((event.srcElement.scrollTop + containerCords.height + 100) >= event.srcElement.scrollHeight) {
      this.ui.enableScrollToBottom = false;
    }
  }

  recordScrollPosition(): void {
    this.ui.chatScrollTop = this.messageContainer.nativeElement.scrollHeight - this.messageContainer.nativeElement.scrollTop;
  }

  restoreScrollPosition(): void {
    if (!this.ui.chatScrollTop) return;
    setTimeout((): void => {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight - this.ui.chatScrollTop;
      delete this.ui.chatScrollTop;
    });
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message: message.error || message.toString() });
  }

  async onUploadReport(event: any): Promise<void> {
    const file = event.target.files[0];
    if (!file) return;
    if (!this.commonUtilityHelper.isFileSizeLesserThanMaxSize(file.size, 5)) {
      this.broadcast.broadcast('NOTIFY', { message: 'File size should be less than 5MB. Kindly retry.' });
      return;
    }
    const response: { publicURL: string; signedGetURL: string } = await this.conn.uploadFile({
      file,
      bucket: 'OTHER',
      username: this.user.get('username'),
      source: 'app_upload_report',
    });
    const userFile = new Table.UserFiles();
    userFile.set('fileUrl', response.publicURL);
    userFile.set('user', this.user);
    userFile.set('type', this.commonUtilityHelper.findFileType(file.name));
    try {
      await userFile.save({}, { context: { supportTicketId: this.selectedChatType.id } });
      await this.loadNewChat();
    } catch (error) {
      this.broadcast.broadcast('NOTIFY', { message: error.toString() });
    }
  }

  async redirectToNewTicket(): Promise<any> {
    const newQuestionId = (this.newHelpTreeExperiment && this.newSupportQuestionId) ? this.newSupportQuestionId : '8L9eKnSUPe';
    if (this.selectedChatType?.className === 'SupportTicket'
    && this.selectedChatType?.get('status') === this.appConfig.Shared.Ticket.status.Completed) {
      const { supportTicketId }: { supportTicketId: string } = await this.conn.createTicket(newQuestionId);
      await this.router.navigate([`/support/ticket/${supportTicketId}`]);
    }
  }

  async processAssistantAction(assistant: any, index?: any, input?: any): Promise<any> {
    if (!assistant.get('Inputs') || !assistant.get('Inputs').length) return;
    await this.redirectToNewTicket();
    if (this.selectedChatType?.className === 'SupportTicket' && this.route.snapshot.queryParams.reportId) {
      const followUpReport = new Table.FollowUpReport();
      followUpReport.id = this.route.snapshot.queryParams.reportId;
      followUpReport.set('consentFormAsk', false);
      await followUpReport.save();
    }
    if (assistant.get('Inputs')[index].action === 'ACTION') {
      if (assistant?.get('Inputs')[index]?.params?.queryParams) {
        const params = assistant?.get('Inputs')[index]?.params?.queryParams;
        this.photoType = JSON.parse(params).tag;
        if (this.photoType.includes(this.appConfig.Shared.InstantCheckup.Type.FACE)) {
          this.localStorageService.setValue('expectedFacePhoto', this.photoType);
        }
      }
      await this.singleSelectorService.openInAppAction(
        assistant.get('Inputs')[index],
        assistant.get('context'),
        this.filePickerComponent,
        assistant.id,
        assistant.className,
        (inputItem: InputType, data: Record<string, unknown>): void => {
          switch (inputItem.params.actionName) {
            case 'OrderSelection': {
              if (data.option) {
                const option = data.option as { orderId: string; orderNumber: string; };
                const context = assistant.get('context');
                context.orderId = option.orderId;
                assistant.set('context', context);
                this.saveUserResponse(assistant, option.orderNumber);
              }
              break;
            }
            case 'ProductSelection': {
              if (data.option) {
                const option = data.option as { productId: string; title: string; };
                const context = assistant.get('context');
                context.catalogId = option.productId;
                assistant.set('context', context);
                this.saveUserResponse(assistant, option.title);
              }
              break;
            }
            default:
          }
        });
    } else if (assistant.get('Inputs')[index].action === 'TREE') {
      const message = assistant.get('Inputs')[index].type === 'TEXT'
        ? input
        : assistant.get('Inputs')[index].text;
      await this.saveUserResponse(assistant, message);
    }
  }

  prepareReply(lastMessage: any, userReply: string): any {
    const reply = new Table[lastMessage?.className](this.where);
    if (lastMessage
      && lastMessage.get('Owner') === this.appConfig.Shared.Assistant.Owner.BOT
      && lastMessage.get('BotMetadata')) {
      reply.set('BotMetadata', `REPLY_${lastMessage.get('BotMetadata')}`);
    }
    let mode = this.appConfig.Shared.Assistant.Mode.TEXT_NOREPLY;
    if (lastMessage && this.responseModes.includes(lastMessage.get('Mode'))) {
      mode = `${lastMessage.get('Mode')}_RESPONSE`;
    }
    if (this.isInternalUser) {
      reply.set('OperatorName', this.currentUser?.get('username'));
    }
    reply.set('user', this.user);
    reply.set('Owner', this.appConfig.Shared.Assistant.Owner.USER);
    reply.set('context', lastMessage.get('context'));
    reply.set('questionId', lastMessage.get('questionId'));
    reply.set('Message', userReply);
    reply.set('Mode', mode);
    reply.set('messageAttendedByBot', lastMessage.get('messageAttendedByBot'));
    reply.set('Owner', this.appConfig.Shared.Assistant.Owner.USER);
    reply.set('UserId', this.user.get('username'));
    reply.set('uniqueId', lastMessage ? `assistant_3_${lastMessage.id}` : uuid());
    return reply;
  }

  async saveUserResponse(prevMessage: any, userReply: string): Promise<any> {
    if (!userReply || this.ui.botReplyInProcess || this.ui.userResponseSaveInProcess) return;
    this.ui.userResponseSaveInProcess = true;
    if (!this.allowAnimation) this.allowAnimation = true;
    const lastMessage = prevMessage || this.getLatestMessage();
    const messageAttendedByBot = lastMessage?.get('messageAttendedByBot');
    const reply = await this.prepareReply(lastMessage, userReply);

    this.addToChats(false, [reply]);
    this.scrollChatToBottom();
    delete this.chatPayload.input;
    if (messageAttendedByBot) {
      this.updateBotReplyProgress(true);
    }
    try {
      await reply.save();
      if (messageAttendedByBot) {
        this.startBotReplyTimer();
      } else {
        setTimeout((): Promise<void> => this.loadNewChat(), 2000);
      }
      this.ui.userResponseSaveInProcess = false;
    } catch (err) {
      this.updateBotReplyProgress(false);
      this.ui.userResponseSaveInProcess = false;
      this.zone.run((): void => this.chats.pop());
      this.showError(err.message || err.toString());
      if (err.code === 412 && err.message.includes('Please answer pending questions.')) {
        await this.loadNewChat();
      } else if (err instanceof Error) {
        this.sentry.handleError(err);
      }
    }
  }

  cancelBotReplyWaitingTimer(retryFetch?: boolean): void {
    this.zone.run((): void => {
      if (this.startTimeOfBotReply && !retryFetch) {
        const time = new Date().getTime() - this.startTimeOfBotReply;
        this.eventLogger.trackEvent('BOT_RESPONSE_TIME', { userId: this.user.id, timeInMillSec: time });
        delete this.startTimeOfBotReply;
      }
      if (!retryFetch) {
        this.ui.botReplyTimeSeries = { t1: 1000, t2: 1000 };
      }
      this.updateBotReplyProgress(false);
      if (!this.waitForBotReplyTimer) return;
      clearTimeout(this.waitForBotReplyTimer);
      delete this.waitForBotReplyTimer;
    });
  }

  async startBotReplyTimer(log?: boolean): Promise<boolean> {
    return this.zone.run(async (): Promise<boolean> => {
      if (log) {
        this.eventLogger.trackEvent('BOT_RESPONSE_RETRY', {
          userId: this.user.id,
          assistant: this.getLatestMessage(),
        });
      }
      this.cancelBotReplyWaitingTimer(true);
      if (!this.startTimeOfBotReply) {
        this.startTimeOfBotReply = new Date().getTime();
      }
      this.updateBotReplyProgress(true);
      this.chatPayload.loading.new = false;

      const seconds = this.ui.botReplyTimeSeries.t1 + this.ui.botReplyTimeSeries.t2;
      this.waitForBotReplyTimer = setTimeout((): Promise<void> => this.loadNewChat(), seconds);
      this.ui.botReplyTimeSeries.t1 = this.ui.botReplyTimeSeries.t2;
      this.ui.botReplyTimeSeries.t2 = seconds;

      const latestMessage = this.getLatestMessage();
      if (this.isBotReplyDelayed(latestMessage)) return true;
      return false;
    });
  }

  navigateTo(url: string): void {
    if (!url) return;
    let queryString = '';
    if (String(url)
      .toLowerCase()
      .includes('regimen')) {
      queryString += `?class=${this.user.get('PrivateMainConcernClass')}`;
    }
    this.conn.navigateToURL(`${url}${queryString}`);
  }

  /**
   * Calculates the minutes, seconds left and runs the clock timer for every seconds.
   * Opens the regimen once the timer ends and clears the interval.
   * @param {number} milliseconds - no of milliseconds left
   * @param chat
   */
  startRegimenPreparationTimer(milliseconds: number, chat: any): any {
    this.conn.updateExperimentLogsInUser({ regimen_allocation_timer_started: this.user.get('PrivateMainConcernClass') });
    this.eventLogger.trackEvent('regimen_allocation_timer_started', { class: this.user.get('PrivateMainConcernClass') });
    this.zone.run((): void => {
      if (milliseconds <= 0) return;
      const minutes = Math.floor(milliseconds / 1000 / 60);
      this.timer.minutes = (minutes > 2) ? 1 : minutes;
      this.timer.seconds = Math.floor((milliseconds - (minutes * 1000 * 60)) / 1000);
      this.timer.time = chat.get('createdAt');
      this.interval = setInterval(async (): Promise<void> => {
        if (this.timer.seconds - 1 >= 0) this.timer.seconds -= 1;
        if (this.timer.seconds - 1 < 0) {
          this.timer.minutes -= 1;
          this.timer.seconds = 59;
        }
        if (this.timer.minutes < 0) {
          clearInterval(this.interval);
          this.timer = {};
        }
      }, 1000);
    });
  }

  isChatVisible(chat: any): boolean {
    return ![this.appConfig.Shared.Assistant.Mode.BLOCK].includes(chat.get('Mode'));
  }

  async openActiveSessionRegimen(fetchRegimen?: boolean): Promise<any> {
    if (fetchRegimen) await this.conn.fetchRegimens(null, true);
    this.scrollChatToBottom();
    this.router.navigate(['/user'], {
      queryParams: {
        tab: 'regimen',
        class: this.selectedChatType.get('PrivateMainConcernClass'),
        resetSelection: 'true',
      },
    });
  }

  trackChatArray(index: number): number {
    return index;
  }

  home(): void {
    const queryParams: any = { tab: 'home' };
    this.router.navigate(['/user'], { queryParams });
  }

  back(): void {
    if (this.selectedChatType.className === 'SupportTicket') {
      const queryParams: any = { returnedFrom: 'supportTicket' };
      this.router.navigate(['/support'], { queryParams });
      return;
    }
    const queryParams: any = { tab: 'home' };
    this.router.navigate(['/user'], { queryParams });
    this.eventLogger.cleverTapEvent('clickedBack', JSON.stringify({ name: 'home' }));
  }

  handleBackPress(): boolean {
    this.back();
    return true;
  }

  /**
   * Logs the last visited time of that ticket in the ticket table.
   */
  ngOnDestroy(): void {
    if (!this.isInternalUser && this.selectedChatType?.className === 'SupportTicket') {
      this.conn.updateUserLastActiveDetails(this.selectedChatType.id);
    }
    this.currentComponent.remove(this);
    this.cancelBotReplyWaitingTimer();
    clearInterval(this.interval);
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
    this.subscriptions = [];
  }

  private static lastVisibleNode(nativeElement: any): any {
    let index = nativeElement.childNodes.length - 1;
    while (index > 0) {
      if (nativeElement.childNodes[index].nodeName === 'DIV') {
        break;
      }
      index -= 1;
    }
    return nativeElement.childNodes[index];
  }

  async updateFeedback(isHelpfull: boolean, userQuery?: string): Promise<void> {
    if (!isHelpfull) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'support-ticket-dislike' }));
    } else {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'support-ticket-like' }));
    }
    if (this.isInternalUser) return;
    this.isHelpfull = isHelpfull;
    const result = await this.conn.updateSupportTicketFeedback(this.selectedChatType.id, isHelpfull, userQuery, true);
    if (result.ticketStatusChanged) {
      this.selectedChatType = await this.conn.findSupportTicketById(this.route.snapshot.params.id, { translate: true });
      if (this.selectedChatType?.get('status') === this.appConfig.Shared.Ticket.status.Pending) await this.loadNewChat();
      return;
    }
    this.afterSaveFeedback = true;
  }

  updateFeedBackIfFalse(): any {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'support-ticket-dislike' }));
    this.bottomSheet.open(GetFeedbackSheetComponent, {
      data: {
        updateFeedbackAndCreatePendingCall: async (createCall: boolean, userQuery?: string): Promise<void> => {
          if (createCall) {
            await this.updateFeedbackAndCreatePendingCall(this.isHelpfull, userQuery);
            this.bottomSheet.dismiss();
          } else {
            await this.updateFeedback(this.isHelpfull, userQuery);
            this.bottomSheet.dismiss();
          }
        },
      },
    });
  }

  async updateFeedbackAndCreatePendingCall(isHelpfull: boolean, userQuery?: string): Promise<void> {
    if (this.isInternalUser) return;
    this.isHelpfull = isHelpfull;
    const result = await this.conn.createPendingCallAndSaveNote({ message: userQuery,
      type: ApiClientConstant.PendingCall.Type.UserRequestedCall,
      supportTicket: this.selectedChatType,
      user: this.user });
    await this.conn.updateSupportTicketFeedback(this.selectedChatType.id, false, userQuery, false);
    if (result.message === 'success') {
      this.selectedChatType = await this.conn.findSupportTicketById(this.route.snapshot.params.id, { translate: true });
      if (this.selectedChatType?.get('status') === this.appConfig.Shared.Ticket.status.Pending) await this.loadNewChat();
      return;
    }
    this.bottomSheet.dismiss();
    this.afterSaveFeedback = true;
  }
  getExpectedPhotoType(chat: any): string {
    if (chat?.get('questionId') === 'TakeInstantCheckup') {
      return this.appConfig.Shared.InstantCheckup.Type.FRONT_FACE;
    }
    if (chat?.get('questionId') === 'TakeInstantCheckSideFace') {
      return this.appConfig.Shared.InstantCheckup.Type.SIDE_FACE;
    }
    return this.appConfig.Shared.InstantCheckup.Type.FACE;
  }

  handleImageCardClick(chat: any):void {
    const expectedPhotoType = this.getExpectedPhotoType(chat);
    if (expectedPhotoType.includes(this.appConfig.Shared.InstantCheckup.Type.FACE)) {
      this.localStorageService.setValue('expectedFacePhoto', expectedPhotoType);
    }
    this.setRedirectUrl();
  }

  setRedirectUrl(): void {
    let redirectUrl = this.router.url;
    redirectUrl += `${redirectUrl.includes('?') ? '&' : '?'}back=home`;
    this.localStorageService.setValue('CureSkin/redirectUrl', redirectUrl);
  }

  loadChatProgress(chats: any[]): void {
    this.chatProgressService.setSelectedChatType(this.selectedChatType.className);
    chats
      .filter((chat: any): boolean => chat.attributes.Owner === this.appConfig.Shared.Assistant.Owner.USER)
      .forEach((chat: any): void => {
        const { questionId }: { questionId: string } = chat.attributes;
        const chatTypeKey: string = `${this.selectedChatType.get('PrivateMainConcernClass')}-${questionId}`;

        // Before 90% if questionId is null, undefined, or already processed, skip processing
        if (this.progress < 90) {
          if (questionId === null || questionId === undefined || this.processedQuestions.has(chatTypeKey)) {
            return;
          }
        }

        this.processedQuestions.add(chatTypeKey); // Mark this questionId as processed for this chat type

        const configItem = Object.values(this.progressConfig).find(
          (item: any): boolean => item.questionId === questionId,
        );

        if (configItem) {
          if (configItem.questionType === this.appConfig.Shared.Assistant.QuestionType.ANCHOR) {
            if (configItem.progressValue !== null) {
              if (configItem.progressValue < 98 && configItem.progressValue > this.progress) {
                // Case 1: If progressValue is less than 98 and greater than current progress
                this.progress = configItem.progressValue;
                this.chatProgressService.setChatProgressState(this.progress);
              } else if (configItem.progressValue <= this.progress) {
                // Case 2: If progressValue is less than or equal to current progress, increment by 5%
                this.progress = Math.min(this.progress + 5, 98);
                this.chatProgressService.setChatProgressState(this.progress);
              } else if (configItem.progressValue > 98) {
                // Case 3: If progressValue is greater than 98, do not increment progress
                this.progress = Math.min(this.progress, 98);
              } else if (this.progress >= 90 && this.progress <= 98) {
                // Case 4: Handle progress when between 90% and 98%
                this.progress = Math.min(this.progress + 2, 98);
                this.chatProgressService.setChatProgressState(this.progress);
              }
            } else {
              // Case 5: Handle null progressValue for Anchor type
              this.progress = Math.min(this.progress + 5, 98);
              this.chatProgressService.setChatProgressState(this.progress);
            }
          } else if (configItem.questionType === this.appConfig.Shared.Assistant.QuestionType.INFO) {
            this.progress = Math.min(this.progress, 98);
            this.chatProgressService.setChatProgressState(this.progress);
          }
        } else {
          // Handle cases where questionId is not in the ProgressConfig
          // eslint-disable-next-line no-lonely-if
          if (this.progress < 90) {
            this.progress = Math.min(this.progress + 5, 98);
            this.chatProgressService.setChatProgressState(this.progress);
          } else if (this.progress >= 90 && this.progress < 98) {
            this.progress = Math.min(this.progress + 2, 98);
            this.chatProgressService.setChatProgressState(this.progress);
          }
        }
      });

    chats
      .filter((chat: any): boolean => chat.attributes.Owner === this.appConfig.Shared.Assistant.Owner.BOT)
      .forEach((chat: any): void => {
        if (chat.attributes.uniqueId?.includes('regimen_delay')) {
          this.progress = Math.min(95, 98);
          this.chatProgressService.setChatProgressState(this.progress);
        }
      });

    this.progress = Math.max(5, Math.min(this.progress, 98));
  }
  updateBotReplyProgress(isBotReplyInPorcess: boolean): void {
    this.ui.botReplyInProcess = isBotReplyInPorcess;
    this.botReplyInProcessChange.emit(isBotReplyInPorcess);
  }

  formatTime(dateString: string): string {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr}${ampm}`;
  }
}

<div class="tw-flex-none tw-shadow-z1 tw-animate-slide_in_bottom">
  <div class="tw-flex tw-item-center tw-justify-center tw-py-2 tw-bg-orange-100 tw-h-[32px]" *ngIf="+discount >= 5">
    <p class="tw-font-body tw-text-200 tw-text-black tw-flex tw-items-center tw-gap-1 tw-my-0 tw-font-medium">
      <cs-icon class="tw-h-5 tw-w-5 tw-text-teal-600" [iconName]="'sale'"></cs-icon>
      <span class="tw-font-body" i18n="@@discountAppliedForProducts"> {{ discount }}% discount applied</span>
      <span class="tw-font-body" i18n="@@amountSaved">(₹{{ product.mrp - product.price }} saved)</span>
    </p>
  </div>
  <div class="tw-flex tw-gap-4 justify-between tw-items-center tw-p-4 tw-h-20">
    <div class="tw-w-max">
      <div class="tw-flex tw-flex-col xs:tw-flex-row-reverse xs:tw-items-center tw-gap-1 tw-font-body" *ngIf="product.mrp !== product.price">
        <span class="tw-text-650 tw-font-bold tw-text-orange-500 tw-block tw-leading-[16px] tw-font-body">₹{{ product.price }}</span>
        <del class="tw-text-400 tw-font-bold tw-text-gray-500 tw-block tw-font-body">₹{{ product.mrp }}</del>
      </div>
      <div *ngIf="product.mrp === product.price">
        <span class="tw-text-650 tw-font-bold tw-text-orange-500 tw-block tw-leading-[16px] tw-font-body">₹{{ product.price }}</span>
      </div>
    </div>
    <div class="tw-flex tw-gap-2 tw-items-center tw-justify-end tw-w-full">
      <div class="tw-border tw-border-teal-600 tw-rounded-3 tw-text-teal-600 tw-text-300 tw-font-bold tw-w-full tw-h-10 tw-flex tw-justify-center tw-items-center tw-p-0"
           *ngIf="!productInCart?.quantity && !isAddRemovePending"
           (click)="onAddToCart()">
        <span i18n="@@addToCart" class="tw-w-max tw-font-body">Add to Cart</span>
      </div>

      <div class="tw-h-10 tw-w-full tw-max-w-[74px] tw-border tw-border-teal-600 tw-rounded-3 tw-flex tw-justify-between tw-items-center tw-px-2 tw-text-teal-600 tw-text-300 tw-font-bold"
           *ngIf="isAddRemovePending">
        <loading [scale]="0.5" margin="0px 7px"></loading>
      </div>

      <div *ngIf="productInCart?.quantity && !isAddRemovePending"
           class="tw-h-10 tw-w-full tw-max-w-[74px] tw-border tw-border-teal-600 tw-rounded-3 tw-flex tw-justify-between tw-items-center tw-px-2 tw-text-teal-600 tw-text-300 tw-font-bold tw-font-body">
        <span (click)="onRemoveFromCart()">-</span>
        <span>{{ productInCart?.quantity }}</span>
        <span (click)="onAddToCart()">+</span>
      </div>

      <div class="tw-border tw-border-teal-600 tw-bg-teal-600 tw-rounded-3 tw-text-white tw-text-300 tw-font-bold tw-w-full tw-h-10 tw-flex tw-justify-center tw-items-center tw-p-0 tw-transition-all tw-duration-300"
           (click)="onBuyNow()">
        <span i18n="@@buyNow" class="tw-font-body">Buy Now</span>
      </div>
    </div>
  </div>
</div>

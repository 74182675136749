import { Component } from '@angular/core';
import { BroadcastService } from '@services/broadcast-service/index';
import { WindowRefService } from '@services/window-ref-service/index';
import { ConnectionService } from '@services/connection-service/index';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from '../../app.config';

@Component({ selector: 'doctor-list', templateUrl: './doctor-list.html' })
export class DoctorListComponent {
  doctorId: string;
  doctors: Array<any>;
  static parameters: Array<any> = [BroadcastService, WindowRefService, AppConfig, ConnectionService];
  doctorCharuImg: string;
  constructor(
    private broadcast: BroadcastService,
    private window: WindowRefService,
    public appConfig: AppConfig,
    public conn: ConnectionService,
    public eventLogger: EventLoggerService) {
  }

  async ngOnInit(): Promise<any> {
    await this.findDoctorsLocal();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'doctors-list' }));
  }

  async findDoctorsLocal(): Promise<void> {
    const doctors = await this.conn.findDoctors();
    this.doctors = doctors.filter((each: any): any => each.get('doctorDisplayImage') && each.get('doctorQualification'));
    this.doctorCharuImg = 'client/assets/images/dr-charu.png';
  }

  back(): void {
    this.broadcast.broadcast('NAVIGATION_BACK');
  }

  viewDoctor(doctor: any): void {
    this.conn.navigateToURL(`/doctor/${doctor.id}`);
  }

  handleViewDoctor(doctor: any): void {
    this.viewDoctor(doctor);
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'view-doctor-details' }));
  }

  stopPropagation(event: any): void {
    event.stopPropagation();
  }
}

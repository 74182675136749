<div class="tw-absolute tw-w-full" (click)="onCheckout.emit()" [ngClass]="{ 'tw-bottom-0': !isBottomBarVisible && fromListingPage, 'tw-bottom-[88px]': isBottomBarVisible && fromListingPage, 'tw-bottom-[80px]': !fromListingPage }">
  <div class="flex-footer tw-h-auto tw-bg-white tw-bg-left-top tw-bg-no-repeat tw-bg-clip-pading tw-bg-origin-padding tw-opacity-100 tw-shadow-[0_-1px_10px_#0000005d]" style="box-shadow: 0px -1px 10px #0000005d;"  *ngIf="numberOfProductsInCart">
    <div class="tw-py-[10px] tw-px-[20px] tw-flex tw-justify-between tw-flex-wrap" *ngIf="!fromListingPage">
      <div
        class="tw-text-left tw-font-normal tw-not-italic tw-normal-nums tw-tracking-normal tw-text-black tw-capitalize tw-opacity-100 tw-font-body tw-text-gray-700 tw-normal-case"
        [ngClass]="{'tw-text-100': userLanguageKnOrTa, 'tw-text-300': !userLanguageKnOrTa}"
        *ngIf="numberOfProductsInCart === 1"
        i18n="@@productAdded"
      >
        <span class="tw-font-bold">{{numberOfProductsInCart}}&nbsp;</span
        ><span>product added to your cart</span>
      </div>
      <div
        class="tw-text-left tw-font-normal tw-not-italic tw-normal-nums tw-tracking-normal tw-text-[#000000] tw-capitalize tw-opacity-100 tw-font-body tw-text-gray-700 tw-normal-case"
        [ngClass]="{'tw-text-100': userLanguageKnOrTa, 'tw-text-300': !userLanguageKnOrTa}"
        *ngIf="+numberOfProductsInCart > 1"
        i18n="@@productsAdded"
      >
        <span class="tw-font-bold">{{numberOfProductsInCart}}&nbsp;</span
        ><span>products added to your cart</span>
      </div>
      <div
        class="tw-text-center tw-font-bold tw-not-italic tw-normal-nums tw-tracking-normal tw-text-[#5eac73] tw-capitalize tw-opacity-100 tw-font-body tw-text-gray-700 tw-normal-case tw-text-green-500"
        [ngClass]="{'tw-text-100': userLanguageKnOrTa, 'tw-text-300': !userLanguageKnOrTa}"
        (click)="onCheckout.emit()"
      >
        <span i18n="@@buyNow">Buy Now</span
        ><em class="cs-icon-arrow tw-text-green-400 icon-arrow"></em>
      </div>
    </div>
  </div>
  <div
    class="tw-w-full tw-relative tw-shadow-[0_-1px_7px_rgba(0,0,0,0.13)] tw-p-4 tw-h-[88px] tw-p-[16px]"
    [ngStyle]="{'background': amountRemaining > 0 ? '#F9E7D7' : '#EBF1CE'}"
  >
    <div
      class="tw-font-body tw-font-bold"
      [ngClass]="{'tw-text-300': userLanguageKnOrTa, 'tw-text-500': !userLanguageKnOrTa}"
      *ngIf="amountRemaining > 0 "
      i18n="@@getFreeProductNew"
    >
      <span>Get a&nbsp;</span><span class="tw-text-orange-400">free&nbsp;</span
      ><span>product</span>
    </div>
    <div
      class="tw-flex tw-font-body tw-font-bold"
      [ngClass]="{'tw-text-300': userLanguageKnOrTa, 'tw-text-500': !userLanguageKnOrTa}"
      *ngIf="amountRemaining <= 0 "
      i18n="@@congratulations"
    >Congratulations!</div>
    <div class="tw-border-solid">
      <div class="tw-bg-white tw-h-2 tw-opacity-100 tw-border tw-border-solid tw-rounded-1 tw-rounded-t tw-my-1" id="mainBar">
        <div class="tw-bg-left-top tw-bg-no-repeat tw-bg-clip-pading tw-bg-origin-padding tw-h-[7.59px]" id="progressBar" [ngStyle]="barStyle" [ngClass]="{ 'tw-border-orange-100': amountRemaining > 0, 'tw-border-green-100': amountRemaining <= 0 }"></div>
      </div>
      <img class="tw-box-border tw-absolute tw-w-[70px] tw-h-[71px] tw-right-[15px] tw-top-[10px] tw-border-2 tw-border-solid tw-border-gray-75 tw-rounded-[39px] tw-object-contain tw-p-1 tw-bg-white" [src]="prodImage" />
    </div>
    <div class="tw-text-left" style="display: unset;">
      <div
        class="tw-flex tw-font-body tw-text-gray-500"
        [ngClass]="{'tw-text-100': userLanguageKnOrTa, 'tw-text-300': !userLanguageKnOrTa}"
        *ngIf="amountRemaining > 0"
        i18n="@@shopMoreNew"
      >
        Shop for
        <span class="tw-font-bold tw-px-1">₹{{ amountRemaining%1 != 0 ? amountRemaining.toFixed(2) : amountRemaining }}</span>
        more
      </div>
      <div class="freeGift tw-font-body tw-text-gray-500"
          [ngClass]="{'tw-text-100': userLanguageKnOrTa, 'tw-text-300': !userLanguageKnOrTa}"
           *ngIf="amountRemaining <= 0 "
           i18n="@@youGotFreeProduct">You have got a free gift</div>
      <ng-lottie *ngIf="showConfettiAnimation"
                 width="100%"
                 height="200px"
                 [styles]="styles"
                 [options]="options"></ng-lottie>
    </div>
  </div>
</div>

<div id="confirmCodOrderBottomsheet" class="font-noto tw-relative tw-px-5 tw-py-4">
  <div class="tw-flex tw-justify-between tw-items-center tw-mb-0.5">
    <h3 class="tw-text-lg tw-font-semibold tw-text-600" i18n="@@confirmCodOrder">Confirm COD Order</h3>
    <div id="closeBottomSheetBtn" class="tw-w-[35px] tw-h-[35px] tw-flex tw-justify-center tw-items-center tw-p-1 tw-rounded-full tw-bg-gray-100" (click)="closeBottomSheet()">
      <div class="tw-text-black tw-text-500 tw-font-bold">&#10005;</div>
    </div>
  </div>
  <p i18n="@@confirmCodToProceed">Please confirm COD payment method to proceed. For a hassle-free delivery experience, pay online now.</p>
  <div class="tw-mt-4">
    <button id="confirmOrder" class="tw-w-full tw-text-400 tw-text-wrap tw-font-body tw-bg-green-400 tw-text-white tw-rounded-3 tw-mb-2" i18n="@@codPay" (click)="confirmOrder()">
      Cash on delivery - Pay ₹{{data.orderSp}}
    </button>
    <button id="payOnline" class="tw-w-full tw-text-400 tw-font-body tw-text-green-400 tw-border tw-border-solid tw-border-green-400 tw-rounded-3" i18n="@@payOnline" (click)="payOnline()">
      Pay Online
    </button>
  </div>
</div>

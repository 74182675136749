<div class="tw-border tw-border-[#DCE8E5] tw-p-4 tw-rounded-3 tw-bg-gradient-light-teal tw-font-body tw-shadow-z1" (click)="bannerClick()">
  <span class="">
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.0149 2.5C23.4785 2.5 29.5149 8.53643 29.5149 16C29.5149 23.4636
      23.4785 29.5 16.0149 29.5C8.55132 29.5 2.51489 23.4636 2.51489 16C2.51489 8.53643 8.55132 2.5 16.0149 2.5ZM7.97998
      16.5304L9.40737 15.103L13.486 19.1816L22.6626 10.005C23.1521 10.4945 23.6007 10.9431 24.09 11.4324C20.5417 14.9807
      17.0343 18.4881 13.4858 22.0366C11.6504 20.2013 9.81536 18.3657 7.97998 16.5304Z" fill="#4EAE9C"/>
    </svg>
  </span>
  <h1 class="tw-text-600 tw-font-bold tw-text-teal-600 tw-mt-1 tw-mb-2">Your call is scheduled</h1>
  <p class="tw-font-body tw-leading-5 tw-text-300 tw-text-gray-500 tw-mb-2">
    A skin doctor from our team will call you soon on your mobile number {{ mobileNumber }}
  </p>
</div>

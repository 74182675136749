import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '@components/loading';
import { ButtonModule } from '@components/button';
import { ToolbarModule } from '@components/toolbar';
import { TranslateStringsModule } from '@components/translate-string';
import { PopUpModalModule } from '@components/pop-up-modal';
import { ProductListModule } from '@shared/product-list/product-list.module';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { NotificationPermissionSheetModule }
  from '@shared/bottom-sheet-layouts/notification-permission-sheet/notification-permission.module';
import { CsIconModule } from '@components/icon';
import { FormsModule } from '@angular/forms';
import { PopUpModule } from '@components/pop-up';
import { DiscontinuedPopupModule } from '@shared/discontinued-popup/discontinued-popup.module';
import { AovSaleModule } from '@shared/aov-sale/aov-sale.module';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';
import { DirectivesModule } from '@directives/directives.module';
import { DiscountTimerModule } from '@components/discount-timer/discount-timer.module';
import { InputModule } from '@components/inputs';
import { CCODBottomSheetModule } from '@shared/bottom-sheet-layouts/ccod-bottom-sheet/ccod-bottom-sheet.module';
import { PrepaidDiscountSheetModule } from '@shared/bottom-sheet-layouts/prepaid-discount/prepaid-discount-sheet.module';
import { CodHandlingFeeSheetModule } from '@shared/bottom-sheet-layouts/cod-handling-fee-sheet/cod-handling-fee-sheet.module';
import { ToolbarTabsModule } from '@components/toolbar-tabs';
import { CannotCancelOrderModule } from '@shared/bottom-sheet-layouts/cannot-cancel-order/cannot-cancel-order.module';
import { CancelOrderModule } from '@shared/bottom-sheet-layouts/cancel-order/cancel-order.module';

import { authenticationGuard } from '../guards/authentication.guard';
import { onboardingGuard } from '../guards/onboarding.guard';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderSummaryPopupComponent } from './order-summary-popup/order-summary-popup.component';
import { SelfReorderWarningComponent } from './self-reorder-warning/self-reorder-warning.component';
import { UserOrderAddonComponent } from './user-order-addon/user-order-addon.component';
import { UserOrderFailureComponent } from './user-order-failure/user-order-failure.component';
import { UserOrderListComponent } from './user-order-list/user-order-list.component';
import { UserOrderPaymentComponent } from './user-order-payment/user-order-payment.component';
import { UserOrderReorderComponent } from './user-order-reorder/user-order-reorder.component';
import { UserOrderSuccessComponent } from './user-order-success/user-order-success.component';
import { UserOrderViewComponent } from './user-order-view/user-order-view.component';
import { AovBannerComponent } from '../cart/aov-banner/app-aov-banner.component';

export function playerFactory(): LottiePlayer {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{
      path: 'order',
      canActivate: [authenticationGuard, onboardingGuard],
      children: [
        { path: '', component: UserOrderListComponent },
        { path: 'reorder', component: UserOrderReorderComponent },
        { path: 'success', component: UserOrderSuccessComponent },
        { path: 'failure', component: UserOrderFailureComponent },
        { path: 'addon', component: UserOrderAddonComponent },
        { path: ':id/payment', component: UserOrderPaymentComponent },
        { path: ':id', component: UserOrderViewComponent },
      ],
    }]),
    LoadingModule,
    ButtonModule,
    ToolbarModule,
    TranslateStringsModule,
    PopUpModalModule,
    ProductListModule,
    CsIconModule,
    MatBottomSheetModule,
    NotificationPermissionSheetModule,
    FormsModule,
    PopUpModule,
    DiscontinuedPopupModule,
    AovSaleModule,
    DirectivesModule,
    DiscountTimerModule,
    InputModule,
    CCODBottomSheetModule,
    MatBottomSheetModule,
    PrepaidDiscountSheetModule,
    CodHandlingFeeSheetModule,
    ToolbarTabsModule,
    LottieModule.forRoot({ player: playerFactory }),
    CannotCancelOrderModule,
    CancelOrderModule,
    AovBannerComponent,
  ],
  declarations: [
    OrderListComponent,
    OrderSummaryPopupComponent,
    SelfReorderWarningComponent,
    UserOrderAddonComponent,
    UserOrderFailureComponent,
    UserOrderListComponent,
    UserOrderPaymentComponent,
    UserOrderReorderComponent,
    UserOrderSuccessComponent,
    UserOrderViewComponent,
  ],
  exports: [],
})
export class UserOrderModule { }

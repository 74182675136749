import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-custom-audio-waveform',
  templateUrl: './custom-audio-waveform.html',
  standalone: true,
  imports: [CommonModule],
})
export class CustomAudioWaveformComponent implements OnInit, OnDestroy {
  @Input('voiceUrl') voiceUrl: string;
  @Input('isDoctorImageVariant') isDoctorImageVariant?: boolean = false;
  @Input('docImg') docImg?: string;
  @Input('regimen') regimen?: any;
  @Output() emitTrackEvent: EventEmitter<any> = new EventEmitter<string>();

  waveformBars: number[] = [];
  BARS_PER_100PX: number = 15;
  MIN_BAR_COUNT: number = 10;
  BAR_WIDTH: number = 2;
  MIN_BAR_HEIGHT: number = 20;
  MAX_BAR_HEIGHT: number = 80;
  EDGE_BAR_MAX_VARIATION: number = 10;
  MIDDLE_BAR_MAX_VARIATION: number = 60;
  FULL_BACKGROUND_PROGRESS: number = 100;
  ZERO_PROGRESS: number = 0;
  PERCENTAGE_MULTIPLIER: number = 100;
  resizeObserver: ResizeObserver;
  containerElement: HTMLElement;
  audio: HTMLAudioElement;
  animationFrame: number;
  audioDuration: number = 0;
  progressPercentage: number = 0;
  isPlaying: boolean = false;
  currentTime: number = 0;
  backgroundProgress: number = 15;

  constructor(private readonly windowRef: WindowRefService) {}

  ngOnInit(): void {
    this.initializeAudio();
    this.initializeResponsiveWaveform();
  }

  ngOnDestroy(): void {
    this.cleanup();
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  initializeAudio(): void {
    this.audio = new this.windowRef.nativeWindow.Audio(this.voiceUrl);

    this.audio.addEventListener('loadedmetadata', (): void => {
      this.audioDuration = Math.floor(this.audio.duration);
    });

    this.audio.addEventListener('ended', (): void => {
      this.isPlaying = false;
      this.currentTime = 0;
      this.progressPercentage = 0;
      this.emitTrackEvent.emit('completed');
      this.windowRef.nativeWindow.cancelAnimationFrame(this.animationFrame);
    });
  }

  initializeResponsiveWaveform(): void {
    setTimeout((): void => {
      this.containerElement = this.windowRef.nativeWindow.document.querySelector('#audioWaveformContainer');
      if (this.containerElement) {
        this.resizeObserver = new this.windowRef.nativeWindow.ResizeObserver(this.handleResize.bind(this));
        this.resizeObserver.observe(this.containerElement);
        this.generateWaveform(this.calculateBarCount());
      }
    });
  }

  handleResize(entries: ResizeObserverEntry[]): void {
    const entry = entries[0];
    if (entry) {
      this.generateWaveform(this.calculateBarCount());
    }
  }

  calculateBarCount(): number {
    if (!this.containerElement) return this.MIN_BAR_COUNT;
    const width = this.containerElement.offsetWidth;
    const maxBars = Math.floor(width / this.BAR_WIDTH);
    const desiredBars = Math.floor((width / 100) * this.BARS_PER_100PX);
    return Math.max(this.MIN_BAR_COUNT, Math.min(maxBars, desiredBars));
  }

  generateWaveform(barCount: number): void {
    this.waveformBars = Array(barCount).fill(0).map((_: number, index: number, array: number[]): number => {
      if (index < 2 || index > array.length - 3) {
        return this.MIN_BAR_HEIGHT + (Math.random() * this.EDGE_BAR_MAX_VARIATION);
      }
      return Math.min(
        this.MAX_BAR_HEIGHT,
        Math.max(
          this.MIN_BAR_HEIGHT,
          this.MIN_BAR_HEIGHT + (Math.random() * this.MIDDLE_BAR_MAX_VARIATION),
        ),
      );
    });
  }

  getBarClass(index: number): string {
    const isCurrentBar = this.isPlaying && index === this.getCurrentBar();
    return `tw-w-[2px] tw-transition-all tw-duration-200 tw-rounded-md ${isCurrentBar ? 'tw-animate-pulse-scale' : ''}`;
  }

  getCurrentBar(): number {
    const index = Math.floor((this.progressPercentage / 100) * this.waveformBars.length);
    return Math.max(0, Math.min(index, this.waveformBars.length - 1));
  }

  handlePlayPause(): void {
    if (this.isPlaying) {
      this.audio.pause();
      this.windowRef.nativeWindow.cancelAnimationFrame(this.animationFrame);
      this.backgroundProgress = this.ZERO_PROGRESS;
    } else {
      this.audio?.play();
      this.emitTrackEvent.emit('play');
      this.updateProgress();
      this.backgroundProgress = this.FULL_BACKGROUND_PROGRESS;
    }
    this.isPlaying = !this.isPlaying;
  }

  updateProgress = (): void => {
    this.currentTime = this.audio.currentTime;
    this.progressPercentage = (this.currentTime / this.audioDuration) * this.PERCENTAGE_MULTIPLIER;
    this.animationFrame = this.windowRef.nativeWindow.requestAnimationFrame(this.updateProgress);
  };

  formatTime(seconds: number): string {
    if (seconds < 0) {
      return '0:00';
    }
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  getRemainingTime(): string {
    const remaining = Math.max(0, this.audioDuration - this.currentTime);
    return this.formatTime(remaining);
  }

  cleanup(): void {
    if (this.audio) {
      this.audio.pause();
      this.audio.src = '';
    }
    if (this.animationFrame) {
      this.windowRef.nativeWindow.cancelAnimationFrame(this.animationFrame);
    }
  }

  seekToPosition(event: MouseEvent): void {
    const element = event.currentTarget as HTMLElement;
    const rect = element.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const percentage = (x / rect.width) * this.PERCENTAGE_MULTIPLIER;

    this.audio.currentTime = (percentage / this.PERCENTAGE_MULTIPLIER) * this.audioDuration;
    this.progressPercentage = percentage;
  }
}

<div
  #textContainer
  class="tw-relative tw-w-full tw-h-[60px] tw-overflow-hidden tw-rounded-md tw-mx-auto"
>
  <p #textContent class="tw-text-gray-700 tw-m-0 tw-break-words">
    {{ displayText }}
    <ng-container *ngIf="isOverflowing">
      <span *ngIf="!isExpanded">&hellip;</span>
      <div (click)="toggleText()"
        class="tw-text-gray-800 tw-font-bold tw-hover:underline tw-inline tw-border-0 tw-p-0 tw-bg-transparent">
        <ng-container *ngIf="!isExpanded">
          <span i18n="@@readMore">Read More</span>
        </ng-container>
        <ng-container *ngIf="isExpanded">
          <span i18n="@@readLess">Read Less</span>
        </ng-container>
      </div>
    </ng-container>
  </p>
</div>

import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'cannot-cancel-order',
  templateUrl: './cannot-cancel-order.component.html',
})
export class CannotCancelOrderComponent {
  // eslint-disable-next-line new-cap
  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly bottomSheetRef: MatBottomSheetRef,
  ) {}

  closePopup(): void {
    this.bottomSheetRef.dismiss();
  }
}

<span *ngIf="key" i18n="@@dynamicStrings">{ key, select,FACE {Face} FULL_FACE {Face} FRONT_FACE {Front Face} SIDE_FACE
  {Side Face} LEFT_SIDE_FACE {Left Side Face} RIGHT_SIDE_FACE {Right Side Face} LEFT_FACE {Left Face} RIGHT_FACE {Right Face}
  HAIR {Hair} HAIR_TOP {Hair Top} HAIR_FRONT {Hair Front} BODY {Body} REPORT
  {Report} OTHER {Other} INVALID {Invalid} KEEP_PHOTO {Keep Photo} DELETE_PHOTO
  {Delete Photo} DELETE {Delete} CANCEL_ORDER {Cancel Order} GOT_QUESTIONS {Got
  questions?} SEE_REVIEWS {See reviews} SEND_PRODUCTS {Send products}
  WILL_ORDER_LATER {Will order later} REGIMEN_NOT_HAPPY {Not happy with the
  regimen} ORDER_MISTAKE {Ordered by mistake} COSTLY {Too costly} SOMETHING_ELSE
  {Something else} SUBMIT {Submit} CANCEL {Cancel} OK {OK} LOGOUT {Logout} YES
  {Yes} NO {No} UPDATE_APP {Update App} UPDATE_NOW {Update Now} UPDATE_CHROME
  {Update Chrome} UPDATE_WEB_VIEW {Update Web View} FEEDBACK {Feedback} BAD
  {Bad} GOOD {Good} LATER {Later} RETRY {Retry} RATE_APP {Rate App} RATE_NOW
  {Rate Now} DARKSPOTS {Darkspots} ACNE_OR_PIMPLE {Acne / Pimple} DARK_CIRCLES
  {Dark Circles} DULL_SKIN {Dull Skin} MELASMA {Melasma} STRETCH_MARKS {Stretch
  Marks} RASH {Rash} FUNGAL_INFECTION {Fungal Infection} TINEA {Tinea} DRY_SKIN
  {Dry Skin} HAIRFALL {Hairfall} DANDRUFF {Dandruff} ITCHY_SCALP {Itchy Scalp}
  DRY_HAIR {Dry hair} SCALP_PSORIASIS {Scalp Psoriasis} SKIN {Skin}
  INVALID_PINCODE {Invalid Pincode} PRODUCT_NOT_HAPPY {Not happy with the product}  other { {{key}} }}</span>
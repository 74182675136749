import { Injectable } from '@angular/core';

const VARIANT_ID = {
  variant_999: 'variant_999',
  variant_1499: 'variant_1499',
  variant_1999: 'variant_1999',
  variant_4999: 'variant_4999',
};

const REGIMEN_IMG_BACKGROUND_CLASS = {
  variant_999: 'tw-bg-teal-100',
  variant_1499: 'tw-bg-teal-100',
  variant_1999: 'tw-bg-orange-100',
  variant_4999: 'tw-bg-blue-100',
};

type LinearGradient = {
  color1: string;
  color2: string;
};

@Injectable({ providedIn: 'root' })
export class RegimenService {
  variantId: string = 'variant_999';
  backgroundColor: string = 'tw-bg-teal-100';
  linearGradient: LinearGradient = {
    color1: '#A2DBD0',
    color2: '#14796D',
  };

  setRegimenVariantId(variantId: string): void {
    this.variantId = variantId;
  }

  getRegimenVariantId(): string {
    return this.variantId;
  }

  getBackgroungColourBasedOnVariantId(variantId: string): string {
    try {
      if (
        !variantId
        || this.variantId === VARIANT_ID.variant_1499
        || !REGIMEN_IMG_BACKGROUND_CLASS[variantId]
      ) {
        this.backgroundColor = REGIMEN_IMG_BACKGROUND_CLASS.variant_999;
      } else {
        this.backgroundColor = REGIMEN_IMG_BACKGROUND_CLASS[variantId];
      }
    } catch (err) {
      this.backgroundColor = REGIMEN_IMG_BACKGROUND_CLASS[VARIANT_ID.variant_1499];
    }
    return this.backgroundColor;
  }

  getRegimenTagBorderColorBasedOnBgColor(backgroundColor: string): LinearGradient {
    if (backgroundColor === 'tw-bg-teal-100' || !backgroundColor) {
      this.linearGradient = {
        color1: '#A2DBD0',
        color2: '#14796D',
      };
    } else if (backgroundColor === 'tw-bg-orange-100') {
      this.linearGradient = {
        color1: '#EFC9A1',
        color2: '#D65C1A',
      };
    } else if (backgroundColor === 'tw-bg-blue-100') {
      this.linearGradient = {
        color1: '#4973B4',
        color2: '#B6CCE7',
      };
    }
    return this.linearGradient;
  }
}

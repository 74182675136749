import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ApiClientConstant, Table } from '@cureskin/api-client';
import { ValueOf } from '@cureskin/api-client/src/common';
import { WindowRefService } from '../window-ref-service';
import { ConnectionService } from '../connection-service';
import { AppConfig } from '../../app/app.config';
import { ElasticService } from '../elastic-search';
import { SentryErrorHandler } from '../sentry-logger';

@Injectable()
export class EventLoggerService {
  timeEventTrack: { [key: string]: number } = {};
  user: any;

  constructor(
    private windowRef: WindowRefService,
    private conn: ConnectionService,
    private http: HttpClient,
    private appConfig: AppConfig,
    private elasticSearchService: ElasticService,
    private appWebBridgeService: AppWebBridgeService,
    private sentry: SentryErrorHandler) {
  }

  /**
   * This is the common service (middleware) for all the other logger and tracing services.
   * It receives the data in one place and sends it to all other services.
   */

  /**
   * Initializes following logger services
   * 1. ElasticSearch (analytics.cureskin.com server) doesn't need init. its opens for logs.
   */
  initialize(): void {
    this.setUser();
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: 'identity',
      params: { uniqueId: this.conn.getSessionLessId(true) },
    });
  }

  /**
   * Fetches user and sets the identity of user in the services.
   */
  setUser(): void {
    if (!this.conn.isUserLoggedIn) return;
    this.user = this.conn.getCurrentUser();
    const interApplicationTrackingId = this.conn.getSessionLessId();
    this.elasticSearchService.setIdentity(interApplicationTrackingId);
    this.logUserInClarity();
  }

  /**
   * Tracks web app route url changes.
   */
  trackWebPage(): void {
    if (this.conn.isInternalUser()) return;
    const query = this.windowRef.nativeWindow.location.search.split('?')[1];
    const params: { path?: string } = {};
    if (query) {
      query.split('&')
        .forEach((x: any): void => {
          const temp = x.split('=');
          const key = temp.shift();
          params[key] = temp.join('=');
        });
    }
    params.path = this.windowRef.nativeWindow.location.pathname;
  }

  /**
   * Tracks the event with a 'eventName' & 'params' payload to log.
   * @param {string} eventName
   * @param {object} params
   */
  trackEvent(eventName: string, params: { [key: string]: unknown } = {}): void {
    if (this.conn.isInternalUser()) return;
    const trackParams: any = { ...params };
    if (this.user) {
      trackParams.username = this.user.get('username');
      trackParams.user_created_at = this.user.createdAt;
      trackParams.user_mod = this.user.get('mod20');
    }
    if (this.timeEventTrack[eventName]) {
      trackParams.duration = new Date().getTime() - this.timeEventTrack[eventName];
      delete this.timeEventTrack[eventName];
    }
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: eventName,
      params,
    });
  }

  /**
   * Records the specific events start and end time based on 'isStart' boolean.
   * @param {string} event
   * @param {boolean} isStart
   */
  timeEvent(event: string, isStart: boolean = false): void {
    if (this.conn.isInternalUser()) return;
    this.timeEventTrack[event] = new Date().getTime();
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: 'time_event',
      params: { event },
    });
  }

  /**
   * Sets the user properties, these properties are attached with all the events http request when sent to server.
   * @param {object} data
   */
  setUserProperty(data: object = {}): void {
    if (this.conn.isInternalUser()) return;
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: 'people_set',
      params: data,
    });
  }

  /**
   * Increments count of event or logs the event directly (in case of elastic search).
   * @param {object} params
   */
  trackPeopleIncrement(params?: object): void {
    if (this.conn.isInternalUser()) return;
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: 'people_increment',
      params,
    });
  }

  /**
   * Tracing event directly in backend 'Event' table.
   * @param {string} eventName
   * @param {string} type
   */
  trackInParse(eventName: string,
    type?: ValueOf<typeof ApiClientConstant.Event.Type>,
    productId?: string,
    articleId?: string): void {
    if (!this.conn.isUserLoggedIn || this.conn.isInternalUser()) return;
    this.trackEvent(eventName.toLocaleLowerCase());
    const event = new Table.Event();
    event.set('user', this.conn.getCurrentUser());
    event.set('name', eventName);
    if (type) event.set('type', type);
    if (productId) event.set('productId', productId);
    if (articleId) event.set('articleId', articleId);
    event.save({});
  }

  trackInFirebaseAndBranch(eventName: string, eventData?: { [key: string]: unknown }): void {
    if (!this.conn.isUserLoggedIn || this.conn.isInternalUser()) return;
    this.conn.trackInFirebaseAndBranch(eventName, eventData);
  }

  trackInElasticSearch(param_: { [key: string]: any }): void {
    const param = param_;
    if (this.conn.isInternalUser()) return;
    if (param.event && !param.track) {
      param.track = param.event;
      delete param.event;
    }
    this.elasticSearchService.log(Object.assign(param, { analytics: this.appConfig.Shared.Analytics.Mixpanel }));
  }

  logError(error: any, inSentry?: boolean): void {
    const body = { message: error.message, stack: error.stack, error, analytics: this.appConfig.Shared.Analytics.Mixpanel };
    if (inSentry) this.sentry.handleError(error.message || error.toString());
    else this.elasticSearchService.log(body);
  }

  logout(): void {
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.Mixpanel,
      track: 'reset',
      params: {},
    });
  }

  /**
   * Send events to `cleavertap`
   * @param event Cleavertap Event name
   * @param payload Payload in string format
   */
  cleverTapEvent(event: string, payload?: string): void {
    this.elasticSearchService.log({
      analytics: this.appConfig.Shared.Analytics.CleverTap,
      track: event,
      params: JSON.parse(payload || '{}'),
    });
    this.appWebBridgeService.cleverTapPushEvent(event, payload);
  }

  /*
   Sends user name to clarity
   */
  logUserInClarity(): void {
    // Add a check for clarity object
    clarity('set', 'username', this.user.get('username'));
  }
}

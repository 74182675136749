<ng-container *ngIf="!showSearch">
  <div class="tw-fixed tw-left-6 tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
       (click)="back()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'arrow_back'"></cs-icon>
  </div>
  <div class="tw-fixed tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-gap-1 tw-px-4 tw-rounded-full tw-h-10 tw-border-2 tw-border-white tw-bg-teal-200"
       [ngClass]="{'tw-right-18': centerSearchExperiment , 'tw-right-32': !centerSearchExperiment}"
       *ngIf="totalCashBalance"
       (click)="openBottomSheet()">
    <cs-icon class="tw-h-6 tw-w-6"
             [iconName]="'cureskin_cash_coin'"></cs-icon>
    <p class="tw-font-bold tw-text-400">{{totalCashBalance}}</p>
  </div>
  <div class="tw-fixed tw-right-[72px] tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
       *ngIf="isSearchOn && !centerSearchExperiment"
       (click)="onSearchOpen()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'search_icon'"></cs-icon>
  </div>
  <div class="tw-fixed tw-right-6 tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
       (click)="goToCart()">
    <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
             [iconName]="'shopping_cart'"></cs-icon><span
          class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{numberOfProductsInCart}}</span>
  </div>
</ng-container>
<div class="tw-flex flex-col tw-bg-gray-100 tw-h-full"
     #homePageContainer="">
  <div class="flex-box">
    <div class="tw-bg-teal-200 tw-inline-flex tw-justify-center tw-flex-wrap"
         *ngIf="isSearchOn"
         [ngClass]='{ "tw-hidden": !showSearch }'>
      <div class="tw-fixed tw-left-6 tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
           (click)="closeSearch()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                 [iconName]="'arrow_back'"></cs-icon>
      </div>
      <div class="tw-w-[60%] tw-ml-[10px] tw-mt-8 tw-mb-5">
        <input class="tw-mx-auto tw-w-full  tw-border-b tw-bordere-solid tw-border-black"
               style="background-color: transparent;"
               *ngIf="showSearch"
               placeholder="Search here..."
               [(ngModel)]="searchValue"
               (keyup)="fetchSearchData()"
               (click)="searchClickedHandler()"/>
      </div>
      <div class="tw-fixed tw-right-6 tw-top-6 tw-z-20 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10"
           (click)="goToCart()">
        <cs-icon class="tw-h-6 tw-w-6 tw-text-white"
                 [iconName]="'shopping_cart'"></cs-icon><span
              class="tw-absolute tw-top-[2px] tw-right-[18%] tw-bg-black tw-font-body tw-font-bold tw-h-[14px] tw-text-200 tw-text-red-400">{{numberOfProductsInCart}}</span>
      </div>
    </div>
    <div class="tw-grow tw-shrink tw-basis-auto tw-overflow-x-hidden tw-overflow-y-auto"
         *ngIf="!showSearch">
      <div class="tw-w-full tw-flex tw-flex-col tw-bg-teal-200 tw-bg-grid-layout tw-text-center tw-bg-repeat-x tw-bg-top tw-pt-20 tw-px-0 tw-overflow-visible tw-pb-4"
           *ngIf="!showSearch"
           [ngClass]="{
           'tw-sticky tw-top-0 tw-z-10 tw-mb-5': centerSearchExperiment,
           'tw-mb-0': showCenterSearchResults && centerSearchExperiment,
           'tw-mb-5': showCashBalance && !showCenterSearchResults,
           'tw-mb-6 before:tw-absolute before:tw-bg-transparent before:tw-top-[142px] before:tw-h-[50px] before:tw-w-6 before:tw-rounded-l-3xl before:tw-shadow-[0_-25px_0_0_#BDDDD4] after:tw-absolute after:tw-bg-transparent after:tw-top-[142px] after:tw-h-[50px] after:tw-right-0 after:tw-w-6 after:tw-rounded-r-3xl after:tw-shadow-[0_-25px_0_0_#BDDDD4] tw-relative': !showCashBalance && !centerSearchExperiment,
            } ">
        <div class="tw-relative">
          <div class="tw-font-body tw-font-bold tw-text-600 tw-tracking-normal"
               i18n="@@shopProducts">
            Shop products
          </div>
          <!-- center search input field -->
          <div class="tw-flex tw-items-center tw-justify-start tw-gap-3.5 tw-bg-white tw-rounded-xl tw-h-10 tw-w-11/12 tw-mx-auto tw-mt-5 tw-p-2.5"
               *ngIf="isSearchOn && centerSearchExperiment">
            <cs-icon class="tw-h-6 tw-w-10 tw-text-teal-400 tw-border-r tw-border-r-solid tw-border-r-gray-200"
                     [iconName]="'search_icon'"></cs-icon>
            <input class="tw-mx-auto tw-w-full tw-border-none"
                   style="background-color: transparent;"
                   placeholder="Search for 'facewash'"
                   [(ngModel)]="searchValue"
                   (keyup)="fetchSearchData()"
                    (click)="centerSearchClickedHandler()"/>
          </div>
          <div class="tw-font-body tw-tracking-normal tw-text-300 tw-text-gray-500"
               i18n="@@curatedForSkin"
          *ngIf="!centerSearchExperiment"
          >
            Curated For You
          </div>
        </div>
      </div>
      <div *ngIf="!showCenterSearchResults && searchValue.length <= 2">
                <!-- main content of shop page, this includes all the visible sections -->
            <div class="-tw-mt-[20px] tw-flex tw-flex-col tw-bg-white tw-animate-slide_in_top"
             *ngIf="showRepairProductAutoComplete && centerSearchExperiment"
             >
                  <div id="showRepairProducts">
                    <ul>
                      <li *ngFor="let item of repairProducts"
                          class="tw-flex items-center tw-py-2 tw-px-6"
                          (click)="selectAutocomplete(item)">
                        <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400 tw-mr-2"
                                 [iconName]="'search_icon'"></cs-icon> {{ item.title }}
                      </li>
                    </ul>
                  </div>
                </div>
        <shop-order-banners *ngIf="productsInCartArray?.length" [totalSp]="totalSP"
                            [productsInCartArray]="productsInCartArray"
                            [numberOfProductsInCart]="numberOfProductsInCart"
                            [cartDiscount]="cartDiscount"
                            [rebrandedLevelFourBanners]="rebrandedLevelFourBanners"
                            [userDrImage]="userDrImage"
                            (goToCart)="goToCart()"></shop-order-banners>
        <div #bogoSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             id="bogoSection"
             *ngIf="isPersonalizedBogoExperiment && !isBogoProductsPending && personalizedBogoProducts?.length">
          <shop-carousels [sectionName]="appLanguageCode === appConfig.Shared.Languages.HI ? personalizedBogoTitle?.saleTitleHindi : personalizedBogoTitle?.saleTitle"
                          [viewAllSection]="'bogoProduct'"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of personalizedBogoProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'personalizedBogoProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'personalizedBogoProducts')"></app-product-card>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!loading && isBOGOSaleLive && saleProducts?.length">
          <shop-carousels [sectionName]="'Buy 1 Get 1'"
                          [viewAllSection]="'bogoProduct'"
                          [showTags]="false"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of saleProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'saleProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'saleProducts')"></app-product-card>
          </div>
        </div>
        <loader-shop *ngIf="isBogoProductsPending"></loader-shop>

        <div #reorderProductsSection class="tw-flex tw-flex-col tw-gap-4 tw-pt-5 tw-mb-5 tw-pb-6 tw-bg-white"
             *ngIf="!isAddOnAndReorderPending && reorderProducts?.length">
          <shop-carousels [sectionName]="'Reorder products'"
                          [translateId]="'@@reorderProducts'"
                          [viewAllSection]="'reorderProducts'"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of reorderProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'reorderProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'reorderProducts')"></app-product-card>
          </div>
        </div>
        <loader-shop *ngIf="isAddOnAndReorderPending"></loader-shop>

        <div #recommendedProductsSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!isAddOnAndReorderPending && addOnProducts?.length">
          <shop-carousels [sectionName]="'Recommended products for you'"
                          [translateId]="'@@recommProductsForYou'"
                          [viewAllSection]="'recommendedProducts'"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of addOnProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'recommendedProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'recommendedProducts')"></app-product-card>
          </div>
        </div>
        <loader-shop *ngIf="isAddOnAndReorderPending"></loader-shop>

        <div #drRecommendedProductsSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!loading && drRecommendedProducts?.length">
          <shop-carousels [sectionName]="'Suggested by your doctor'"
                          [translateId]="'@@DrSuggested'"
                          [viewAllSection]="'drRecommendedProducts'"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of drRecommendedProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'drRecommendedProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'drRecommendedProducts')"></app-product-card>
          </div>
        </div>

        <div #personalisedRecommendationsSection>
          <boost-your-regimen *ngIf="!loading && isBoostYourRegimenExperiment"
                              [isProgressiveProductBanner]="isProgressiveProductBanner"
                              [progressiveProduct]="progressiveProduct"
                              [doctorProductsInCart]="doctorProductsInCart"
                              [isBoostProductBanner]="isBoostProductBanner"
                              [isBoostProductLoading]="isBoostProductLoading"
                              [boostProductList]="boostProductList"
                              [boostProductTagName]="boostProductTagName"
                              (viewProduct)="viewProduct(progressiveProduct)"
                              (fetchBoostProductByType)="fetchBoostProductByType($event, true)"
                              (proceedToBuy)="proceedToBuy(null, $event)"
                              (showPopup)="showPopup()"
                              (getCart)="getCart()"></boost-your-regimen>
        </div>

        <div class="tw-bg-white"
             *ngIf="!loading">
          <cross-regimen-banner></cross-regimen-banner>
        </div>
        <div #superSaverKitsSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="kitsProducts?.length && !loading">
          <shop-carousels [sectionName]="'Super saver kits'"
                          [translateId]="'@@superSaverKits'"
                          [viewAllSection]="'kitsProducts'"
                          [showTags]="true"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of kitsProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'superSaverkitsProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'superSaverkitsProducts')"></app-product-card>
          </div>
        </div>
        <div #holiSaleSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!loading && isHoliSaleLive">
          <shop-carousels [sectionName]="holiSaleVariants?.saleTitle"
                          [viewAllSection]="'holiSale'"
                          [showTags]="false"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of holiSaleProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'holiSaleProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'holiSaleProducts')"></app-product-card>
          </div>
        </div>
        <div #forYourBodySection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!isShopTagSectionPending && bodyProducts?.length">
          <shop-carousels [sectionName]="'For your body'"
                          [translateId]="'@@forYourBody'"
                          [viewAllSection]="'bodyProducts'"
                          [showTags]="true"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of bodyProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'forYourBodyProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'forYourBodyProducts')"></app-product-card>
          </div>
        </div>
        <div #forYourScalpSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!isShopTagSectionPending && scalpProducts?.length">
          <shop-carousels [sectionName]="'For your scalp'"
                          [translateId]="'@@forYourScalp'"
                          [viewAllSection]="'scalpProducts'"
                          [showTags]="true"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of scalpProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'forYourScalpProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'forYourScalpProducts')"></app-product-card>
          </div>
        </div>
        <div #forYourLipsSection class="tw-flex tw-flex-col tw-gap-4 tw-mb-5 tw-pt-5 tw-pb-6 tw-bg-white"
             *ngIf="!isShopTagSectionPending && lipProducts?.length">
          <shop-carousels [sectionName]="'For your lips'"
                          [translateId]="'@@forYourLips'"
                          [viewAllSection]="'lipProducts'"
                          [showTags]="true"></shop-carousels>
          <div class="tw-flex tw-items-stretch tw-gap-5 tw-px-5 tw-overflow-x-auto">
            <app-product-card *ngFor="let product of lipProducts"
                              [product]="product"
                              [productInCart]="productsInCart[product?.objectId]"
                              (addToCart)="addToCart($event, 'forYourLipsProducts')"
                              (reomveFromCart)="reomveFromCart($event)"
                              (gotoProduct)="gotoProduct($event, 'forYourLipsProducts')"></app-product-card>
          </div>
        </div>
        <loader-shop *ngIf="isShopTagSectionPending"></loader-shop>

        <div class="tw-flex tw-justify-space-between tw-items-center tw-flex-row tw-pl-4"
             *ngIf="!loading"
             (click)="exploreAll()"
             [ngClass]='{ "tw-mb-[148px]": !aovTask, "tw-mb-[200px]": aovTask }'>
          <div class="tw-flex-1 tw-flex tw-flex-col">
            <div class="tw-font-body tw-font-bold tw-text-600 tw-text-black"
                 i18n="@@shopAll">
              Shop all
            </div>
            <div class="tw-font-body tw-text-200 tw-text-black"
                 i18n="@@shopAllProducts">
              Cureskin products
            </div>
          </div>
          <div
            class="tw-flex-none tw-h-8 tw-w-8 tw-bg-green-400 tw-rounded-full tw-m-4 tw-flex tw-items-center tw-justify-center">
            <em class="tw-text-white cs-icon-arrow"></em>
          </div>
        </div>
      </div>
      <div>
      <!--    center search-->
      <div class="tw-bg-white">
        <div
          *ngIf="centerSearchExperiment && !showCenterSearchResults && loading && searchValue.length > 2"
          class="tw-absolute tw-z-40 tw-h-1/2 tw-w-full">
          <loading class="tw-absolute tw-top-1/2 tw-left-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%] tw-z-50"
                   *ngIf="loading"></loading>
        </div>
        <div
          *ngIf="centerSearchExperiment && showCenterSearchResults && !loading && searchValue.length > 2">
          <div class="tw-block tw-border-spacing-0 tw-overflow-hidden tw-font-body"
               *ngIf="showCenterSearchResults && !loading">
            <div class="tw-flex tw-flex-col tw-h-full tw-pb-12"
            [ngClass]='{ "tw-mb-32": !aovTask, "tw-mb-[230px]": aovTask }'
                 *ngIf="searchValue.length > 2  &&  !loading">
              <div class="tw-overflow-y-scroll tw-pb-6" [ngClass]='{ "tw-mb-32": !aovTask, "tw-mb-[230px]": aovTask }'>
                <!-- List of Names with Search Icon and Arrow Icon -->
                <div class="tw-py-2 tw-px-4">
                  <ul>
                    <li *ngFor="let item of searchResultTexts"
                        class="tw-flex items-center tw-py-2 tw-px-6"
                        (click)="selectAutocomplete(item)">
                      <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400 tw-mr-2"
                               [iconName]="'search_icon'"></cs-icon> {{ item.title }}
                    </li>
                  </ul>
                </div>
                <div class="tw-h-full tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4"
                     style="display: flex; justify-content: center;">
                  <ng-container *ngFor="let product of searchResultProducts">
                    <div class="tw-items-center tw-flex tw-flex-col tw-justify-between tw-h-full">
                      <app-product-card class="tw-animate-anim_fadeIn"
                                        [product]="product"
                                        [productInCart]="productsInCart[product?.objectId]"
                                        (addToCart)="addToCart($event, 'advanceSearchProduct')"
                                        (reomveFromCart)="reomveFromCart($event)"
                                        (gotoProduct)="gotoProduct($event, 'advanceSearchProduct')">
                      </app-product-card>
                    </div>
                  </ng-container>
                  <div class="tw-w-[85%] tw-pt-[75px] tw-px-5 tw-text-center tw-leading-[1.8rem] tw-font-normal tw-text-[1.1rem] tw-bg-no-repeat tw-bg-center tw-bg-top tw-bg-60 tw-absolute tw-top-2/4 tw-left-2/4 tw-translate-x-[-50%] tw-translate-y-[-50%]"
                       *ngIf="!searchResultProducts.length"
                       i18n="@@noProductsAvailable">
                    No products available
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- advance search -->
    <div *ngIf="advanceSearchExperiment">
      <loading class="tw-absolute tw-top-1/2 tw-left-1/2 tw-translate-x-[-50%] tw-translate-y-[-50%]"
               *ngIf="loading && showSearch"></loading>
      <!-- after search  -->
      <div class="tw-block tw-border-spacing-0 tw-overflow-hidden tw-w-full tw-h-full tw-font-body tw-fixed"
           *ngIf="showSearch && !loading">
        <div class="tw-flex tw-flex-col tw-h-full"
             *ngIf="searchValue.length <= 2">
          <div class="tw-overflow-y-scroll tw-mb-32 tw-pb-6" [ngClass]='{ "tw-mb-32": !aovTask, "tw-mb-[230px]": aovTask }'>
              <!-- List of Names with Search Icon and Arrow Icon -->
              <div class="tw-py-2 tw-px-4">
                <ul>
                  <li *ngFor="let item of searchResultTexts"
                      class="tw-flex items-center tw-py-2 tw-px-6"
                      (click)="selectAutocomplete(item)">
                    <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400 tw-mr-2"
                             [iconName]="'search_icon'"></cs-icon> {{ item.title }}
                  </li>
                </ul>
              </div>
            <div *ngIf="reorderProducts?.length">
              <div class="tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-2">
                <div class="tw-px-4 tw-font-body tw-font-bold tw-text-400 tw-normal-case tw-text-gray-500"
                     i18n="@@reorderProducts">
                  Reorder products
                </div>
              </div>

              <div class="tw-h-full tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4"
                   style="display: flex; justify-content: center">
                <ng-container *ngFor="let product of reorderProducts">
                  <div class="tw-items-center tw-flex tw-flex-col tw-justify-between tw-h-full">
                    <app-product-card class="tw-animate-anim_fadeIn"
                                      [product]="product"
                                      [productInCart]="productsInCart[product?.objectId]"
                                      (addToCart)="addToCart($event, 'reorderProducts')"
                                      (reomveFromCart)="reomveFromCart($event)"
                                      (gotoProduct)="gotoProduct($event, 'reorderProducts')">
                    </app-product-card>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="tw-mt-4" [ngClass]='{ "tw-mb-24": !aovTask, "tw-mb-[230px]": aovTask }'>
              <div class="tw-flex tw-items-center tw-justify-between tw-px-5 tw-py-2">
                <div class="tw-px-4 tw-font-body tw-font-bold tw-text-400 tw-normal-case tw-text-gray-500"
                     i18="@@recommProductsForYou">
                  Recommended products for you
                </div>
              </div>
              <div class="tw-h-full tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4"
                   style="display: flex; justify-content: center">
                <ng-container *ngFor="let product of addOnProducts">
                  <div class="tw-items-center tw-flex tw-flex-col tw-justify-between tw-h-full">
                    <app-product-card class="tw-animate-anim_fadeIn"
                                      [product]="product"
                                      [productInCart]="productsInCart[product?.objectId]"
                                      (addToCart)="addToCart($event, 'addOnProducts')"
                                      (reomveFromCart)="reomveFromCart($event)"
                                      (gotoProduct)="gotoProduct($event, 'addOnProducts')">
                    </app-product-card>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-flex-col tw-h-full tw-mb-32 tw-pb-12"
             *ngIf="searchValue.length > 2  &&  !loading">
          <div class="tw-overflow-y-scroll tw-pb-6" [ngClass]='{ "tw-mb-32": !aovTask, "tw-mb-[230px]": aovTask }'>
            <!-- List of Names with Search Icon and Arrow Icon -->
            <div class="tw-py-2 tw-px-4">
              <ul>
                <li *ngFor="let item of searchResultTexts"
                    class="tw-flex items-center tw-py-2 tw-px-6"
                    (click)="selectAutocomplete(item)">
                  <cs-icon class="tw-h-6 tw-w-6 tw-text-green-400 tw-mr-2"
                           [iconName]="'search_icon'"></cs-icon> {{ item.title }}
                </li>
              </ul>
            </div>
            <div class="tw-h-full tw-grid tw-grid-cols-2 tw-gap-2 tw-px-4"
                 style="display: flex; justify-content: center;">
              <ng-container *ngFor="let product of searchResultProducts">
                <div class="tw-items-center tw-flex tw-flex-col tw-justify-between tw-h-full">
                  <app-product-card class="tw-animate-anim_fadeIn"
                                    [product]="product"
                                    [productInCart]="productsInCart[product?.objectId]"
                                    (addToCart)="addToCart($event, 'advanceSearchProduct')"
                                    (reomveFromCart)="reomveFromCart($event)"
                                    (gotoProduct)="gotoProduct($event, 'advanceSearchProduct')">
                  </app-product-card>
                </div>
              </ng-container>
              <div class="tw-w-[85%] tw-pt-[75px] tw-px-5 tw-text-center tw-leading-[1.8rem] tw-font-normal tw-text-[1.1rem] tw-bg-no-repeat tw-bg-center tw-bg-top tw-bg-60 tw-absolute tw-top-2/4 tw-left-2/4 tw-translate-x-[-50%] tw-translate-y-[-50%]"
                   *ngIf="!searchResultProducts.length"
                   i18n="@@noProductsAvailable">
                No products available
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<pop-up *ngIf="isDiscontinued"
        [class]="'bdr-rad-10 p15 popup-ht-unset'"
        [show]="isDiscontinued"
        [triggerDismiss]="true"
        (close)="closePopup()">
  <discontinued-popup [oldProductData]="oldProduct"
                      [newProductData]="newProduct"
                      (changeProduct)="changeProduct()"
                      (articleOpen)="openArticle()"></discontinued-popup>
</pop-up>

<cart-detail-strip *ngIf="cartItems?.length && isCheckoutStickyVisible && !aovTask"
                   [numberOfProductsInCart]="numberOfProductsInCart"
                   [user]="user"
                   [cashBalance]="totalCashBalance"
                   [cart]="cart"
                   [totalSp]="totalSP"
                   (closeStrip)="isCheckoutStickyVisible = false"></cart-detail-strip>

<aov-sale *ngIf="aovTask && !loading && !newCartUI"
          [numberOfProductsInCart]="numberOfProductsInCart"
          [maxPrice]="maxPrice"
          [showConfettiAnimation]="showConfettiAnimation"
          [barStyle]="barStyle"
          [cart]="cart"
          [user]="user"
          [userLanguageKnOrTa]="userLanguageKnOrTa"
          [prodImage]="prodImage"
          [amountRemaining]="amountRemaining"
          [totalSP]="totalSP"
          (onCheckout)="checkout()"></aov-sale>
  <div *ngIf="aovTask && !loading && newCartUI" class="tw-absolute tw-w-full tw-h-32 tw-bottom-6">
    <app-aov-banner
      [amountRemaining]="amountRemaining"
      [freeProductBannerImage]="prodImage"
      [percentageDone]="percentageDone"
      [isEnglishLanguage]="user?.get('languagePreference') === appConfig.Shared.Languages.EN"
      [displayWhenAmountRemainingIsZero] = true
    ></app-aov-banner>
  </div>

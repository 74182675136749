<div *ngIf="displayWhenAmountRemainingIsZero || amountRemaining > 0" class="tw-h-[72px] tw-w-full tw-rounded-t-3 tw-relative tw-bg-white" (click)="aovBannerClickHandler()">
  <img
    class="tw-absolute tw-w-full tw-h-[72px] tw-object-cover tw-opacity-50 tw-rounded-t-3 tw-overflow-hidden"
    src="/assets/gifs/aov-banner-bg.gif"
    alt="aov banner bg"
  />
  <div class="tw-flex tw-gap-6 tw-pl-[19.96px] tw-rounded-t-3 tw-inset-0 tw-absolute">
    <div>
      <img
        [src]="freeProductBannerImage"
        alt="free-product"
        class="tw-w-18 tw-aspect-square tw-object-contain tw-bg-white tw-p-2 tw-rounded-full tw-border-[3px] tw-border-orange-50 -tw-translate-y-[10.26px]"
      />
    </div>
    <div class="tw-font-body tw-py-[10.26px]">
      <h1
        class="tw-font-bold tw-mb-[7px]"
        [ngClass]="{'tw-text-400': isEnglishLanguage, 'tw-text-300': !isEnglishLanguage}"
        *ngIf="percentageDone >= 0 && percentageDone < 100"
        i18n="@@getAFreePouch"
      >
        Get a <span class="tw-font-bold tw-italic" >free</span> gift
      </h1>
      <h1 *ngIf="percentageDone >= 100" class="tw-italic tw-font-bold tw-mb-[7px]" [ngClass]="{'tw-text-400': isEnglishLanguage, 'tw-text-300': !isEnglishLanguage}" i18n="@@congratulations">Congratulations!</h1>
      <div class="tw-h-[4px] tw-w-[182px] tw-rounded-full tw-bg-yellow-50 tw-overflow-hidden tw-mb-[5.74px]">
        <div
          class="tw-bg-orange-600 tw-h-full tw-transition-all tw-rounded-full"
          [style.width.%]="percentageDone"
        ></div>
      </div>
      <p *ngIf="percentageDone >= 0 && percentageDone < 100" i18n="@@shopMoreNew" class="tw-text-200">
        Shop for
        <span class="tw-font-bold">₹{{ amountRemaining%1 != 0 ? amountRemaining.toFixed(2) : amountRemaining }}</span>
        more
      </p>
      <p *ngIf="percentageDone >= 100" i18n="@@youGotFreeProduct" class="tw-text-200">You have got a free gift</p>
    </div>
  </div>
</div>

import { Languages, RegimenClass } from '@cureskin/api-client/src/server-api';

export interface AuthConfig {
  source?: string;
  deviceId?: string;
  aaid?: string;
  referralCode?: string;
  loginMethod?: string;
  OTP: string;
  mobileNumber: string;
  phoneNumber?: string;
  payload?: string;
  signature?: string;
  signatureAlgorithm?: string;
}

export interface FollowUpReport {
  afterInstantCheckup?: any;
  beforeInstantCheckup?: any;
  type?: string;
  tips?: string;
  investigations?: { tests: any[], name: string };
  regimenModifications?: string[];
  audioURL?: string;
  imageConfig: { after: any, before: any};
  treatmentProgress?: Array<{ issue: string, score: number}>;
  doctor?: any;
  createdAt?: any;
  observation?: string;
  concern?: string;
  isImageOnlyReport?: boolean;
  objectId?: string;
  audioNote?: string;
  secondaryComparisons?: any[];
  assistantId?: string;
  trackingDetails?: { audioPlayed?: boolean, audioFinished?: boolean, scrollTillBottom?: boolean };
  feedback?: { feedback?: boolean, isReportSatisfied?: boolean, isVoiceNoteUnderstandable?: boolean, doubtsCleared?: boolean };
  consentFormAsk?: boolean;
  supportTicket?: any;
}

export interface Regimen {
  showConsultationView?: boolean;
  showProductSummary?: boolean;
  showPriceDetail?: boolean;
  regimenPreparation?: boolean;
  morning?: Array<any>;
  night?: Array<any>;
  [key: string]: any;
}

export interface AddOnProductVariant {
  variant: {
    [key: string]: {
      productId: string,
      checkupLimit?: number,
      concernDetected?: string[],
      excludableRegimenConcerns?: string[],
      note?: any,
    },
  };
}

export interface LatestReportIds {
  latestFaceReportId: string;
  latestHairReportId: string;
}

export interface ConcernSeverity {
  'Mild': string;
  'Moderate': string;
  'Severe': string;
}

export interface ConcernSeverityThreshold {
  [key: string]: {
    mild: number;
    moderate: number;
  };
}

export const CONCERN_SEVERITY: ConcernSeverity = {
  Mild: 'Mild',
  Moderate: 'Moderate',
  Severe: 'Severe',
};

export const CONCERN_SEVERITY_THRESHOLD: ConcernSeverityThreshold = {
  'pimple-region': { mild: 2, moderate: 5 },
  'darkspot-region': { mild: 4, moderate: 8 },
  'come-region': { mild: 4, moderate: 8 },
  'ascar-region': { mild: 2, moderate: 5 },
  Hirsutism: { mild: 0, moderate: 100 }, // Always Moderate
  Freckles: { mild: 0, moderate: 100 }, // Always Moderate
  PerioralPigmentation: { mild: 0, moderate: 100 }, // Always Moderate
  OpenPores: { mild: 0, moderate: 100 }, // Always Moderate
};

export interface TranslateLastBotQuestionData {
  regimenClass: RegimenClass;
  questionId: string;
  toLanguage: Languages;
}

export interface CollapsableCardItem {
  productId: string;
  isOpen: boolean;
}

export interface CollapsableCardState {
  onHold: CollapsableCardItem[];
  removed: CollapsableCardItem[];
  instructionChanged: string[];
}

export interface PageOpenEvent {
  name: string;
  [key: string]: any;
}

import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/index';
import { Store } from '@ngrx/store';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { CurrentComponentService } from '@services/current-component';
import { BroadcastService } from '@services/broadcast-service';

@Component({ selector: 'user-order-failure', templateUrl: './user-order-failure.html', styleUrls: ['./user-order-failure.scss'] })
export class UserOrderFailureComponent implements OnDestroy {
  payment$: Observable<any> = this.store.select(fromSelectors.selectOrderPayment);
  orderInProcess$: Observable<boolean> = this.store.select(fromSelectors.selectOrderRetryInProcess);
  user: any;
  constructor(
    private conn: ConnectionService,
    private eventLogger: EventLoggerService,
    private router: Router,
    private currentComponent: CurrentComponentService,
    private broadcastService: BroadcastService,
    private readonly store: Store,
  ) {
    this.currentComponent.set(this);
  }

  ngOnInit(): any {
    this.user = this.conn.getActingUser();
    this.eventLogger.trackEvent('time_payment_initiate_to_complete');
    this.store.dispatch(fromActions.OrderFetchPaymentBegin());
  }

  async placeCodOrder(): Promise<void> {
    this.store.dispatch(fromActions.OrderRetryInCodMode());
  }

  async tryAgain(): Promise<void> {
    this.store.dispatch(fromActions.OrderRetryOnlinePayment());
  }

  navigateTo(url: string): void {
    this.conn.navigateToURL(url);
  }

  async handleBackPress(): Promise<boolean> {
    this.router.navigate(['user'], { queryParams: { tab: 'home' }, replaceUrl: true });
    return true;
  }

  back(): void {
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }

  ngOnDestroy(): void {
    this.currentComponent.remove(this);
  }
}

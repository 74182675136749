<div class="tw-block tw-border-spacing-0 tw-overflow-hidden tw-w-full tw-h-full" *ngIf="true">
  <div class="tw-flex tw-h-full tw-flex-col tw-flex-nowrap">
    <div class="tw-flex-initial tw-w-full" #header="">
      <div class="tw-bg-black tw-h-14 tw-px-6 tw-flex tw-items-center tw-w-full tw-justify-center tw-z-[100]">
        <div class="tw-absolute tw-left-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10" (click)="back()">
          <cs-icon class="tw-h-6 tw-w-6 tw-text-gray-50" [iconName]="'arrow_back'"></cs-icon>
        </div>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" *ngIf="!checkupType || (checkupType && [appConfig.Shared.InstantCheckup.Type.REPORT, appConfig.Shared.InstantCheckup.Type.INVALID].includes(checkupType))" i18n="@@photo">Photo</span>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" *ngIf="(checkupType && ![appConfig.Shared.InstantCheckup.Type.REPORT, appConfig.Shared.InstantCheckup.Type.INVALID, appConfig.Shared.InstantCheckup.Type.HAIR_FRONT, appConfig.Shared.InstantCheckup.Type.HAIR_TOP].includes(checkupType))" i18n="@@skinAnalysisResult">Skin analysis result</span>
        <span class="tw-font-body tw-font-bold tw-text-300 tw-text-white" *ngIf="(checkupType === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT || checkupType === appConfig.Shared.InstantCheckup.Type.HAIR_TOP)" i18n="@@hairAnalysisResult">Hair analysis result</span>
        <div class="tw-absolute tw-right-6 tw-top-2 tw-flex tw-items-center tw-justify-center tw-bg-black tw-rounded-xl tw-h-10 tw-w-10">
          <cs-icon class="tw-h-10 tw-w-10" [iconName]="'delete'" (click)="deleteInstantCheckup()"></cs-icon>
        </div>
      </div>
    </div>
    <div class="tw-flex-auto tw-overflow-x-hidden tw-overflow-y-auto tw-w-full tw-font-body tw-relative tw-bg-gray-100" #content="">
      <div class="tw-w-full tw-h-[200px] tw-fixed tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-my-8 tw-mx-0" *ngIf="!ui.imageLoaded">
        <div class="tw-w-1/2 tw-h-full tw-relative tw-my-0 tw-mx-auto">
          <div class="scanner before:tw-content-[''] before:tw-absolute before:tw-w-5 before:tw-h-4 before:tw-border-0 before:tw-border-solid before:tw-border-black before:tw-left-0 before:tw-top-0 before:tw-border-l-[1px] before:tw-border-t-[1px]   after:tw-content-[''] after:tw-absolute after:tw-w-5 after:tw-h-4 after:tw-border-0 after:tw-border-solid after:tw-border-black after:tw-right-0 after:tw-top-0 after:tw-border-r-[1px] after:tw-border-t-[1px]">
            <em class="before:tw-content-[''] before:tw-absolute before:tw-w-5 before:tw-h-4 before:tw-border-0 before:tw-border-solid before:tw-border-black before:tw-left-0 before:tw-bottom-0 before:tw-border-l-[1px] before:tw-border-b-[1px]  after:tw-content-[''] after:tw-absolute after:tw-w-5 after:tw-h-4 after:tw-border-0 after:tw-border-solid after:tw-border-black after:tw-right-0 after:tw-bottom-0 after:tw-border-r-[1px] after:tw-border-b-[1px]"></em>
            <div class="tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-w-[90%] tw-h-[5px] tw-z-[5] tw-bg-blue-200 tw-animate-scan_IC1"></div>
            <span class="cs-icon-male tw-absolute tw-text-[72px] tw-text-[rgba(0,0,0,0.4)] tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2" *ngIf='user && user.get("Gender") === "Male"'></span>
            <span class="cs-icon-female" *ngIf='user && user.get("Gender") !== "Male"'></span>
          </div>
        </div>
      </div>
      <div class="tw-font-body tw-font-bold tw-text-100" *ngIf="ui.imageLoaded && banner && instantCheckup.type !== appConfig.Shared.InstantCheckup.Type.INVALID">
        <ng-container *ngIf='banner === "photoReviewed"'>
          <div class="tw-px-4 tw-text-orange-400 tw-bg-orange-100 tw-font-bold tw-py-2 tw-w-full tw-text-center" i18n="@@photoReviewed"> This photo was last reviewed by your dermatologist on {{user.get('refreshDate') | date: 'dd MMM yyyy'}}. </div>
        </ng-container>
        <ng-container *ngIf='banner === "photoReviewPending"'>
          <div class="tw-px-4 tw-text-orange-400 tw-bg-orange-100 tw-font-bold tw-py-2 tw-w-full tw-text-center" i18n="@@photoReviewPending"> This photo is pending review by your dermatologist. </div>
        </ng-container>
        <ng-container *ngIf='banner === "photoWillBeReviewed"'>
          <div class="tw-px-4 tw-text-orange-400 tw-bg-orange-100 tw-font-bold tw-py-2 tw-w-full tw-text-center" i18n="@@photoWillBeReviewedNew"> Your assigned CureSkin dermatologist will review this photo after you order the treatment kit. </div>
        </ng-container>
      </div>
      <div class="anim-group tw-relative tw-overflow-x-auto tw-w-full tw-px-4 tw-whitespace-nowrap tw-pt-2 tw-pb-3" *ngIf="ui.imageLoaded && result.length" #problemNameContainer="" id="problemNameContainer">
        <div class="anim-fadeIn card_{{i}} tw-min-w-[130px] tw-text-center tw-rounded-[50px] tw-py-[10px] tw-px-[20px] tw-box-border tw-max-h-[70px] tw-mr-[15px] tw-inline-block tw-tracking-[1px] tw-text-[12.9px] tw-capitalize tw-font-medium tw-border-none last:tw-mr-0 tw-shadow-z1" *ngFor="let item of result; let i = index" (click)="prepareCanvasToDrawDetectionOfConcern(item, i)" [ngClass]="{ 'tw-bg-black tw-text-white': ui.selectedCard == item.class }">
          {{ problemsCopy[user?.get('languagePreference') ||
    'en'][item.class]?.name}}
        </div>
      </div>
      <div class="tw-relative tw-overflow-hidden">
        <div class="canvasHolder tw-relative tw-transition-all tw-duration-[0.5s] tw-origin-top-left" #canvasHolder="">
          <canvas class="tw-w-full" *ngIf="checkupType !== appConfig.Shared.InstantCheckup.Type.HAIR_FRONT" id="canvasImage" #canvasImage=""></canvas>
          <canvas class="tw-w-full tw-z-[1] tw-absolute tw-top-0 tw-right-auto tw-bottom-auto tw-left-0 pos-abs pos-top-left z-ind-1 w100" id="canvasIssueMarking" *ngIf="checkupType !== appConfig.Shared.InstantCheckup.Type.HAIR_FRONT" #canvasIssueMarking=""></canvas>
          <app-hair-ai-report *ngIf="checkupType === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT" [instantCheckup]="instantCheckup" (hairAiLoaded)="hairAiLoaded($event)"></app-hair-ai-report>
        </div>
      </div>
      <div class="tw-px-8 tw-py-3" *ngIf="ui.selectedCard !== null">
        <div class="tw-font-body tw-font-bold tw-text-300 tw-mb-1">
          {{ problemsCopy[user?.get('languagePreference') ||
    'en'][ui.selectedCard]?.name }}
        </div>
        <div class="tw-font-body tw-text-200">
          {{ problemsCopy[user?.get('languagePreference') ||
    'en'][ui.selectedCard]?.info }}
        </div>
      </div>
      <div class="tw-px-8 tw-py-3" *ngIf="ui.imageLoaded && ui.selectedCard === null && instantCheckup && detectedIssuesText.length">
        <ng-container *ngFor="let issueText of detectedIssuesText; let i = index">
          <div class="tw-font-body tw-font-bold tw-text-300 tw-mb-1" *ngIf='appConfig.InstantCheckupResult[issueText].split(":").length === 2'>
            {{ appConfig.InstantCheckupResult[issueText].split(':')[0] }}
          </div>
          <div class="tw-font-body tw-text-200" *ngIf='appConfig.InstantCheckupResult[issueText].split(":").length === 2'>
            {{ appConfig.InstantCheckupResult[issueText].split(':')[1] }}
          </div>
          <div class="tw-font-body tw-text-200" *ngIf='appConfig.InstantCheckupResult[issueText].split(":").length === 1'>
            {{ appConfig.InstantCheckupResult[issueText] }}
          </div>
        </ng-container>
      </div>
      <div class="tw-p-4 tw-text-center" *ngIf='ui.imageLoaded && !user?.get("PrivateMainConcern")'>
        <div i18n="@@takeMultiplePhotos"> Take multiple photos of side face and front face in bright light for better analysis. </div>
        <div class="tw-mt-4" i18n="@@selectConcernInNextPage"> After taking photos click Continue and select the most important concern for a personalised skin/hair/body care kit. </div>
      </div>
    </div>
    <div class="tw-relative tw-shadow-z2" #footer="">
      <div class="tw-flex tw-flex-row tw-items-center tw-flex-wrap tw-justify-between tw-py-5 tw-px-3 tw-text-center" *ngIf="instantCheckup">
        <div class="tw-w-full tw-mb-4 tw-text-[13.25px]" *ngIf="!handleBlurPhotosExperiment && instantCheckup.type === appConfig.Shared.InstantCheckup.Type.INVALID" i18n="@@couldNotFindFace"> Could not find face. Please stand in bright light and click on "Re-Take Photo" button to take better photo. </div>
        <ng-container *ngIf="handleBlurPhotosExperiment && instantCheckup.type === appConfig.Shared.InstantCheckup.Type.INVALID">
          <div class="tw-w-full tw-flex tw-justify-center tw-items-center tw-h-14">
            <div class="tw-w-full tw-bg-orange-50 tw-border tw-border-orange-400 tw-px-4 tw-py-4 tw-text-left tw-rounded-xl -tw-translate-y-[60px]">
              <div class="tw-flex tw-justify-start tw-items-start tw-mb-2">
                <img src="/assets/icons/warning.svg" class="tw-w-4 tw-h-4 tw-mr-1 tw-mt-0.5" />
                <h1 i18n="@@faceNotVisible" class="tw-text-orange-400 tw-text-400 tw-font-body tw-font-bold">Face not visible</h1>
              </div>
              <h1 i18n="@@retakePhoto" class="tw-text-200 tw-mb-2">Retake photo, by trying the following things:</h1>
              <div class="tw-text-200 tw-pl-2">
                <h1>1. <span i18n="@@fitFaceInOval">Fit your face in the oval</span></h1>
                <h1>2. <span i18n="@@cleanCamera">Clean your camera</span></h1>
                <h1>3. <span i18n="@@useBackCamera">Use back camera</span></h1>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="tw-w-full tw-mb-4 tw-text-[13.25px]" *ngIf="instantCheckup.type !== appConfig.Shared.InstantCheckup.Type.INVALID && ui.imageLoaded && instantCheckup?.aiResponse && instantCheckup.aiResponse.blurStandard && instantCheckup.aiResponse.blurScore &lt;= (instantCheckup.aiResponse.blurStandard.assessment_threshold.thresholds[0].blur / 2)">
          <div i18n="@@photoIsBlurStandInBrightLight"> Photo is too blur. Please stand in bright light and take photo. </div>
        </div>
        <div class="tw-w-full tw-mb-4 tw-text-[13.25px]" *ngIf="instantCheckup.type !== appConfig.Shared.InstantCheckup.Type.INVALID && ui.imageLoaded && instantCheckup?.aiResponse && instantCheckup.aiResponse.lumaStandard && (instantCheckup.aiResponse.lumaScore &lt; instantCheckup.aiResponse.lumaStandard.assessment_threshold.thresholds[0].dark)">
          <div i18n="@@photoIsBadStandInBrightLight"> Photo taken in bad light. Please stand in bright light and take photo. </div>
        </div>
        <ng-container *ngIf="!handleBlurPhotosExperiment">
          <div class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-justify-between">
            <div class="tw-w-[48%]">
              <cs-button (callback)="goCheckAgain()" [class]="'w100 secondary new-g-clr bdr-black ' + (user?.get('languagePreference') === 'ta' ? 'tw-text-200' : 'tw-text-500')">
                <cs-button-text *ngIf='instantCheckup.type.includes("FACE") || (instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected)' i18n="@@checkAgain">Check Again</cs-button-text>
                <cs-button-text *ngIf='!instantCheckup.type.includes("FACE") && ((instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT || instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_TOP) && !instantCheckup?.aiResponse?.hairDetected)' i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                <cs-button-text *ngIf="instantCheckup.type === 'INVALID'" i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                <cs-button-text *ngIf="instantCheckup.type === 'REPORT'" i18n="@@change">Change</cs-button-text>
                <cs-button-text *ngIf="instantCheckup.type === appConfig.Shared.InstantCheckup.Type.OTHER" i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
              </cs-button>
            </div>
            <div class="tw-w-[48%]">
              <cs-button (callback)="continueToNext()" [class]="'w100 primary new-bg white-clr'">
                <cs-button-text i18n="@@continue">Continue</cs-button-text>
              </cs-button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="handleBlurPhotosExperiment">
          <ng-container *ngIf="instantCheckup.type === appConfig.Shared.InstantCheckup.Type.INVALID">
            <div class="tw-w-full tw-flex tw-justify-center tw-items-center">
              <cs-button (callback)="goCheckAgain()" class="tw-w-full primary new-bg white-clr">
                <cs-button-text i18n="@@retake">Retake</cs-button-text>
              </cs-button>
            </div>
          </ng-container>
          <ng-container *ngIf="instantCheckup.type !== appConfig.Shared.InstantCheckup.Type.INVALID">
            <div class="tw-w-full tw-flex tw-items-center tw-flex-wrap tw-justify-between">
              <div class="tw-w-[48%]">
                <cs-button (callback)="goCheckAgain()" [class]="'w100 secondary new-g-clr bdr-black ' + (user?.get('languagePreference') === 'ta' ? 'tw-text-200' : 'tw-text-500')">
                  <cs-button-text *ngIf='instantCheckup.type.includes("FACE") || (instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT && instantCheckup?.aiResponse?.hairDetected)' i18n="@@checkAgain">Check Again</cs-button-text>
                  <cs-button-text *ngIf='!instantCheckup.type.includes("FACE") && ((instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_FRONT || instantCheckup.type === appConfig.Shared.InstantCheckup.Type.HAIR_TOP) && !instantCheckup?.aiResponse?.hairDetected)' i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                  <cs-button-text *ngIf="instantCheckup.type === 'REPORT'" i18n="@@change">Change</cs-button-text>
                  <cs-button-text *ngIf="instantCheckup.type === appConfig.Shared.InstantCheckup.Type.OTHER" i18n="@@reTakePhoto">Re-Take Photo</cs-button-text>
                </cs-button>
              </div>
              <div class="tw-w-[48%]">
                <cs-button (callback)="continueToNext()" [class]="'w100 primary new-bg white-clr'">
                  <cs-button-text i18n="@@continue">Continue</cs-button-text>
                </cs-button>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<mouse-tour *ngIf="tourElement" [element]="tourElement" (onClose)="onCloseTour()"></mouse-tour>
<pop-up-modal [config]="ui.popUpModal" (close)="popUpClosed($event)"></pop-up-modal>
<input class="tw-fixed" #cameraInput="" id="cameraInput" type="file" capture="user" accept="image/*"
  (change)="uploadImageFromNativeCamera($event)" style="top: -100%; left: -100%" />

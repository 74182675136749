import { Component, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { WindowRefService } from '@services/window-ref-service';
import { BroadcastService } from '@services/broadcast-service';
import { CurrentComponentService } from '@services/current-component';
import { AppConfig } from '../../app/app.config';

@Component({
  selector: 'pop-up',
  templateUrl: './pop-up.html',
})
export class PopUpComponent implements OnDestroy {
  @Input('show') set setShow(status: boolean) {
    this.show = status;
    if (this.show) {
      this.showPopup();
      this.currentComponentService.set(this);
    } else this.currentComponentService.remove(this);
  }
  @Input('class') customClass: object | string = {};
  @Input('bg') bg: string;
  @Input('fullscreen') fullscreen: boolean;
  @Input('triggerDismiss') triggerDismiss: boolean;
  @Output('close') close: EventEmitter<any> = new EventEmitter();
  show: boolean = false;
  constructor(private window: WindowRefService,
    public appConfig: AppConfig,
    public broadcast: BroadcastService,
    private currentComponentService: CurrentComponentService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
  }

  /**
   * We push a # & callback to browser state in order to handle back press of popup
   * 1. We don't push # when its fullscreen popup, because fullscreen popup are expected to have CTA
   * and this kind of popup shouldn't be closed on back press. It can be closed only by performing action on the CTA.
   * 2. For non fullscreen popup we push # into browser history.
   */
  showPopup(): void {
    setTimeout((): void => {
      if (!this.fullscreen) this.window.replaceHash('./#popup', (): void => { this.show = false; this.close.emit(); });
      if (this.fullscreen) {
        const [contentContainer]: HTMLElement[] = this.viewContainerRef.element.nativeElement.getElementsByClassName('popup-content');
        if (contentContainer && contentContainer.style) {
          contentContainer.style.minWidth ??= '100%';
          contentContainer.style.minHeight = '100%';
        }
      }
    }, 0);
    if (this.viewContainerRef.element) {
      this.window.nativeWindow.document.body.appendChild(this.viewContainerRef.element.nativeElement);
    }
  }

  async closePopUp(): Promise<any> {
    if (this.triggerDismiss) {
      this.close.emit();
      return;
    }
    if (this.show) {
      this.broadcast.broadcast('NAVIGATION_BACK');
      this.show = false;
    }
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  ngOnDestroy(): void {
    if (this.viewContainerRef.element) {
      this.viewContainerRef.element.nativeElement.remove();
    }
  }
}

<ng-container>
  <div class="tw-py-4">
    <div class="tw-bg-white tw-w-full tw-h-[152px] tw-rounded-3 tw-shadow-z4 tw-flex tw-relative">
      <div class="tw-pl-4 tw-pb-4 tw-space-y-1">
        <div [ngClass]="{'tw-text-600 tw-w-[120px]': user.get('languagePreference') === appConfig.Shared.Languages.EN, 'tw-text-400 tw-w-[150px]': user.get('languagePreference') !== appConfig.Shared.Languages.EN}">
          <h1 *ngIf="type === 'DIET'" class="tw-pt-[46px] tw-font-body tw-font-[700!important]" i18n="@@yourDailyPlan">Your Diet Plan</h1>
          <h1 *ngIf="type === 'REFER'" class="tw-pt-[46px] tw-font-body tw-font-[700!important]" i18n="@@referAndEarn">Refer &amp; Earn</h1>
          <h1 *ngIf="type === 'QUIZ'" class="tw-pt-[46px] tw-font-body tw-font-[700!important]" i18n="@@dailyCareQuiz">Daily Care Quiz</h1>
        </div>

        <div [ngClass]="{'tw-text-200 tw-w-[145px]': user.get('languagePreference') === appConfig.Shared.Languages.EN, 'tw-text-100 tw-w-[150px]': user.get('languagePreference') !== appConfig.Shared.Languages.EN}">
          <p *ngIf="type === 'DIET'" class="tw-text-gray-500  tw-font-body" i18n="@@forHealthySkinandHair">For healthy skin and hair created by nutritionist</p>
          <p *ngIf="type === 'REFER'" class="tw-text-gray-500 tw-font-body" i18n="@@earnCureskinCash">Earn Cureskin cash worth ₹400</p>
          <p *ngIf="type === 'QUIZ'" class="tw-text-gray-500 tw-font-body" i18n="@@playAndEarnCureskinCash">Play and earn Cureskin cash daily</p>
        </div>
      </div>

      <div class="tw-rounded-r-3 tw-overflow-hidden tw-ml-auto">
        <img [src]="bannerImages[type].banner"
             [class]="type === 'QUIZ' ? 'tw-h-[131px] tw-aspect-square tw-ml-auto tw-mr-[10.25px] tw-mt-[10.78px]' : 'tw-h-full tw-ml-auto'" />
      </div>

      <div class=" tw-absolute tw-w-12 tw-aspect-square tw-rounded-full tw-left-4 -tw-top-3 tw-flex tw-justify-center tw-items-center">
        <img [src]="bannerImages[type].icon"/>
      </div>
    </div>
  </div>
</ng-container>

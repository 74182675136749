import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from '@services/broadcast-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { RequestQueryPayload } from '@cureskin/api-client';
import { AppConfig } from '../app.config';

@Component({
  selector: 'user-notification',
  templateUrl: './user-notification.html',
  styleUrls: ['./user-notification.scss'],
})
export class UserNotificationComponent implements OnInit {
  loadMore: () => void = () => this.fetchMoreNotifications();
  user: any;
  ui: { loading: boolean; hasMore: boolean } = { loading: false, hasMore: true };
  toolbarIcons: Array<any> = [{
    name: this.appConfig.Toolbar.ICONS.REFRESH,
    callback: (): void => this.reset(),
  }];
  notifications: Array<any> = [];
  showDoctorMessage: boolean;
  questionIds: Array<string> = ['newFollowUpReport', 'doctorMessage', 'aVoiceMessageFromDoctor'];

  constructor(
    private appConfig: AppConfig,
    private router: Router,
    private connectionService: ConnectionService,
    private eventLogger: EventLoggerService,
    private broadcastService: BroadcastService) { }

  async ngOnInit(): Promise<void> {
    this.user = this.connectionService.getActingUser();
    this.fetchMoreNotifications();
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'notification' }));
  }

  back(): void {
    this.broadcastService.broadcast('NAVIGATION_BACK');
  }

  reset(): void {
    this.ui.hasMore = true;
    this.notifications = [];
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'notification-refresh' }));
    this.fetchMoreNotifications();
  }

  private async fetchMoreNotifications(): Promise<void> {
    if (this.ui.loading || !this.ui.hasMore) {
      return;
    }
    this.ui.loading = true;
    const payload: RequestQueryPayload<Table.Notification> = {
      where: { active: true, user: this.user },
      skip: this.notifications.length,
      limit: 20,
      descending: 'createdAt',
      option: { context: { translate: true } } as Parse.FullOptions,
    };
    if (this.showDoctorMessage) {
      payload.where.questionId = this.questionIds;
      payload.where.audioURL = { $exists: true };
    }
    const notifications = (await this.connectionService.fetchNotification(payload)).map((each: any): any => each.toJSON());
    this.notifications.push(...notifications);
    this.ui.hasMore = !!notifications.length;
    this.ui.loading = false;
  }

  async changeNotification(showDoctorMessage: boolean): Promise<void> {
    this.showDoctorMessage = showDoctorMessage;
    this.notifications = [];
    this.ui.hasMore = true;
    switch (this.showDoctorMessage) {
      case true: {
        await this.fetchMoreNotifications();
        break;
      }
      case false: {
        await this.fetchMoreNotifications();
        break;
      }
      default:
    }
  }
}

import { Injectable } from '@angular/core';
import { OrderTypeEnum } from '@cureskin/api-client/src/server-api';
import { AppConfig } from 'client/app/app.config';

@Injectable({
  providedIn: 'root',
})

export class OrderHelper {
  constructor(
    private readonly appConfig: AppConfig,
  ) {}

  filterOrdersByType(orders: Array<Object>, type: string): Array<Object> {
    if (!orders) {
      return [];
    }
    return orders.filter((order: any): boolean => order.get('type') === type);
  }

  isOrderTypeRegimen(orderType: OrderTypeEnum): boolean {
    return orderType === this.appConfig.Shared.Order.Type.REGIMEN;
  }
}

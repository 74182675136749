<ng-container>
  <!--  old voice note UI  -->
  <div *ngIf="!newHomeVoiceNoteBannerExperiment" class="card tw-flex tw-flex-row tw-gap-4 tw-rounded-2xl tw-px-5 tw-py-4 tw-mx-4 tw-bg-orange-100">
    <div class="tw-relative tw-top-[-10px]">
      <img class="tw-flex-none tw-h-20 tw-w-20 tw-rounded-full" src="../assets/images/voice_note.gif" /><img class="tw-flex-none tw-h-14 tw-w-14 tw-rounded-full tw-absolute tw-top-3 tw-left-3" [src]="docImg" />
      <div class="orangeDot tw-absolute tw-top-3 tw-right-4 tw-w-2 tw-h-2 tw-bg-orange-500 tw-rounded-full tw-content-none"></div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col tw-gap-2">
      <div></div>
      <p class="tw-font-body tw-font-bold tw-text-300 tw-text-black" i18n="@@newVoiceMsg">
        1 new voice message
      </p>
      <p class="tw-font-body tw-text-200 tw-text-black tw-text-xs" i18n="@@drSharedMessage">
        Your doctor has shared a message regarding your treatment.
      </p>
      <c-audio class="c-audio" [src]="voiceUrl" [trackEvent]="trackEventAudio" eventPrefix="OrderApproval" (emitTrackEvent)="handleClick($event)"></c-audio>
    </div>
  </div>

<!--  new voice note UI  -->
  <div *ngIf="newHomeVoiceNoteBannerExperiment && !isDoctorVerifiedKit" class="tw-px-4 tw-py-6 tw-bg-orange-75 tw-mx-4 tw-rounded-[15px] tw-shadow-z4 tw-mt-2">
    <div class="tw-flex tw-gap-2 tw-mb-4">
      <div class="tw-flex-none">
        <div class="tw-relative tw-w-20 tw-aspect-square tw-flex tw-justify-center tw-items-center">
          <img class="tw-rounded-full tw-absolute tw-inset-0 tw-w-full tw-h-full" src="../assets/images/voice_note.gif" />
          <img class="tw-rounded-full tw-absolute tw-w-16 tw-aspect-square" [src]="docImg" />
          <img src="/assets/gifs/blinking-red-dot.gif" alt="red-blink-circle"
               class="tw-w-[10px] tw-aspect-square tw-absolute tw-top-[14px] tw-right-[10px]"/>
        </div>
      </div>
      <div class="tw-pt-2">
        <h1 class="tw-text-400 tw-font-bold tw-mb-2" i18n="@@newVoiceMsg">
          1 new voice message
        </h1>
        <p *ngIf="message.length" class="tw-line-clamp-2 tw-text-200 tw-text-gray-500 tw-pr-[18%]">
          {{message}}
        </p>
        <p *ngIf="!message.length" class="tw-line-clamp-2 tw-text-200 tw-text-gray-500 tw-pr-[18%]" i18n="@@drSharedMessage">
          Your doctor has shared a message regarding your treatment.
        </p>
      </div>
    </div>
    <app-custom-audio-waveform [voiceUrl]="voiceUrl" (emitTrackEvent)="handleClick($event)"></app-custom-audio-waveform>
  </div>
  <!--  new voice note UI Doctor verified kit card  -->
  <div *ngIf="newHomeVoiceNoteBannerExperiment && isDoctorVerifiedKit" class="tw-bg-white tw-mx-4 tw-rounded-[15px] tw-shadow-z4 tw-mt-2">
    <div class="tw-bg-orange-75 tw-px-4 tw-flex tw-items-center tw-gap-2 tw-h-10 tw-rounded-t-[15px]">
      <h1 class="tw-text-400 tw-font-bold tw-font-body" i18n="@@drVerified">Doctor verified</h1>
      <img alt="icon" src="/assets/icons/orange_tick_new.svg" class="tw-w-4 tw-aspect-square" id="tick">
    </div>
    <div class="tw-p-4 tw-pt-3">
      <div class="tw-flex tw-gap-2 tw-items-center tw-mb-3">
        <div class="tw-flex-none">
          <div class="tw-w-16 tw-aspect-square tw-flex tw-justify-center tw-items-center">
            <img class="tw-rounded-full tw-w-16 tw-aspect-square" [ngClass]="regimenCoverBgColor" [src]="kitImageUrl" />
          </div>
        </div>
        <p class="tw-text-gray-500 tw-font-body tw-font-medium tw-text-300" i18n="@@drHasVerifiedYourTreatmentKit">
          {{doctorName}} has verified your treatment kit
        </p>
      </div>
      <app-custom-audio-waveform [voiceUrl]="voiceUrl" [isDoctorImageVariant]="isDoctorVerifiedKit" [docImg]="docImg" [regimen]="regimen" (emitTrackEvent)="handleClick($event)"></app-custom-audio-waveform>
    </div>
  </div>
</ng-container>

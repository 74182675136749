import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConsultationSessionService {
  chats: any[] = [];

  getChats(): any {
    return this.chats;
  }

  setChats(chats: any[]): void {
    this.chats = chats;
  }
}

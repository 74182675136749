import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, inject, ViewEncapsulation } from '@angular/core';
import { CsIconModule } from '@components/icon';
import { SwiperOptions } from 'swiper';
import { SwiperModule } from 'swiper/angular';

@Component({
  selector: 'app-product-slide-show',
  standalone: true,
  imports: [CommonModule, SwiperModule, CsIconModule],
  templateUrl: './product-slide-show.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: `.active-pagination-class {
    opacity: 1;
  }`,
})
export class ProductSlideShowComponent {
  data: { product: any } = inject<{ product: any }>(DIALOG_DATA);
  dialogRef: DialogRef = inject(DialogRef);

  currentActiveSlide: number = 0;
  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    observer: false,
    loop: true,
    pagination: {
      el: '#swiper-pagination',
      clickable: true,
      bulletActiveClass: 'active-pagination-class',
      renderBullet: (index: number, className: string): string => `<div
        class="${className} tw-m-0 tw-h-14 tw-w-fit tw-bg-black tw-flex tw-items-center tw-justify-center">
        <img class="tw-max-h-full tw-max-w-full tw-rounded-1" src="${this.data.product.images[index]?.type === 'video'
    ? '/assets/images/play_circle.svg'
    : this.data.product.images[index]?.url}">
      </div>`,
    },
  };

  close(): void { this.dialogRef.close(); }
}

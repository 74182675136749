<ng-container *ngIf="!loading">
  <div  class="rebrandingUI-report-card rebrandingUI" *ngIf="report && !newFollowUpUIExperiment" (click)="openReport()" [ngClass]='{ "bg-green": !report.get("read") }'>
    <div class="unread">
      <div class="shine loop">
        <div class="tag" *ngIf='!report.get("read") && isNew' i18n="@@new">
          NEW
        </div>
        <div class="tag tw-uppercase" *ngIf='!report.get("read") && !isNew' i18n="@@unread">
          unread
        </div>
      </div>
    </div>
    <div class="tw-mt-0.5 tw-flex-grow tw-relative tw-flex tw-flex-col tw-items-start">
      <div class="tw-text-300" i18n="@@checkUpReportBy">
        {{report.get('type')}} <br/> Check-up report by <br /><b>{{report.get('doctor')?.get('DoctorDisplayName') || 'Dr. ' + report.get('doctor')?.get('username')}}</b>
      </div>
      <div class="tw-text-start tw-text-500 tw-capitalize tw-font-bold tw-pt-2.5 tw-w-11/12 ">
        <span class="date">{{report.get('createdAt') | date: 'd'}}</span><span>&nbsp;</span><span class="month">{{report.get('createdAt') | date: 'MMM'}},</span><span>&nbsp;</span><span class="month">{{report.get('createdAt') | date: 'yyyy'}}</span>
      </div>
    </div>
    <div class="images">
      <div class="tw-mt-0.5 tw-relative tw-flex tw-flex-row">
        <img alt="purple" class="rebrandingUI-image" src="https://cdn.cureskin.com/app/img/purple-icon.svg" />
      </div>
      <button class="rebrandingUI-btn" (click)="openReport()">
        <span i18n="@@viewReport">View Report</span>
        <span>&nbsp;&nbsp;></span>
      </button>
    </div>
  </div>
<!--  new report card-->
  <div *ngIf="newFollowUpUIExperiment" class="tw-flex tw-gap-5 tw-mb-0.5">
    <div class="tw-w-15 tw-flex tw-justify-end tw-gap-4">
      <!-- Date -->
      <h1 class="tw-text-gray-800 tw-text-200 tw-self-center -tw-translate-y-3 tw-min-w-10 tw-leading-4"
          [ngClass]="{
        'tw-invisible': checkIfPreviousIndexHasSameDate(index, report.createdAt)
      }">
        {{ report.createdAt | date: 'MMM' }} {{ report.createdAt | date: 'd' }}<br />
        {{ report.createdAt | date: 'yyyy' }}
      </h1>

      <div class="tw-w-1 tw-h-32 tw-relative tw-flex tw-flex-col tw-gap-[1px] tw-justify-center tw-items-center">
        <!-- Timeline first half -->
        <div class="tw-border-r tw-border-dashed tw-flex-1 -tw-translate-y-3"
             [ngClass]="{
           'tw-border-teal-600': report?.firstHalfTimelineActive,
         }">
        </div>

        <!-- Dot Image Container -->
        <div [ngClass]="{
           'tw-h-5': blinkingDotLogic(index, latestReportIds),
           'tw-h-2': tealDotLogic(index) || grayDotLogic(index)
         }"
             class="tw-rounded-full tw-aspect-square -tw-translate-y-3 tw-relative tw-flex tw-justify-center tw-items-center">
          <img alt="active" src="/assets/icons/checkup-dot.svg" class="tw-w-2 tw-absolute tw-z-10"
               [ngClass]="{ 'tw-hidden': !tealDotLogic(index) }" />
          <img alt="inactive" src="/assets/icons/checkup-gray-dot.svg" class="tw-w-2 tw-absolute tw-z-10"
               [ngClass]="{ 'tw-hidden': !grayDotLogic(index) }" />
          <img alt="latest" src="/assets/icons/checkup-dot-bg.svg" class="tw-w-full tw-absolute"
               [ngClass]="{
             'tw-hidden': !blinkingDotLogic(index, latestReportIds),
             'tw-animate-pulse': blinkingDotLogic(index, latestReportIds)
           }" />
        </div>
        <!-- Timeline second half-->
        <div class="tw-border-r tw-border-dashed tw-flex-1 -tw-translate-y-3"
             [ngClass]="{
           'tw-border-teal-600': report?.secondHalfTimelineActive,
         }">
        </div>
      </div>
    </div>

    <!--    report card -->
    <div class="tw-w-full tw-h-28 tw-rounded-4 tw-p-4 tw-flex tw-gap-4 tw-relative"
    [ngClass]="{
    'tw-bg-teal-100 tw-shadow-z4': !report?.read,
    'tw-bg-gray-100': report?.read,
    }"
    >
        <div *ngIf="!report.read" class="tw-text-orange-400 tw-text-100 tw-font-bold tw-absolute tw-top-2 tw-right-3 tw-flex tw-gap-1 tw-items-center">
          <span class="tw-bg-orange-400 tw-w-1 tw-aspect-square tw-rounded-full tw-block"></span>
          <span i18n="@@unread" class="tw-capitalize">
            unread
          </span>
        </div>
        <div class="tw-min-w-14 tw-flex tw-justify-center tw-items-center">
          <img alt="doctor profile" src="{{report?.doctor?.doctorDisplayImage}}" class="tw-aspect-square tw-w-14 tw-bg-gray-200 tw-rounded-full">
        </div>
        <div class="tw-h-full tw-w-full tw-font-body tw-text-300">
          <h1 class="tw-font-bold"> <span i18n="@@checkup">Check-up</span>&nbsp;<span>{{report?.checkupNumber}}</span></h1>
          <p class="tw-font-normal tw-text-gray-500"><span class="tw-capitalize">{{report.type.toLocaleLowerCase()}}</span><span>&nbsp;</span><span i18n="@@checkupTreatment">treatment</span></p>
          <button (click)="openReport()" class="tw-bg-teal-600 tw-text-gray-50 tw-h-8 tw-w-[118px] tw-mr-auto tw-ml-0 tw-mt-2 tw-p-4 tw-pr-3">
            <span class="tw-text-200 tw-leading-none tw-font-body tw-text-center" i18n="@@viewReport">View Report</span>
            <cs-icon class="tw-w-4 tw-ml-1" [iconName]='"chevron_right"'></cs-icon>
          </button>
        </div>
      </div>
  </div>
</ng-container>

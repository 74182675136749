import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, inject } from '@angular/core';
import { CsIconModule } from '@components/icon';

@Component({
  selector: 'app-video-dialog',
  standalone: true,
  imports: [CsIconModule],
  templateUrl: './video-dialog.component.html',
})
export class VideoDialogComponent {
  data: { url: string } = inject<{ url: string }>(DIALOG_DATA);
  dialogRef: DialogRef = inject(DialogRef);

  close(): void { this.dialogRef.close(); }
}

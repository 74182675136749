import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { EventLoggerService } from '@services/event-logger-service';
import { AppConfig } from 'client/app/app.config';
import SwiperCore, { Scrollbar, Autoplay, SwiperOptions } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ConnectionService } from '@services/connection-service';
import { Router } from '@angular/router';

SwiperCore.use([Autoplay, Scrollbar]);

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductViewComponent {
  private _product: any;
  buyNowExperiment: boolean = false;
  isDiscontinued: boolean = false;
  @Input() set product(product: any) {
    if (!product) return;
    // eslint-disable-next-line no-underscore-dangle
    this._product = product;
    if (this.product?.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      this.isDiscontinued = true;
    }
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-detail', productId: this.product.objectId }));
    this.findDiscount();
    this.extractImages();
  }
  get product(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._product;
  }

  @Input() experiments: any[];
  @Input() numberOfProductsInCart: number;
  @Input() productInCart: any;
  private _user: any;
  @Input() set user(user: any) {
    if (!user) return;
    // eslint-disable-next-line no-underscore-dangle
    this._user = user;
    this.isPaidUser = user.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED;
  }
  get user(): any {
    // eslint-disable-next-line no-underscore-dangle
    return this._user;
  }
  @Input() isAddRemovePending: boolean = false;

  @Output() back: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addToCart: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeFromCart: EventEmitter<any> = new EventEmitter<any>();

  discount: number;

  isPaidUser: boolean;
  isOldProductImage: boolean;
  images: { type: string, url: string }[] = [];
  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    observer: false,
    pagination: {
      el: '#swiper-pagination',
      clickable: true,
      bulletActiveClass: 'active-pagination-class',
      renderBullet: (index: number, className: string): string => `<div
        class="${className} tw-m-0 tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-bg-purple-100 tw-rounded-none"
  style="opacity: 1">
  <img class="tw-max-h-full tw-max-w-full" src="${this.images[index]?.type === 'video'
    ? '/assets/images/play_circle.svg'
    : this.images[index]?.url}">
      </div>`,
    },
  };
  @ViewChild('swiperWrapperOne', { static: false }) swiper?: SwiperComponent;
  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }
  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }
  constructor(
    private readonly eventLogger: EventLoggerService,
    public readonly appConfig: AppConfig,
    private readonly conn: ConnectionService,
    private readonly router: Router,
  ) { }

  async ngOnInit(): Promise<void> {
    this.experiments = await this.conn.findUserActiveExperiments();
    this.experiments.forEach((each: any): void => {
      if (each.key === 'buy_now_article_page') {
        this.buyNowExperiment = true;
      }
    });
  }

  findDiscount(): void {
    const totalMRP = this.product.mrp;
    const totalSP = this.product.price;
    if (totalMRP > totalSP) {
      this.discount = Math.floor(((totalMRP - totalSP) * 100) / totalMRP);
    } else this.discount = 0;
  }

  extractImages(): void {
    if (this.product?.productUnboxedImage?.length) {
      if (this.product?.productUnboxedImage[0].split('.').pop() === 'mp4') {
        this.images.push({ type: 'video', url: this.product?.productUnboxedImage[0] });
      } else {
        this.images.push({ type: 'image', url: this.product?.productUnboxedImage[0] });
      }
      this.isOldProductImage = true;
    } else if (this.product?.rebrandedImageWithBackground?.length) {
      if (this.product?.rebrandedImageWithBackground[0].split('.').pop() === 'mp4') {
        this.images.push({ type: 'video', url: this.product?.rebrandedImageWithBackground[0] });
      } else {
        this.images.push({ type: 'image', url: this.product?.rebrandedImageWithBackground[0] });
      }
    }
    if (this.product.extraImages?.length) {
      const images = this.product.extraImages;
      if (!images[0].length) return;
      this.images = [];
      images.forEach((imageArr: string[]): void => {
        if (imageArr[0]?.split('.')?.pop() === 'mp4') {
          this.images.push({ type: 'video', url: imageArr[0] });
        } else if (['jpg', 'png'].includes(imageArr[0]?.split('.')?.pop())) {
          this.images.push({ type: 'image', url: imageArr[0] });
        }
      });
    }
  }

  addProductToCart(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'product-add-to-cart', productId: this.product.objectId }));
    this.addToCart.emit(this.product.objectId);
  }

  removeProductFromCart(): void {
    this.removeFromCart.emit(this.product.objectId);
  }

  handleBuyNow(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'new_buy_now_button_article_page' }));
    if (!this.productInCart?.quantity) {
      this.addProductToCart();
    }
    this.router.navigate(['cart']);
  }
}
